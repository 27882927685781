import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card, WhiteSpace, Icon, Button, List, Input, Divider, Toast, Modal, Picker, Form, CascadePicker, Checkbox, SearchBar, Popup, Steps } from "antd-mobile";
import { SearchOutline } from "antd-mobile-icons";
import { createForm } from "rc-form";
// import usePrevious from '@/hooks/usePrevious'
import { doNumber, setPhone, getCardInfo, checkCardInfo } from "./fn";
import Empty from "@/components_base/Empty";
import "./style.scss";

const { Step } = Steps;
// import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
// import { getCookie, saveCookie, removeCookie } from "@/utils/storage";

// logout();
// alert("0000")
function OrderforSeach(props) {
  const [isCancelClick, setCancelClick] = useState(false);
  const [iscode, setCode] = useState(false);
  const [isdata, setData] = useState(false);
  const [popup, setPopup] = useState(false);
  const [freightStatesdata, setFreightStatesdata] = useState([]);
  const [orderTraces, setOrderTraces] = useState([]);
  document.title = "订单查询";
  const { getFieldProps, validateFields, getFieldError, setFieldsValue } = props.form;
  function validate() {
    validateFields(["phone"], (errors, values) => {
      const { phone } = values;
      if (!phone || !phone.trim()) {
        Toast.show("手机号不能为空", 2);
        return false;
      }
      Toast.show({
        content: "查询中,请稍后...",
      });
      checkCardInfo(
        { phone },
        (response) => {
          if (response.code) {
            console.log(response);
            if (!response.info || response.info.length <= 0) {
              console.log(response);
              setTimeout(() => {
                Toast.show("未查询到订单信息");
              }, 50);
            } else {
              Toast.clear();
              setTimeout(() => {
                // 记录卡号
                Toast.show("正在跳转...");
                //navigates("/anotherhome");
              }, 50);
            }
          }
        },
        () => {},
        () => {
          Toast.show({
            content: "订单不存在,请检查后重新输入!",
          });
        }
      );
    });
  }
  const handleBlur = (event) => {
    if (!isCancelClick) {
      // 执行查询操作
      console.log(event);
      //   setCancelClick(false);
      const value = event.target.value;
      validatea(value);
    }
    setCancelClick(false);
  };
  function validatea(phone) {
    if (!phone || !phone.trim()) {
      Toast.show("手机号不能为空", 2);
      return false;
    }
    Toast.show({
      content: "查询中,请稍后...",
    });
    setCancelClick(false);
    checkCardInfo(
      { phone },
      (response) => {
        if (response.code > 0) {
          console.log(response);
          Toast.clear();
          if (!response.info || response.info.length <= 0) {
            Toast.show({
              content: "订单不存在,请检查后再试!",
            });
          } else {
            setData(response);
            setCode(true);
          }
        }
      },
      () => {
        Toast.show({
          content: "订单不存在,请检查后再试!",
        });
      },
      () => {
        //Toast.show({"点击订单号复制", 2});
        // Toast.show({
        //   content: '点击单号可复制',
        //   position: 'top',
        //   duration:3000
        // })
      }
    );
  }

  //组装数据
  function renderList(data) {
    let _rcDom = null;
    const listItems = data.map((item, index) => {
      return (
        <Steps direction="vertical" key={`step-${index}`}>
          <Step title={item.state} />
          <Step title="时间" description={item.stateTime} />
          <Step title="描述" description={<span style={{ "line-height": "15px" }}>{item.desc}</span>} />
        </Steps>
      );
    });
    _rcDom = listItems;
    return _rcDom;
  }
  //组装数据
  function renderOList(data) {
    let _rcDom = null;
    const listItems = data.map((item, index) => {
      return (
        <Steps direction="vertical" key={`stepop-${index}`}>
          <Step title={item.state} />
          <Step title="时间" description={item.createTime} />
          <Step title="描述" description={<span style={{ "line-height": "15px" }}>{item.describe}</span>} />
        </Steps>
      );
    });
    _rcDom = listItems;
    return _rcDom;
  }
  function onCopyLink(record) {
    //console.log("record:::::::::::::" + JSON.stringify(record))
    const input = document.createElement("input");
    input.value = record;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand("Copy");
    document.body.removeChild(input);
    Toast.show("复制成功！");
    // setVisible((v) => false);
  }
  return (
    <section className={["layoutPage", "pageAcDos"].join(" ")}>
      {/* <div className="layoutPage-header"></div> */}
      {/* <div className="layoutPage-body", float:'left'> */}
      <div className="pageAcDos-sloganseach" {...props} style={{ textAlign: "center", minHeight: "0rem" }}>
        {/* <p>查询订单</p> */}
      </div>
      <div className="pageAcDos-formBlock">
        <form>
          <List style={{}}>
            {/* <Input
                {...getFieldProps("phone", {
                  rules: [
                    {
                      required: true,
                      message: "手机号不能为空",
                    },
                  ],
                })}
                placeholder="输入手机号查询订单信息"
                // style={{ width: "70%"}}
              />
              <Button size='small'  onClick={validate} >
              <SearchOutline />
              </Button> */}

            <SearchBar
              // ref={searchRef}
              placeholder="输入手机号查询订单信息"
              showCancelButton
              style={
                {
                  // '--border-radius': '100px',
                  //'--placeholder-color': 'blue',
                  // '--height': '32px',
                  // '--padding-left': '12px',
                  //'--background': 'red'
                }
              }
              onSearch={(val) => {
                // Toast.show(`你搜索了：${val}`);
                validatea(val);
              }}
              onFocus={() => {
                console.log("获得焦点");
              }}
              onBlur={(e) => {
                console.log(`失去焦点`);
                //handleBlur(e);
              }}
              onCancel={() => {
                console.log("取消搜索");
                // 延迟处理失去焦点事件
                // setTimeout(() => {
                //   setCancelClick(true);
                // }, 1);
                // return;
              }}
            />
            <Divider />
          </List>
        </form>
      </div>

      <div className="pageAcDos-otherBlock">
        <List>
          {" "}
          {isdata &&
            iscode &&
            isdata.info.map((item, index) => {
              return (
                // <div>
                //   <span>{item.freightVendor}</span>
                <div>
                  <Card title="订单信息">
                    {/* <List.Item> */}
                    {item.code === 1 && (
                      <List.Item prefix={<span style={{ color: "#A8A8A8" }}>快递公司</span>}>
                        <span style={{ color: "#A8A8A8" }}>{item.freightVendor} </span>
                      </List.Item>
                    )}
                    {item.code === 1 && (
                      <List.Item prefix={<span style={{ color: "#A8A8A8" }}>快递单号</span>}>
                        <span onClick={() => onCopyLink(item.freightCode)}   style={{ color: "#A8A8A8" }}>{item.freightCode}  <span role="img" aria-label="copy" className="anticon anticon-copy">
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="0.21rem" height="0.21rem" fill="currentColor" aria-hidden="true">
                        <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                      </svg>
                    </span></span>
                      </List.Item>
                    )}
                    <List.Item prefix={<span style={{ color: "#A8A8A8" }}>时间</span>}>
                      <span style={{ color: "#A8A8A8" }}>{item.createTime}</span>
                    </List.Item>
                    {(item.code === 2 || item.code === 3) && (
                      <List.Item prefix={<span style={{ color: "#A8A8A8" }}>信息</span>}>
                        <span style={{ color: "#A8A8A8" }}>{item.describe}</span>
                      </List.Item>
                    )}
                    {item.code === 1 && (item.freightStates.length > 0 || item.orderTrace.length > 0) ? (
                      <Button
                        style={{
                          "--border-radius": "10px",
                        }}
                        onClick={() => {
                          setPopup(true);
                          setFreightStatesdata(item.freightStates);
                          setOrderTraces(item.orderTrace);
                        }}>
                        更多信息
                      </Button>
                    ) : (
                      <Button
                      style={{
                        "--border-radius": "10px",
                      }}
                        onClick={() => {
                          setPopup(false);
                        }}>
                        暂无更多信息
                      </Button>
                    )}
                    {/* </List.Item> */}
                  </Card>
                  <Divider />
                </div>
              );
            })}
        </List>

        <Popup
          visible={popup}
          onMaskClick={() => {
            setPopup(false);
          }}
          position="right"
          bodyStyle={{ width: "80vw" }}>
          {popup && freightStatesdata && freightStatesdata.length > 0 && (
            <div style={{ height: "100vh", overflowY: "scroll", padding: "20px" }} className="adm-overflow-hidden">
              {renderList(freightStatesdata)}
            </div>
          )}
          {popup && orderTraces && orderTraces.length > 0 && (
            <div style={{ height: "100vh", overflowY: "scroll", padding: "20px" }} className="adm-overflow-hidden">
              {renderOList(orderTraces)}
            </div>
          )}
        </Popup>
      </div>
      {/* </div> */}
      {/* <div className="layoutPage-footer"></div> */}
    </section>
  );
}
export default createForm()(OrderforSeach);
