/**
 * 离线路由
 * 该路由下，已登录用户，一律重定向
 */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Redirect, useLocation,useMatch  } from "react-router-dom";



import Exception from "@/pages/Exception";
// import { logout } from "@/utils/account";
// import { userLogout } from "@/redux/user/action";
import  HelpMC from "@/pages/search";
import NoOnlineWithRoute from "../NoOnlineWithRoute";
// Account
// const Login = React.lazy(() => import('@/pages/Account/Login'))
//  const Forget = React.lazy(() => import("@/pages/Account/Forget"));
//  const Login = React.lazy(() => import("@/pages/Account/Active"));
//  const Jump = React.lazy(() => import("@/pages/Account/Jump"));
const Search = React.lazy(() => import("@/pages/search")); 
const Home = React.lazy(() => import("@/pages/home")); 
//#/my/aiForCheck
function NoOnlineRountes({ children, ...rest }) {
  const { online } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  // const history  = useHistory()
  const { path } = useMatch ();
  const _path = path === "/" ? "" : path;
  const preUrl = "/search";
  console.log(":::::::::::::::::::::::"+path, _path);

 

  return (
    <React.Fragment>
      <Routes>
        <Route path={`${_path}/search`} exact>
          <NoOnlineWithRoute key={"search"} title="查询">
            <Search />
          </NoOnlineWithRoute>
        </Route> 
        {/* <Route path={`${_path}/home`} exact>
          <NoOnlineWithRoute key={"home"} title="卡信息">
            <Home />
          </NoOnlineWithRoute>
        </Route>  */}
      </Routes>
    </React.Fragment>
  );
}

export default NoOnlineRountes;
