export default {
    // mq
    //requestBaseURL: 'http://117.34.106.92:8666/openapi/wlw/v3',
    requestBaseURL: 'https://h5api.mqiotlink.com/openapi/wlw/v3',
    remakeInputSimNumberLabel: '物联卡号',
    remakeInputSimNumberTips: '请输入至少1个物联卡号，最多3个。以换行符隔开，如：\n10001\n10002\n...',
    packageFuelSuccessTipsDo: 0,
    // tencentGDTAppID: '1111009119',
    // tencentGDTPlacementID: '1081232670476446',
    //
    uploadURL: 'http://upload.api.bjmqtx.com/openapi/v3/upload/images/weixin/base-attch',
    uploadDomain: 'http://upload.api.bjmqtx.com',
    //downloadDomainH5:'http://117.34.106.92:8666/upload',
    downloadDomainH5:'https://h5api.mqiotlink.com/upload',
    telemrealname:'https://uniteapp.ctwing.cn:10990/uapp/certifhtml/certif_entry.html?token='
  }
