import React, { useState, useEffect } from 'react'
import { useNavigate, } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Modal, ActivityIndicator, Toast,Popup } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'

import usePrevious from '@/hooks/usePrevious'

function PopupForArticle({visible, children, onClose = () => {}, hasBtn = true, btnTxt = '知道了'}) {
  const navigates = useNavigate()  
  // console.log(children);
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  
  //
  
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function handleClose() {
    onClose && onClose()
  }
  

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  const _rcDomBtn = (
    <Button type="primary" onClick={handleClose}>{btnTxt}</Button>
  )

  return (

    <Popup
    visible={visible}
    // onMaskClick={() => {
    //   setVisible5(false)
    // }}
    showCloseButton
    onClose={() => {
      handleClose()
    }}
    bodyStyle={{
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      minHeight: '65vh',
    }}
  >
    <div style={{ height: '65vh', overflowY: 'scroll', padding: '20px' }}>
    {children}
    </div>
    { hasBtn && (
        <div style={{margin: '0.28rem 0'}}>
          {/* <WingBlank> */}
            {_rcDomBtn}
            {/* </WingBlank> */}
            </div>
      ) }

  </Popup>

    // <Modal
    //   popup
    //   visible={visible}
    //   animationType="slide-up"
    //   style={{height: '85%'}}
    //   closable={true}
    //   onClose={handleClose}
    //   className="articlePopup"
    //   closeOnAction
    //   content={children}
    //   actions={[
    //     {
    //       key: 'confirm',
    //       text: '知道了',
    //       primary: true,
    //     }
    //   ]}
    // >
      
    //   { hasBtn &&
    //    (
    //     <div style={{margin: '0.28rem 0'}}>
    //       {/* <WingBlank> */}
    //         {_rcDomBtn}
    //         {/* </WingBlank> */}
    //       </div>
    //    ) }
    // </Modal>
  )
}

export default PopupForArticle
