import React from 'react'

function ArticleCon() {
  //
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <React.Fragment>
      <div>
<p><span>为切实加强短信类业务、IDC业务、语音接入业务、互联网接入业务、物联网业务等安全使用和规范管理，促进互联网信息服务活动健康有序发展，维护国家安全和社会稳定，保障社会公众利益和公民合法权益，保障其它客户的合法权益，</span><span>北京铭谦天下科技有限公司及关联方</span><span>（下文统称</span><span>“北京铭谦天下”</span><span>）根据《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》、《计算机信息网络国际联网安全保护管理办法》和其他相关法律、行政法规的规定，结合</span><span>北京铭谦天下</span><span>现有业务和网络现状制定本安全责任承诺书。</span></p>
<p><span>我</span><span>单位/个人</span><span>对于所申请的短信、网络、语音接入服务（包括但不限于MAS、ADC、IDC业务、互联网专线、语音业务、物联网业务等接入到</span><span>电信运营商、北京铭谦天下及关联方</span><span>网内的业务），为确保向客户提供健康的信息服务，明确企业主体</span><span>及个人</span><span>责任和法律责任，向</span><span>北京铭谦天下</span><span>郑重承诺如下：</span></p>
<p><span>一、</span><span>遵守国家有关法律法规，提供的信息符合国家法律、法规与工业和信息化部等政府部门的各项管理规定以及</span><span>电信运营商、北京铭谦天下及关联方</span><span>相关规定，并严格执行上述信息安全管理规定。</span></p>
<p><span>二、</span><span>不得利用互联网（CMNET）、语音网和短信类业务从事危害国家安全、泄露国家机密等违法犯罪活动，不得利用互联网（CMNET）、语音网和短信类业务制作、查阅、复制和传播违反宪法和法律、妨碍社会治安、破坏国家统一、破坏民族团结、色情、暴力等的信息，不得利用互联网（CMNET）、语音网和短信类业务发布任何含有下列内容之一的信息：</span></p>
<p><span>1、危害国家安全、泄露国家秘密，侵犯国家的、社会的、集体的荣誉、利益和公民的合法权益，从事违法犯罪活动；</span></p>
<p><span>2、煽动抗拒、破坏宪法和法律、行政法规实施的；</span></p>
<p><span>3、煽动颠覆国家政权，推翻社会主义制度的；</span></p>
<p><span>4、煽动分裂国家、破坏国家统一的；</span></p>
<p><span>5、煽动民族仇恨、民族歧视，破坏民族团结的；</span></p>
<p><span>6、捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</span></p>
<p><span>7、破坏国家宗教政策，宣扬邪教、封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖，教唆犯罪的；</span></p>
<p><span>8、公然侮辱他人或者捏造事实诽谤他人的；</span></p>
<p><span>9、损害国家机关信誉的；</span></p>
<p><span>10、其他违反宪法和法律、行政法规的；</span></p>
<p><span>11、侵犯他人知识产权，传播虚假信息的。</span></p>
<p><span>12、违背社会主义精神文明建设要求、违背中华民族优良文化传统与习惯、以及其它违背社会公德的各类低俗信息。</span></p>
<p><span>13、工业和信息化部、公安部、工商总局、国家互联网信息办公室等国家职能机构、部门禁止发布的各类信息，如银行卡网上非法买卖等。</span></p>
<p><span>三、</span><span>不得利用</span><span>电信运营商、北京铭谦天下及关联方</span><span>互联网（CMNET）、语音网、短信类、物联网类业务从事以下行为：</span></p>
<p><span>1、网络攻击行为</span></p>
<p><span>1）利用自己或他人的机器机器设备，未经他人允许，通过非法手段取得他人机器设备的控制权；</span></p>
<p><span>2）非授权访问、窃取、篡改、滥用他人机器设备上的信息，对他人机器设备功能进行删除、修改或者增加；</span></p>
<p><span>3）向其他机器设备发送大量信息包，干扰其他机器设备的正常运行甚至无法工作；或引起网络流量大幅度增加，造成网络拥塞，而损害他人利益的行为；</span></p>
<p><span>4）资源被利用进行网络攻击的行为或由于机器设备被计算机病毒侵染而造成攻击等一切攻击行为。</span></p>
<p><span>2、淫秽色情信息危害行为</span></p>
<p><span>1）在网站或业务平台等相关系统发布、传播淫秽色情和低俗信息的行为；</span></p>
<p><span>2）网站或业务平台引入、分发其他的网站、应用等内容，由于管理、手段缺失，而导致引入、分发内容包含色情、低俗等不良信息的行为。</span></p>
<p><span>3、计算机病毒危害行为</span></p>
<p><span>1）有意通过互联网络传播计算机病毒；</span></p>
<p><span>2）因感染计算机病毒进而影响网络和其它客户正常使用的行为。</span></p>
<p><span>4、发送或协助发送垃圾邮件行为</span></p>
<p><span>1）故意向未主动请求的客户发送或中转电子邮件广告刊物或其他资料；</span></p>
<p><span>2）故意发送没有明确的退信方法、发信人、回信地址等的邮件；</span></p>
<p><span>3）邮件服务器存在安全漏洞或开放OPENRELAY功能导致被利用转发垃圾邮件；</span></p>
<p><span>4）冒名发送恶意邮件（违反国家法律法规的）；</span></p>
<p><span>5）其它任何协助垃圾邮件发送行为、可能会导致影响网络正常运营及其它客户正常使用的邮件等。</span></p>
<p><span>5、手机恶意软件危害行为</span></p>
<p><span>1）服务器作为手机应用软件下载源所提供的下载列表中含有违规恶意软件，或者直接作为手机恶意软件的下载源，通过互联网传播手机恶意软件；</span></p>
<p><span>2）作为手机恶意软件控制服务器，对中毒用户手机进行远程控制，进行下发远程指令、收集用户隐私、不知情定制业务造成扣费等恶意行为。</span></p>
<p><span>6、语音类违法违规行为</span></p>
<p><span>1）使用</span><span>电信运营商、北京铭谦天下及关联方</span><span>提供的语音接入服务时，擅自改变业务用途及相关设置；2）利用接入的语音网络开展违法违规业务，以及任何形式的互联网协议电话（VOIP）业务。</span></p>
<p><span>7、高危地区/协助犯罪违规行为</span></p>
<p><span>1）向电信运营商、北京铭谦天下及关联方/北京铭谦天下明令禁止发送的高危地区（包括但不限于：云南、新疆、西藏）发送物联网卡</span></p>
<p><span>2）向从事违法业务和行为的单位和个人邮寄物联网卡和提供规避监管的方案。</span></p>
<p><span>四、</span><span> </span><span>发现上述违法犯罪活动和有害信息，应立即采取措施制止并及时向有关主管部门报告。对于其它破坏网络信息安全或违反国家相关法律法规规定的行为，本责任书同样适用。</span></p>
<p><span>五、</span><span> </span><span>我</span><span>单位/个人</span><span>承诺对接入的业务网站、即时通信工具、网盘、视频等进行全面检查、逐一过滤，彻底清理涉暴恐音视频等存量有害信息，关闭传播恐怖宣传视频的注册账户，并建立清理暴恐音视频工作台账。我</span><span>单位/个人</span><span>承诺向所属员工宣传国家及电信主管部门有关使用电信业务和因特网的法规和规定；建立健全使用者档案，加强对使用者管理、教育工作；有健全的网络安全保密管理办法。加强网络安全管理，在测试期间不传播客户端群发软件，通过网站发送短消息功能应严格控制发送条数并设置关键字拦截功能。</span></p>
<p><span>六、</span><span> </span><span>对于公网可访问的、无需注册即可向任意手机号发生短信动态验证码的业务（如用户注册、好友邀请、密码取回等），必须同时采用增加安全图片验证码、单IP请求次数限制、限制发送时长限制3个措施进行信息安全防护，方可开通、使用短消息类业务。我</span><span>单位/个人</span><span>采取必要手段加强网站/平台内容监测、审核、拦截，一经发现涉暴恐音视频等有害信息，立即清理，并及时上报国家相关部门。</span></p>
<p><span>七、</span><span> </span><span>我</span><span>单位/个人</span><span>在联网测试、试运行期间、业务正式开通以及合作业务推广过程中，遵守</span><span>电信运营商、北京铭谦天下及关联方（移动、联通、电信）</span><span>相关管理规定，保证所提供业务内容的安全性与稳定性，对于各类接入设备发现的高危、中危、低危漏洞及时整改，不对</span><span>电信运营商、北京铭谦天下及关联方</span><span>通信网络、相关业务平台造成危害。    </span></p>
<p><span>八、</span><span> </span><span>我</span><span>单位/个人</span><span>保证建立有效的信息安全管理制度和技术保障措施，并接受相关主管部门的管理、监督和检查，为相关主管部门提供技术支持。</span></p>
<p><span>九、</span><span> </span><span>电信运营商、北京铭谦天下及关联方</span><span>将通过技术手段、受理投诉及其它方式实时监控</span><span>电信运营商、北京铭谦天下及关联方</span><span>网络及内容信息的安全状况。对于违反国家相关法律法规的客户、不采取相应措施保证自身网络及内容信息安全的客户、恶意威胁</span><span>电信运营商、北京铭谦天下及关联方</span><span>网络安全的客户以及妨碍其他客户正常使用的客户等，</span><span>电信运营商、北京铭谦天下及关联方</span><span>将依据国家相应法规、条例以及本责任书内容采取相应处理措施以保证网络的安全。情节严重者，将报送当地司法机关进行处理。</span></p>
<p><span>十、</span><span> </span><span>电信运营商、北京铭谦天下及关联方</span><span>作为</span><span>电信运营商、北京铭谦天下及关联方</span><span>，有按要求配合国家有关部门处理互联网网络与信息安全工作的义务，包括但不限于提供客户基础资料、采取技术措施处理等。</span></p>
<p><span>十一、</span><span>北京铭谦天下</span><span>在接到投诉或发现危害互联网安全的行为后，将立即针对不同情况予以处理，直至确认客户已完成有效整改：</span></p>
<p><span>1、危害信息安全的行为：立即对非法信息源、传播途径以及互连端口等进行封堵，并进行封存取证。</span></p>
<p><span>2、网络攻击行为：立即针对其攻击途径、端口进行封堵，并进行封存取证。对于相关人员视情节轻重采取相应处理措施，直至移交国家信息安全机构及当地司法机关依法处理。</span></p>
<p><span>3、淫秽色情信息危害行为：立即删除淫秽色情和低俗信息，并查找原因分析不足，对发布的信息建立完善的审核、管控机制，进行事前审核、事中监控、事后处置，防止出现淫秽色情和低俗信息；对非法信息源、内容引入路径等进行封堵，禁止引入、发布包含淫秽色情和低俗内容的域名链接。对网络安全影响严重，可能或已经造成</span><span>电信运营商、北京铭谦天下及关联方</span><span>网络及其他客户损失时，</span><span>电信运营商、北京铭谦天下及关联方</span><span>将立即中断客户网络连接，同时以多种方式通知客户。对于相关人员视情节轻重采取相应处理措施，直至移交国家信息安全机构及当地司法机关依法处理。</span></p>
<p><span>4、计算机病毒危害行为：在接到投诉或警示发现此行为后，接入客户应首先断开与</span><span>电信运营商、北京铭谦天下及关联方</span><span>网络的连接并及时清除计算机病毒。若接入客户在接到警示后30分钟内没有采取措施改正，或被发现的计算机病毒已被证实或有理由相信其危害性对网络安全影响严重，可能或已经造成</span><span>电信运营商、北京铭谦天下及关联方</span><span>IDC网络及其他客户损失时，</span><span>电信运营商、北京铭谦天下及关联方</span><span>将立即中断客户网络连接，同时以多种方式通知客户。</span></p>
<p><span>5、发送或协助发送垃圾邮件行为：在接到投诉或警示发现此行为后，将立即对入驻客户予以警告，并列入监控名单；对于情节严重者，将立即针对参与发送或协助发送垃圾邮件的客户暂时关闭网络服务及对互连端口进行封堵。</span></p>
<p><span>十二、</span><span>对屡整屡犯、明知故犯的，情节严重的将移交国家信息安全机构、当地司法机构等相关部门并依法配合处理。对于对</span><span>北京铭谦天下</span><span>造成重大经济损失和名誉影响的，</span><span>北京铭谦天下</span><span>有权追究经济赔偿和法律责任。</span></p>
<p><span>十三、</span><span>所有签署本责任承诺书的客户必须认同</span><span>北京铭谦天下</span><span>对危害互联网安全行为的定义，并承诺承担以下责任和义务：</span></p>
<p><span>1、自觉遵守国家有关互联网管理的法律、法规，建立有效的网络安全与信息安全管理制度和技术保障手段及措施。</span></p>
<p><span>2、按照IDC网络安全行为类型，对自身网络行为和其下挂客户的网络使用行为进行管理和约束。</span></p>
<p><span>3、接受</span><span>电信运营商、北京铭谦天下及关联方</span><span>相关部门的管理、监督和检查，接受</span><span>电信运营商、北京铭谦天下及关联方</span><span>IDC网络与信息安全工作的处理方法，有责任和义务积极配合</span><span>电信运营商、北京铭谦天下及关联方</span><span>查找、清除非法网络行为，直至处理完毕。</span></p>
<p><span>4、对自身网络行为和其下挂客户的网络使用行为负全责。作为客户方第一责任人承担因非法网络行为造成的一切后果，承担相应的经济责任和法律责任。</span></p>
<p><span>5、联系方式或网络安全责任人发生变更时，应及时通知</span><span>北京铭谦天下</span><span>相关联系人员，否则导致的一切后果由客户自身承担。</span></p>
<p><span>十四、</span><span>“依据《非经营性互联网备案管理办法》第二十三条规定，如备案信息不真实，将关闭网站并注销备案。入驻客户承诺并确认：提交的所有备案信息真实有效，当备案信息发生变化时（包含在二级域名的使用过程中，对应的一级域名发生变更或取消接入等）请及时与</span><span>电信运营商、北京铭谦天下及关联方</span><span>IDC域名备案部门联系并提交更新信息，如因未及时更新而导致备案信息不准确，我公司有权依法对接入网站进行关闭处理。</span></p>
<p><span>十五、</span><span>如有其它影响网络安全和信息安全的突发事件，</span><span>电信运营商、北京铭谦天下及关联方</span><span>有权采取紧急措施，以保证网络安全。</span></p>
<p><span>十六、若违反上述规定，</span><span>电信运营商、北京铭谦天下及关联方</span><span>有权采取措施，关闭相关信息源接入通道；同时，追究责任</span><span>单位/个人</span><span>的法律责任，并且终止与责任</span><span>单位/个人</span><span>的合作。</span></p>
<p><span>我</span><span>单位/个人</span><span>愿承担上述各项信息安全管理责任，积极配合</span><span>电信运营商、北京铭谦天下及关联方</span><span>公司做好网络及信息安全管理工作。若违反上述规定，</span><span>北京铭谦天下</span><span>有权采取措施，对责任</span><span>单位/个人</span><span>进行经济惩罚以及关闭相关业务接入通道；同时，追究责任</span><span>单位/个人</span><span>的法律责任</span><span>及经济赔偿</span><span>，并且终止与责任</span><span>单位/个人</span><span>的相关业务。</span></p>
<p><span>此承诺书经</span><span>勾选同意后立即生效。</span></p>
</div>

    </React.Fragment>
  )
}

export default ArticleCon
