import React, { useState, useEffect } from 'react'
import { useSelector, } from 'react-redux'
import { Link, useLocation, useNavigate, } from 'react-router-dom'
import { WingBlank, WhiteSpace, Tabs, List, Radio, Button, Toast, Modal, } from 'antd-mobile'
import CheckForFlow from './CheckForFlow'
import HeaderPanel from '@/components_blc//HeaderPanel'
import ModalForTime from './ModalForTime'
import GLOBAL from '@/config/global'
// import { createOrderForFuelByWx, createOrderForFuelByBalance, } from '../fn'
import { onBridgeReady } from '@/utils/wechat'
import { getCache, saveCache, getCookie, saveCookie, removeCookie } from '@/utils/storage'
import { getQueryBySearch, getSearchByHash, } from '@/utils/core/url'


function PackagesFuelDo() {
  //
  const [showTips, setShowTips] = useState(false)
  const simType = 1;
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */



  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  // console.log(agreed);


  function renderTips() {
    return (
      <Modal
        visible={showTips}
        transparent
        maskClosable={false}
        title="温馨提示"
        // footer={[{ text: '好的', onPress: hideShowTips }]}
        closeOnAction
        onClose={() => {
          setShowTips(false)
        }}
        actions={[
          {
            key: 'confirm',
            text: '好的',
            primary: true,
          },
        ]}
      >
        <div style={{ textAlign: 'left', }}>
          {/* {fuelTips} */}
        </div>
      </Modal>
    )
  }

  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header">
        <HeaderPanel />
      </div>
      <div className="layoutPage-body">
        <div style={{ background: '#fff', width: '100%', overflow: 'hidden', }}>
          {simType === 1 && <CheckForFlow />}
          <div className="packageDo-others">
            {/* <div className="item" onClick={openShowTips}><span className="icon tips"></span>温馨提示</div> */}
            {/* <div className="item"><Link to="/my/bills"><span className="icon bills"></span>账单中心</Link></div> */}
          </div>
          {renderTips()}
        </div>
      </div>
      <div className="layoutPage-footer">
      </div>
    </section>
  )
}

export default PackagesFuelDo