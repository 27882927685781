import React, { useState, useEffect } from 'react'
import { useSelector, } from 'react-redux'
import { Link, useLocation, } from 'react-router-dom'
import { WingBlank, WhiteSpace, Tabs, Modal, } from 'antd-mobile'

import HeaderPanel from '@/components_blc//HeaderPanel'

import DoOrder from './DoOrder'



function Package() {
  const location = useLocation()
  //
  //
  const [showTips, setShowTips] = useState(false)
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  //
  function openShowTips() {
    setShowTips(true)
  }
  function hideShowTips() {
    setShowTips(false)
  }
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  function renderTips() {
    return (
      <Modal
        visible={showTips}
        transparent
        maskClosable={false}
        title="温馨提示"
        closeOnAction
        onClose={() => {
          setShowTips(false)
        }}
        actions={[
          {
            key: 'confirm',
            text: '好的',
            primary: true,
          },
        ]}
      >
        <div style={{textAlign: 'left',}}>
        </div>
      </Modal>
    )
  }
  


  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header">
      </div>
      <div className="layoutPage-body">
        <HeaderPanel />

        <div style={{background: '#fff'}}>
            <div style={{minHeight: '4.9rem'}}>
              <DoOrder />
            </div>
          <div className="packageDo-others">
            {/* <div className="item" onClick={openShowTips}><span className="icon tips"></span>温馨提示</div> */}
          </div>
          {renderTips()}
        </div>
        
      </div>
      <div className="layoutPage-footer">
      </div>
    </section>
  )
}

export default Package
