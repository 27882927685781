import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import HtmlTitle from '@/components_base/HtmlTitle'
import PullLoading from '@/components_base/PullLoading'
import { getCookie, saveCookie } from "@/utils/storage";

// import PublicLayout from '@/layouts/PublicLayout'

function NoOnlineWithRoute({children, title, ...rest}) {
  const navigate = useNavigate();


  useEffect(() => {
    const lastNumber = getCookie("lastno");
    console.log(lastNumber);
    if (lastNumber!= null && lastNumber !='underfined') {
      navigate('/home')
    }
  }, [])

  return (
    <React.Fragment>
      <HtmlTitle title={title} />
      <Suspense fallback={ <PullLoading />}>
        {children}
      </Suspense>
    </React.Fragment>
  )
}

export default NoOnlineWithRoute
