import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { List, Radio, Button, Toast, Modal, NoticeBar, Stepper, Checkbox, Space, Result, Dialog } from "antd-mobile";
import CheckPayMethod from "@/components_blc/CheckPayMethod";
// import ModuleLoading from "@/components_base/ModuleLoading";
import Empty from "@/components_base/Empty";
import ModalForTime from "./ModalForTime";
import DoMessage from "./DoMessage";
import SafeInfoCheckBlock from "@/components_base/SafeInfoCheckBlock";

// import usePrevious from '@/hooks/usePrevious'
import { packagesForFuelNew, testPay } from "../fn";
import { dcToPackageType, createBasicproOrder, checkPaystate } from "../fn";
import { getsCardInfo } from "@/pages/anotherhome/fn";
import { useNavigate } from "react-router-dom";
import styles from "./DoOrder.module.scss";
import { getCache, saveCache, getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { DownOutline, UpOutline, RightOutline } from "antd-mobile-icons";
import { aliases, data } from "browserslist";
import { onBridgeReady } from "@/utils/wechat";

// const alert = Modal.alert;
// const RadioItem = Radio.RadioItem;
const cacheModName = "thdOrderNumbers";
// const cacheDuration = 3 * 60 * 1000;
const showCount = 4;

function PackageDoOrder({ props }) {
  const [dataSource, setDataSource] = useState(null);
  //
  const [checked, setChecked] = useState(null);
  const [price, setPrice] = useState(0);
  const [pricetotal, setPriceTotal] = useState(0);
  const [pnum, setpnum] = useState(1);
  const [renewtype, setRenewType] = useState(2);

  const [payMethod, setPayMethod] = useState("wx");
  // const prevBalance = usePrevious(balance)
  const [triggerModalTime, setTriggerModalTime] = useState(false);
  //
  // 是否同意
  const [agreed, setAgreed] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const navigates = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [show, setshow] = useState(false);
  const [orderNumber, setorderNumber] = useState(null);
  const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
  const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];
  var number = null;

  //const [cardinfo, setCardInfo] = useState(false);
  // const { getFieldProps, validateFields, getFieldError } = props.form
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */

  useEffect(() => {
    if (cardNum) {
      number = getCookie("lastno");
      if (cardNum != number) {
        removeCookie("lastno");
        removeCookie("siminfo");
        const _opt = {
          expires: 1,
        };
        saveCookie("lastno", cardNum, _opt);
        const fournumber = cardNum.substr(0, 4);
        const teonumber = cardNum.substr(0, 2);
        let issn = 0;
        if (fournumber == 8986 || teonumber == 14) {
          issn = 0;
        } else {
          issn = 1;
        }
        removeCookie("issn");
        saveCookie("issn", issn, _opt);
        Toast.show("加载中...");
        getsCardInfo(
          { number: cardNum },
          (response) => {
            // console.log(response);
            setDataSource(response);
            saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
            Toast.clear();
          },
          () => {
            console.log(111111);
            navigates("/");
          },
          () => {}
        );
      }
    }

    number = getCookie("lastno");
    // console.log(number);
    if (number) {
      Toast.show("加载中...");
      packagesForFuelNew(
        {
          number: number,
          fueltype: 0,
        },
        (response) => {
          const checkeds = dcToPackageType(response["info"]);
          setDataSource(checkeds);
          Toast.clear();
          // setDataSource([])
        },
        () => {
          setshow(true);
        },
        () => {}
      );
    } else {
      navigates("/");
    }
  }, [cardNum, number]);

  const [conclusion, setConclusion] = useState([
    { value: 2, label: "续费生效", checked: true },
    { value: 10, label: "立即生效", checked: false },
  ]);

  function onChange(val) {
    // console.log(val);
    const list = [];
    for (let i = 0; i < conclusion.length; i++) {
      if (val === conclusion[i].value) {
        conclusion[i].checked = true;
      } else {
        conclusion[i].checked = false;
      }
      list.push(conclusion[i]);
    }
    // console.log(list);
    setConclusion(list);
    setRenewType(val);
  }

  function onClickPackage(e) {
    const _dom = e.currentTarget;
    const _id = _dom.getAttribute("data-id");
    const _index = _dom.getAttribute("data-index");
    // console.log(_id);
    // console.log(_index);
    setChecked(handleChecked(_id));
    const packageprice = dataSource.find((i) => i.value == _id).price;
    setPrice(packageprice);
    setPriceTotal(packageprice * pnum);
  }
  function handleChecked(id) {
    // console.log(id);
    return dataSource.find((item) => {
      return id === item.value;
    });
  }

  function onShowMore() {
    setShowMore(!showMore);
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  // function PackageType() {
  //   // console.log(dataSource);
  //   // console.log(checked);
  //   if (dataSource) {
  //     return (
  //       <React.Fragment>
  //         <WhiteSpace size="lg" />
  //         <div className="amList">
  //           <List>
  //             <Picker
  //               className={styles.body}
  //               data={dataSource}
  //               cols={1}
  //               value={checked}
  //               rules={[
  //                 {
  //                   required: true,
  //                   message: "请选择套餐类型",
  //                 },
  //               ]}
  //               onChange={(value) => {
  //                 setChecked(value);
  //                 if (value != -1) {
  //                   const packageprice = dataSource.find(
  //                     (i) => i.value == value[0]
  //                   ).price;
  //                   setPrice(packageprice);
  //                   setPriceTotal(packageprice * pnum);
  //                 } else {
  //                   const packageprice = 0;
  //                   setPrice(packageprice);
  //                   setPriceTotal(packageprice * pnum);
  //                 }
  //                 // console.log(value[0])
  //               }}
  //             >
  //               <List.Item arrow="horizontal" className={styles.body}>
  //                 订购套餐
  //               </List.Item>
  //             </Picker>
  //           </List>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }
  // }

  function PackageType() {
    return (
      // <WingBlank>
      // <WhiteSpace size="lg" />
      <div className="packageCheckForFlow">{renderItem()}</div>
      // {/* </WingBlank> */}
    );
  }

  function renderItem() {
    // console.log(dataSource);
    return dataSource.map((item, index) => {
      const { value, label, price } = item;
      let _checkedClass;
      if (checked) {
        _checkedClass = checked["value"] === value ? "active" : "";
      }

      if (!showMore && index > showCount - 1) {
        return null;
      }

      return (
        <div className={`cardPkgForFlow item ${_checkedClass}`} key={`${item.value}`} data-id={`${item.value}`} data-index={index} onClick={onClickPackage}>
          <div className="name">{label}</div>
          <div className="price">售价：{price.toFixed(2)}</div>
          {/* <div className="other">当月有效</div> */}
        </div>
      );
    });
  }

  function renderShowMore() {
    let _rcDom;
    if (showMore) {
      _rcDom = "收起更多";
    } else {
      _rcDom = "更多套餐";
    }
    return (
      <div style={{ textAlign: "right", padding: "0.08rem 0", fontSize: "0.24rem" }} onClick={onShowMore}>
        {_rcDom}&nbsp;{showMore ? <UpOutline fontSize={12} /> : <DownOutline fontSize={12} />}
      </div>
    );
  }

  function Pnum() {
    return (
      <React.Fragment>
        <br />
        {/* <WhiteSpace size="lg" /> */}
        <List>
          <List.Item
            extra={
              <Stepper
                className="adm-pnum"
                style={{ "--input-background-color": "var(--adm-color-background)" }}
                showNumber
                value={pnum}
                min={1}
                onChange={(value) => {
                  // console.log(value);
                  // console.log(pnum);
                  setpnum(value);
                  setPriceTotal(price * value);
                  // console.log(value)
                }}
              />
            }>
            订购周期
          </List.Item>
        </List>
      </React.Fragment>
    );
  }

  function renderPrice() {
    return (
      <React.Fragment>
        <br />
        {/* <WhiteSpace size="lg" /> */}
        <List>
          <List.Item extra={<div style={{ color: "red" }}>{pricetotal.toFixed(2)}</div>}>订购金额</List.Item>
        </List>
      </React.Fragment>
    );
  }

  function renderList() {
    return (
      <div className="pageDoOrder">
        <List>
          <List.Item
            className={styles.container}
            extra={
              //   <div className="adm-type-extra">
              //     {
              conclusion.map((i) => (
                <Checkbox className={styles.container} key={i.value} checked={i.checked} onChange={() => onChange(i.value)}>
                  {i.label}&nbsp;&nbsp;
                </Checkbox>
              ))
              //     }
              //   </div>
            }>
            选择生效方式
          </List.Item>

          {/* <div className="simsListRenew">{onChangeRadioMethod()}</div> */}
        </List>
      </div>
    );
  }

  // 关闭购买时长
  function onCloseForModalTime() {
    setTriggerModalTime(false);
  }
  // 选择支付方式
  function onCheckedPayMethod(method) {
    setPayMethod(method);
  }

  // function onClickPay() {
  //     testPay(
  //         {
  //             number: 1
  //         },
  //         (response) => {
  //             console.log(response);
  //             if (response.status = 1) {
  //                 navigates(response.message)
  //             }
  //             // setDataSource([])
  //         },
  //         () => {
  //             Toast.show("微信支付失败，请联系管理员")
  //         },
  //         () => {

  //         }
  //         // () => { },
  //         // () => { },
  //     )

  // }

  // 点击付款
  function onClickPay() {
    // const number = getCookie("lastno");
    number = getCookie("lastno");
    // console.log(checked);
    // console.log(moneymethod);
    if (!checked) {
      Toast.show("请先选择订购套餐");
      return false;
    }
    if (pnum <= 0) {
      Toast.show("请先选择订购周期");
      return false;
    }

    // 判断支付方式
    if (!payMethod) {
      Toast.show("请先选择微信支付");
      return false;
    }

    if (pricetotal <= 0) {
      Toast.show("价格异常，请联系管理员");
      return false;
    }

    //
    if (!agreed) {
      Toast.show(
        { content:<p>
          请先仔细阅读并同意
          <br />
          《个人信息保护政策》和《单独同意书》
        </p>})
      //   Toast.info(
      //     <p>
      //       请先仔细阅读并同意
      //       <br />
      //       《信息安全承诺书》
      //     </p>,
      //     2
      //   );
      return false;
    }
    if (renewtype) {
      if (renewtype == 10) {
        const pricefee = pricetotal.toFixed(2);
        const _rcDom = <div style={{ fontSize: "0.32rem", color: "#333", padding: "0.15rem 0" }}>立即生效将作废其他已订购套餐和叠加包,确定选择立即生效吗？</div>;
        Dialog.confirm({
          content: _rcDom,
          onConfirm: async () => {
            doPay({
              orderTarget: checked.value,
              ordertype: renewtype * 1,
              orderMonth: pnum,
              number: number,
              // fee: pricefee,
              // fee,
            });
          },
        });
        // alert("", _rcDom, [
        //     { text: "取消", onPress: () => { } },
        //     {
        //         text: "确定",
        //         onPress: () => {
        //             doPay({
        //                 orderTarget: checked.value,
        //                 ordertype: renewtype * 1,
        //                 orderMonth: pnum,
        //                 number: number,
        //                 // fee: pricefee,
        //                 // fee,
        //             });
        //         },
        //     },
        // ]);
      } else if (renewtype == 2) {
        const pricefee = pricetotal.toFixed(2);
        const _rcDom = <div style={{ fontSize: "0.32rem", color: "#333", padding: "0.15rem 0" }}>已生效套餐到期后生效当前订购套餐,确定选择续费生效吗？</div>;
        Dialog.confirm({
          content: _rcDom,
          onConfirm: async () => {
            doPay({
              orderTarget: checked.value,
              ordertype: renewtype * 1,
              orderMonth: pnum,
              number: number,
              // fee: pricefee,
              // fee,
            });
          },
        });
        // alert("", _rcDom, [
        //     { text: "取消", onPress: () => { } },
        //     {
        //         text: "确定",
        //         onPress: () => {
        //             doPay({
        //                 orderTarget: checked.value,
        //                 ordertype: renewtype * 1,
        //                 orderMonth: pnum,
        //                 number:number,
        //                 // fee: pricefee,
        //                 // fee,
        //             });
        //         },
        //     },
        // ]);
      }
    }
  }

  // 付款
  function doPay(postData) {
    console.log(postData);
    if (payMethod === "wx") {
      Toast.show("正在创建订单中...");
      createBasicproOrder(
        postData,
        (response) => {
          // console.log(response);
          const { type, go_model, preorderno, orderno } = response;

          // Toast.clear();
          // console.log(type);
          // console.log(go_model);
          if (type == 1) {
            console.log("跳转支付");
            doPayForWechat(go_model);
          } else {
            //
            setorderNumber(orderno);
            // setorderNumber(preorderno)
            const _rcDom = (
              <div style={{ fontSize: "0.21rem", color: "#333", padding: "0.15rem 0", textAlign: "left" }}>
                <p>1.如果您已在打开微信支付内支付成功,请点击'已支付'按钮验证是否完成订单. </p>

                <p style={{ paddingTop: "0.1rem" }}>2.如果您未完成支付可点击'取消'按钮,重新发起订单支付.</p>
              </div>
            );

            Dialog.show({
              title: "请确认微信支付是否已完成",
              content: _rcDom,
              closeOnAction: true,
              actions: [
                [
                  {
                    key: "cancel",
                    text: "取消",
                  },
                  {
                    key: "confirm",
                    danger: true,
                    text: "已完成付款",
                    onClick: () => {
                      if (orderno) {
                        checkPaystate(
                          {
                            orderno: orderno,
                          },
                          (response) => {
                            console.log(response);
                            if (response.type == 3) {
                              Toast.show({
                                //icon: "success",
                                content: response.message + ",正在跳转主页...",
                                duration: 3000,
                                afterClose: () => {
                                  navigates("/anotherhome");
                                },
                              });
                            } else {
                              Toast.show({
                                // icon: "fail",
                                content: response.message,
                                duration: 3000,
                              });
                            }
                          },
                          () => {},
                          () => {}
                        );
                      }
                    },
                  },
                ],
              ],
              //style: styles.dialog,
            });

            // Dialog.confirm({
            //   content: _rcDom,
            //   cancelText: "关闭",
            //   confirmText: "查看订单是否提交",
            //   onConfirm: async () => {
            //     if (orderno) {
            //       checkPaystate(
            //         {
            //           orderno: orderno,
            //         },
            //         (response) => {
            //           console.log(response);
            //           if (response.type == 3) {
            //             Toast.show({
            //               icon: "success",
            //               content: response.message,
            //               duration: 3000,
            //               afterClose: () => {
            //                 navigates("/anotherhome");
            //               },
            //             });
            //           } else if (response.type == 1) {
            //             Toast.show({
            //               icon: "fail",
            //               content: response.message,
            //               duration: 3000,
            //             });
            //           } else if (response.type == 0) {
            //             Toast.show({
            //               content: response.message,
            //               duration: 3000,
            //             });
            //           } else if (response.type == 2) {
            //             Toast.show({
            //               content: response.message,
            //               duration: 3000,
            //             });
            //           } else {
            //             Toast.show({
            //               content: response.message,
            //               duration: 3000,
            //             });
            //           }
            //         },
            //         () => {},
            //         () => {}
            //       );
            //     }
            //   },
            // });
            // saveCache(cacheModName, {
            //   preorderno,
            //   orderno,
            // });
            window.location.href = response.go_model;
          }
        },
        () => {},
        () => {}
      );
    }
  }

  // 调起微信支付
  function doPayForWechat(postData) {
    // console.log(postData)
    onBridgeReady(
      postData,
      () => {
        handleOk();
      },
      () => {},
      () => {}
    );
  }

  // 支付成功回调
  function handleOk() {
    //
    Toast.show("支付成功");
    setTriggerModalTime(false);
    setTimeout(() => {
      //  window.location.href = go_model["url"];
      navigates.push("/anotherhome");
    }, 2000);
  }

  // 协议
  function handleChangeAgree(checked) {
    setAgreed(checked);
    // console.log(`协议：${checked}`)
  }
  function onMessage() {
    setShowChat(true);
  }
  function onCloseChat() {
    setShowChat(false);
  }
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  function renderPay() {
    return (
      <React.Fragment>
        <br />
        <div className="am-body">
          <div className="am-list">
            <CheckPayMethod defaultChecked={payMethod} key={payMethod} />
          </div>
          <br />
          <br />
          <SafeInfoCheckBlock initCheck={agreed} onChange={handleChangeAgree} />
          <br />
          <Button type="primary" className="am-button green am-button-primary" style={{ color: "#fff" }} onClick={onClickPay}>
            确认付款
          </Button>
        </div>
      </React.Fragment>
    );
  }

  let _isRenderMore = false;
  if (dataSource && dataSource.length > showCount) {
    _isRenderMore = true;
  }

  // () => alert(' 所有套餐订购周期都是基础周期*n,如月套餐:1*n,季套餐:3*n,半年包:6*n,年包:12*n (个月).\n 订购周期:所选套餐的基础上*订购周期,如 XXX月套餐*1,最终的订购周期就是1*订购周期数!如有疑问请联系客户经理帮您解答!')
  return (
    // <WingBlank size="lg">
    <div className="order-body">
      {/* <WhiteSpace size="lg" /> */}
      <br />
      {dataSource && dataSource.length > 0 && (
        <div className="order-contact">
          <NoticeBar
            content="温馨提示:
          所有套餐订购周期都是基础周期*n,如月套餐:1*n,季套餐:3*n,半年包:6*n,年包:12*n
          (个月).订购周期:所选套餐的基础上*订购周期,如
          XXX月套餐*1,最终的订购周期就是1*订购周期数!如有疑问请联系客户经理帮您解答!"
            color="alert"
            extra={
              // <Space style={{ '--gap': '12px' }}>
              <div onClick={onMessage}>
                <RightOutline />
              </div>
              // </Space>
            }
            //   marqueeProps={{ loop: true, style: { padding: "0 7.5px" } }}
          />
          <DoMessage visible={showChat} onClose={onCloseChat} />
        </div>
      )}
      <br />
      {dataSource && dataSource.length === 0 && (
        <div style={{ height: "4.5rem", position: "relative" }}>
          <Empty caption="暂无套餐" />
        </div>
      )}
      {/* { dataSource && dataSource.length > 0 && <CheckBlock key={simid} dataSource={dataSource} onChecked={onChecked} simType={simType} /> } */}
      {dataSource && dataSource.length > 0 && PackageType()}
      {_isRenderMore && renderShowMore()}
      {dataSource && dataSource.length > 0 && Pnum()}
      <br />
      {dataSource && dataSource.length > 0 && renderList()}
      {dataSource && dataSource.length > 0 && renderPrice()}
      {checked && triggerModalTime && (
        <ModalForTime
          dataSource={checked}
          number={number}
          //   onSubmit={doPay}
          onClose={onCloseForModalTime}
        />
      )}
      {dataSource && dataSource.length > 0 && renderPay()}
      {show && <Result status="info" title="信息提示" description="所选卡无可订购套餐,请联系客服处理!" />}
    </div>
    // </WingBlank>
  );
}

export default PackageDoOrder;
