import React from 'react'

function ArticleCondan() {
    //
    /** 
     * *****************************************************************
     * *****************************************************************
     * 订阅
     * *****************************************************************
     * *****************************************************************
     */
    /** 
     * *****************************************************************
     * *****************************************************************
     * 回调
     * *****************************************************************
     * *****************************************************************
     */

    /** 
     * *****************************************************************
     * *****************************************************************
     * 渲染
     * *****************************************************************
     * *****************************************************************
     */

    return (
        <React.Fragment>
            <div>
                <div className="am-xinxi-context">单独同意书</div>
                <p><span>本人向贵司（我司）申请提供精选靓号、话费、流量、宽带、影视会员VIP折扣等通信产品服务，鉴于贵司在业务审查、审批或者其他业务过程需要收集及使用本人信息，本人已充分知悉并明确同意：</span></p>
                <p><span>鉴于贵司为本人提供前述服务，本人同意，贵司有权向本人所选购通信产品对应的合作运营商（以下称为“信息接收方”）提供并处理贵司基于《个人信息保护政策》所收集的本人下单时提交的个人信息，且本人确认贵司已向本人充分告知、本人也明确知悉信息接收方及信息处理的情况如下：</span></p>
                <p><span>本政策将帮助您了解以下内容：</span></p>
                <p><span>（1）</span></p>
                <p><span>名称：中国移动通信集团有限公司及其子公司、分公司</span></p>
                <p><span>联系方式：10086</span></p>
                <p><span>处理目的：向运营商同步用户下单提交的信息，由运营商提供后续产品服务</span></p>
                <p><span>个人信息种类：个人基本信息（包括姓名、电话号码、身份证）</span></p>
                <br />
                <p><span>（2）</span></p>
                <p><span>名称：中国联合网络通信股份有限公司及其子公司、分公司</span></p>
                <p><span>联系方式：10010</span></p>
                <p><span>处理目的：向运营商同步用户下单提交的信息，由运营商提供后续产品服务</span></p>
                <p><span>处理方式：订单信息回传</span></p>
                <p><span>个人信息种类：个人基本信息（包括姓名、电话号码、身份证）</span></p>
                <br />
                <p><span>（3）</span></p>
                <p><span>名称：中国电信股份有限公司及其子公司、分公司</span></p>
                <p><span>联系方式：10000</span></p>
                <p><span>处理目的：向运营商同步用户下单提交的信息，由运营商提供后续产品服务</span></p>
                <p><span>处理方式：订单信息回传</span></p>
                <p><span>个人信息种类：个人基本信息（包括姓名、电话号码、身份证）</span></p>
                <p><span>本人在此声明：</span></p>
                <p><span>本人已充分理解上述条款的含义，充分了解该等信息处理活动依赖互联网开展而互联网环境并非绝对安全，本人仍作出上述同意。贵司已经对相关事宜与风险向本人进行充分披露及说明，本人已知晓并同意。本人承诺并保证，本人在本同意书项下所提供的所有本人信息真实、准确、完整、有效，且不会侵犯任何第三方的合法权利。</span></p>
            </div>

        </React.Fragment>
    )
}

export default ArticleCondan