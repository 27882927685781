import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WingBlank, WhiteSpace, Button, Modal, Icon, Toast } from 'antd-mobile'

import usePrevious from '@/hooks/usePrevious'
import styles from './modalPkgForTime.module.scss'

import { multiply } from '@/utils/core/math'
// import { createAdvanceOrder } from '../../fn'

// const _timeRadios = [
//   { value: 1, label: 1, },
//   { value: 3, label: 3, },
//   { value: 6, label: 6, },
//   { value: 12, label: 12, },
// ]
const _timeRadios = [
  { value: 1, label: 1, },
  { value: 2, label: 2, },
]

function ModalForTime({children, dataSource, number, onSubmit, ...props}) {
  const { simid, } = useSelector(state => state.user)
  const [checked, setChecked] = useState(null)
  const prevChecked = usePrevious(checked)
  const [checkedFee, setCheckedFee] = useState(null)
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  // 默认选中第一个
  useEffect(() => {
    if (dataSource && !prevChecked) {
      handleChecked(`${_timeRadios[0]['value']}`)
    }
  }, [dataSource])
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function onClick(e) {
    const _dom = e.currentTarget
    const _value = _dom.getAttribute('data-value')
    handleChecked(_value)
  }
  function handleChecked(valueStr) {
    setChecked(valueStr)
    setCheckedFee(multiply(valueStr, dataSource['fee']))
  }
  // 确认付款
  function onClickPay() {
    const { id } = dataSource
    onSubmit && onSubmit({
      packageid: id,
      fee: checkedFee,
      cardid: simid,
      orderunit: checked * 1,
    })
  }
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  function renderRadios() {
    return _timeRadios.map((item) => {
      const _isAcitve = checked === `${item['value']}` ? 'active' : null
      return (
        <div className={`${styles.timeCheckRadio} item ${_isAcitve}`} key={`time-${item['value']}`} data-value={item['value']} onClick={onClick}>
          {item['label']}个月<span className={styles.checkedIcon} style={{display: _isAcitve ? 'block' : 'none'}}></span>
        </div>
      )
    })
  }

  return (
    <Modal
      className={styles.container}
      transparent
      visible={true}
      animationType="slide-up"
      title="购买时长"
      {...props}
    >
      <div className={styles.body}>
        <div className={styles.timeCheckBlock}>
          {renderRadios()}
          {/* <div className={`${styles.timeCheckRadio} item active`}>
            1个月<span className={styles.checkedIcon} style={{display: 'block'}}><Icon type="check" size="xs" /></span>
          </div> */}
        </div>
        <div className={styles.checkedInfo}>
          费用：<span className={styles['checkedInfo-price']}>¥ {checkedFee}</span>
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="primary" className="green" onClick={onClickPay}>确认</Button>
      </div>
    </Modal>
  )
}

export default ModalForTime