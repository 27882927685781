import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, Redirect } from "react-router-dom";
import { Button, Toast } from "antd-mobile";
import HeaderPanel from "@/components_blc/HeaderPanel";
import { useNavigate } from "react-router-dom";
// import
import HtmlTitle from "@/components_base/HtmlTitle";
import Exception from "@/pages/Exception";
import WhiteListIndex from "./WhiteListIndex";
import WhiteAdd from "./WhiteAdd";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
document.title = "语音白名单"; //头部title
function WhiteList() {
  // const [simid, setsimid] = useState(getCookie("simid"));
  // useEffect(() => {
  //   setsimid(getCookie("simid"));
  // }, [simid]);
  const navigates = useNavigate();
  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header">
        <HeaderPanel />
      </div>
      <div className="layoutPage-body">
        <section className="mayBeNeed">
          <div className="mayBeNeed-title">语音白名单记录</div>
          <WhiteListIndex />
        </section>
        <br />
        <section style={{ background: "#fff", borderTop: "1px solid #ddd", height: "2rem" }}>
          <br />
          <br />
          <Button type="primary" className="am-button am-button-primary" style={{ "--background-color": "#108ee9", color: "#fff" }} onClick={() => navigates("/WhiteAdd")}>
            新增白名单
          </Button>
          <br />
        </section>
        <br />
      </div>
      <div className="layoutPage-footer"></div>
    </section>
  );
}

export default WhiteList;
