import React, { useState, useEffect } from "react";
import { PullToRefresh, InfiniteScroll, List, Toast } from "antd-mobile"; 
import { Link, useNavigate } from "react-router-dom"; 
import Empty from "@/components_base/Empty";
import { getsRecordList } from "../fn";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url"; 

let pageIndex = 0;
const pageSize = 10; 
function ListBlock({ params }) {
  const navigates = useNavigate();
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [number, setnumber] = useState(null);
  const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
  const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];
  var siminfo = getCookie("siminfo");
  const _opt = {
    expires: 1,
  };
  useEffect(() => {
    if (siminfo) {
      setnumber(getCookie("lastno"));
    }
    if (!siminfo) {
      navigates("/");
    }
    if (cardNum && cardNum != number) {
      removeCookie("lastno");
      setnumber(cardNum);
      saveCookie("lastno", cardNum, _opt);
    }
  }, []);
//获取数据
  async function loadMore() {
    if (number) {
      const append = await getsRecordList({ pageIndex: pageIndex + 1, pageSize: pageSize, number: number });
      pageIndex++;
      setData((val) => [...val, ...append]);
      setHasMore(append.length > 0);
    }
  }
  //组装数据
  function renderList() {
    let _rcDom = null;
    if (data.length > 0) {
      const listItems = data.map((item, index) => {
        let _classNameLabel;
        let _label;
        let _rcDomErr = null;
        if (item.isSuccess === 1) {
          _classNameLabel = "color_green";
          _label = "发送成功";
        } else {
          _classNameLabel = "color_red";
          _label = "发送失败";
          _rcDomErr = <div className="errMsg">{item.errorMessage}</div>;
        }
        return (
          <div className="smsListItem1" key={`sms-${index}`}>
            <div>
              发送至: {item.msisdn} 时间：{item.creatime}
              <div className={`label ${_classNameLabel}`}>发送状态：{_label}</div>
            </div>
            <div>
              发送内容：{item.content}
              {/* 其他信息{_rcDomErr} */}
            </div>
          </div>
        );
      });
      _rcDom = listItems;
    } else if (data.length === 0) {
      _rcDom = <Empty caption="查无短信记录" />;
    }
    return _rcDom;
  } 
  return (
    <div className="smsList" style={{ height: "100%" }}>
      {renderList()}
      {<InfiniteScroll loadMore={loadMore} hasMore={hasMore} />}
    </div>
  );
}

export default ListBlock;
