import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd-mobile'

import picEmpty from '@/assets/images/empty.png'

function MayBeNeed({caption, desc, action}) {
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <section className="emptyBlock">
      <div className="emptyBlock-icon"><img src={picEmpty} alt="" /></div>
      <div className="emptyBlock-caption">{caption}</div>
      <div className="emptyBlock-desc">{desc}</div>
      {action && (<div className="emptyBlock-action">
        {action}
      </div>)}
    </section>
  )
}

{/* <section className="emptyBlock">
  <div className="emptyBlock-icon"><img src={picEmpty} alt="" /></div>
  <div className="emptyBlock-caption">下月套餐为空</div>
  <div className="emptyBlock-desc">请尽快充值，避免您的卡到期停机</div>
  <div className="emptyBlock-action">
    <Button type="ghost">立即充值</Button>
  </div>
</section> */}

export default MayBeNeed