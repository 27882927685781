import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Modal, NoticeBar, Toast } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'
import '../style.scss'
import picIcon01 from '@/assets/images/avatar_dx.jpg'
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getCookie, saveCookie } from "@/utils/storage";
import { getsCardInfo } from "../fn";




function HomeHeader() {

    const [dataSource, setDataSource] = useState(null)
    /** 
     * *****************************************************************
     * *****************************************************************
     * 订阅
     * *****************************************************************
     * *****************************************************************
     */

    useEffect(() => {
        Toast.show('加载中...');
        const number = getCookie("lastno");
        getsCardInfo(
            { number, },
            (response) => {
                Toast.clear()
                setDataSource(response)
            },
            () => { },
            () => { },
        )
    }, [])
    /** 
 * *****************************************************************
 * *****************************************************************
 * 渲染
 * *****************************************************************
 * *****************************************************************
 */


    function renderWalletBlock() {
        if (dataSource) {
            const { id, iccid, cardStateName, simactivedate, oper, } = dataSource
            const _thumbClass = ((oper) => {
                if (oper === 1) return 'oper_yd'
                if (oper === 2) return 'oper_lt'
                if (oper === 3) return 'oper_dx'
                return ''
            })(oper)
            return (
                <div className="aui-mine-box">
                    <div className='aui-flex'>
                        <div className={`aui-user ${_thumbClass}`}></div>
                        <div className='aui-flex-box'>
                            <h2>{iccid}</h2> 
                            <p>状态：{cardStateName}</p> 
                            <br/>
                            <p>激活时间：{simactivedate}</p>
                        </div>
                    </div>
                    <div className='van-notice-bar'>
                        <NoticeBar content='9月份的卡请提前充值' color='info' />
                    </div>
                </div>
            )
        }
    }


    return (
        dataSource && renderWalletBlock()
    )

}

export default HomeHeader