import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/simInfo'
import CONFIG from '@/config/global'


/**
 * 将 backend 数据转换成UI格式
 * @param {*} types 
 */
   export function dcToPackageType(types) {
    let _result = []
    types.map(item => {
      const { key, value,title,price } = item
      _result.push({
        key:key,
        value: value,
        label: title,
        price:price
      })
    })
    return _result
  }


  /**
 * 获取叠加包(新)
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function packagesForFuelNew(postData, ok, failed, end) {
    request.post(apis.packagesForFuelNew, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }

    /**
 * 测试支付
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function testPay(postData,ok, failed, end) {
  request.post(apis.testPay,postData)
    .then(function (response) {
      // ok && ok(response)
      return response
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 套餐充值
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function createBasicproOrder(postData, ok, failed, end) {
  request.post(apis.createBasicproOrder, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 查询支付状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkPaystate(postData, ok, failed, end) {
  request.post(apis.checkPaystate, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}