import React, { useState, useEffect } from 'react'
 //import { Icon, } from 'antd-mobile'
import { Icon } from 'antd-mobile-icons'
import { Space, SpinLoading } from 'antd-mobile'
function Slogan({ ...props}) {
  const [aniClassName, setAniClassName] = useState(null)
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {
    // if (dataSource) {
      setAniClassName('fadeIn')
    // }
  }, [])
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  //

  return (
    <div className="pageAcDo-slogan" {...props}>
      <React.Fragment>
        {/* <p className={`pageAcDo-slogan-aniItem ${aniClassName}`}>移动</p> */}
       <p className={`pageAcDo-slogan-aniItem ${aniClassName}`} style={{transitionDelay: '.4s'}}>查  询</p>
      </React.Fragment>

    </div>
  )
}

export default Slogan
