import React, { useState, useEffect } from 'react'
import { useHistory, } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { WingBlank, WhiteSpace, Checkbox, } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'

import CheckAgreeGeRenmentBlock from '@/components_base/CheckAgreeGeRenmentBlock'
import ArticleCon from './ArticleCon'

import usePrevious from '@/hooks/usePrevious'

function SafeInfoCheckBlock({...rest}) {
  //
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <div>
    <CheckAgreeGeRenmentBlock {...rest} fileName="《个人信息保护政策》和《单独同意书》" >
      {/* <WingBlank> */}
        <article className="articleBlock">
          <div className="am-xinxi-context">个人信息保护政策</div>
          <div className="am-xinxi-body"><ArticleCon /></div>
          <div className="am-xinxi-context">单独同意书</div>
        </article>

      {/* </WingBlank> */}
    </CheckAgreeGeRenmentBlock>
    </div>
  )
}

export default SafeInfoCheckBlock
