import React from 'react'
import { Link } from 'react-router-dom'
// import { Button } from 'antd'

/**
 * 背景地址，待处理
 */
function Exception({type='404'}) {

  let _h
  let _desc
  let _ctype
  switch(type){
    case '403':
      _h = '403'
      // _desc = intl['exc.403.desc']
      _ctype = 'excBox-403'
      break
    case '500':
      _h = '500'
      // _desc = intl['exc.500.desc']
      _ctype = 'excBox-500'
      break
    default:
      _h = '404'
      // _desc = intl['exc.404.desc']
      _ctype = 'excBox-404'
  }

  // const _desc = 
  return (
    <section className={['excBox', _ctype].join(' ')}>
      <div className="excBox-imgBlock">
        <div className="excBox-imgEle"></div>
      </div>
      <div className="excBox-content">
        <h1>{_h}</h1>
        <div className="excBox-desc">{_desc}</div>
        {/* <Button type="primary"><Link to="/">{intl['exc.next.button']}</Link></Button> */}
      </div>
    </section>
  )
}

export default Exception