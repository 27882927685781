import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Progress,Divider,Toast } from 'antd-mobile'
import usePrevious from '@/hooks/usePrevious'
import { getCookie, saveCookie } from "@/utils/storage";
import { getsCardInfo } from "../fn";



function FuelBlock() {

    const [loadingUsage, setLoadingUsage] = useState(true)
    const prevLoadingUsage = usePrevious(loadingUsage)
    const [dataSource, setDataSource] = useState(null)
    /** 
     * *****************************************************************
     * *****************************************************************
     * 订阅
     * *****************************************************************
     * *****************************************************************
     */
     useEffect(() => {
        Toast.show('加载中...');
        const number =getCookie("lastno");
        getsCardInfo(
            { number, },
            (response) => {
                Toast.clear()
                setDataSource(response)
            },
            () => { },
            () => { },
        )
    }, [])

    /** 
 * *****************************************************************
 * *****************************************************************
 * 回调
 * *****************************************************************
 * *****************************************************************
 */
    //
    function handleRefresh() {
        setLoadingUsage(true)
    }
    /** 
     * *****************************************************************
     * *****************************************************************
     * 渲染
     * *****************************************************************
     * *****************************************************************
     */
    function renderWalletBlock() {
        if (dataSource) {
            const { expireTime,usage,totalpackage,amount} = dataSource
            const _percentFlow = ((usage / totalpackage) * 100).toFixed(1)
            return (
                <div className="pageHome-fuelBlock">
                <div className="pageHome-fuelContainer">
                    <div className="pageHome-fuelCard pageHome-fuelCard-flow">
                        <div className="pageHome-fuelCard-refresh" onClick={handleRefresh}>刷新</div>
                        <span className="pageHome-fuelCard-total" style={{ color: '#666' }}>总流量 <span style={{ color: 'red' }}>{totalpackage}M</span></span>
                        <div className="pageHome-fuelCard-progess">
                            <div className="pageHome-fuelCard-progess-bar" style={{ width: `${_percentFlow}%` }}></div>
                        </div>
                        <div className="pageHome-fuelCard-detail">
                            <div className="left">
                                <div className="name" style={{ color: '#666' }}>已使用流量</div>
                                <div className="count">{usage}M</div>
                            </div>
                            <div className="right">
                                <div className="name" style={{ color: '#666' }}>剩余流量</div>
                                <div className="count" style={{ color: 'red' }}>{amount}M</div>
                            </div>
                        </div>
                        <div className='divSpan'>
                            <Divider
                                style={{
                                    color: '#1677ff',
                                    borderColor: '#1677ff',
                                }}
                                plain
                            >
                                到期时间
                            </Divider>
                            <span>{expireTime}</span><br/>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }

    return (
        dataSource && renderWalletBlock()
    )

}

export default FuelBlock