import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/haoka'
import CONFIG from '@/config/global'
/**
 * 查询号卡订单
 * @param {*} postData
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
 export function checkCardInfo(postData, ok, failed, end) {
  request.post(apis.haokaorderseach, postData)
    .then(function (response) {
     // console.log(response)
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}
