//
export const SET_SYSTEM_CONFIG = 'SET_SYSTEM_CONFIG'
export function setSystemConfig(config) {
  return {
    type: SET_SYSTEM_CONFIG,
    data: config
  }
}

export const SET_LANG = 'SET_LANG'
export function setLang(lang) {
  return {
    type: SET_LANG,
    data: lang
  }
}

export const SET_THEME = 'SET_THEME'
export function setTheme(option) {
  return {
    type: SET_THEME,
    data: option
  }
}

export const RESET_THEME = 'RESET_THEME'
export function resetTheme() {
  return {
    type: RESET_THEME
  }
}

export const SET_DEVICE = 'SET_DEVICE'
export function setDevice(device) {
  return {
    type: SET_DEVICE,
    data: device
  }
}

export const UPDATE_LAYOUT = 'UPDATE_LAYOUT'
export function updateLayout() {
  return {
    type: UPDATE_LAYOUT
  }
}
