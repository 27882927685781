import React, { useState, useEffect } from "react";
import { WingBlank, WhiteSpace, Button, List, Toast, DotLoading, NoticeBar } from "antd-mobile";

import usePrevious from "@/hooks/usePrevious";
import HeaderPanel from "@/components_blc/HeaderPanel";
import { checkState, checkRegionlimitstatus, getsCardInfo, checkClientstate, checkRestrictedarea, checkSession } from "./fn.js";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { logDOM } from "@testing-library/react";
import { useNavigate } from "react-router-dom";

function IntelDiagnosis() {
  const navigates = useNavigate();
  //
  const [trigger, setTrigger] = useState(null);
  const prevTrigger = usePrevious(trigger);
  const [loadingnum, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  //
  const [checkSimstate, setCheckSimstate] = useState("-");
  // const [oper, setOper] = useState(null)
  const [checkRegionlimitstatu, setCheckRegionlimitstatu] = useState("-");
  const [stopstatu, setstopstatu] = useState(null);
  const [checkRestrictedareas, setCheckRestrictedareas] = useState("-");
  const [checkClientstates, setClientstates] = useState("-");
  const [checkActiveClientstates, setActiveClientstates] = useState("-");
  const [checkip, setIp] = useState("-");
  const [checkapnname, setApnname] = useState("-");
  const [checkstarttime, setStarttime] = useState("-");
  const [checknetworktypename, setNetworktypename] = useState("-");
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [checkSimstateLoading, setCheckSimstateLoading] = useState(false);
  const [checkRegionlimitstatuLoading, setCheckRegionlimitstatuLoading] = useState(false);
  const [checkRestrictedareasLoading, setCheckRestrictedareasLoading] = useState(false);
  const [checkClientstatesLoading, setClientstatesLoading] = useState(false);
  const [checkActiveClientstatesLoading, setActiveClientstatesLoading] = useState(false);
  const [checkipLoading, setIpLoading] = useState(false);
  const [checkapnnameLoading, setApnnameLoading] = useState(false);
  const [checkstarttimeLoading, setStarttimeLoading] = useState(false);
  const [checknetworktypenameLoading, setNetworktypenameLoading] = useState(false);
  var siminfo = getCookie("siminfo");
  const [cardinfo, setCardinfo] = useState(null);
  const [number, setNumber] = useState(getCookie("lastno"));
  // var number =null;
  // const cardinfo = JSON.parse(siminfo);
  // const number = cardinfo.iccid;
  // 获取 cardNum
  const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
  const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];

  useEffect(() => {
    if(siminfo)
    {
      setCardinfo(JSON.parse(siminfo));
      // setNumber(getCookie("lastno"))
    }
    if (!siminfo) {
      navigates('/')
    }

  }, [])

  useEffect(() => {

    if (cardNum) {
      console.log(number);
      if (cardNum != number) {
        removeCookie("lastno");
        removeCookie("siminfo")
        const _opt = {
          expires: 1,
        };
        saveCookie("lastno", cardNum, _opt);
        const fournumber = cardNum.substr(0, 4);
        const teonumber = cardNum.substr(0, 2);
        let issn = 0;
        if (fournumber == 8986 || teonumber == 14) {
          issn = 0;
        } else {
          issn = 1;
        }
        removeCookie("issn");
        saveCookie("issn", issn, _opt);
        Toast.show('加载中...');
        getsCardInfo(
          { number:cardNum, },
          (response) => {
            console.log(response);
            setNumber(response.iccid)
            setCardinfo(response);
            saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
            Toast.clear()
          },
          () => {
            console.log(111111);
            navigates('/')
          },
          () => {

          },
        )
      }
    }
  }, [])


  useEffect(() => {
    //console.log(checkSimstateLoading, checkRegionlimitstatuLoading, checkRestrictedareasLoading, checkClientstatesLoading, checkActiveClientstatesLoading, checkipLoading, checkapnnameLoading, checkstarttimeLoading, checknetworktypenameLoading)
    if (checkSimstateLoading && checkRegionlimitstatuLoading && checkRestrictedareasLoading && checkClientstatesLoading && checkActiveClientstatesLoading && checkipLoading && checkapnnameLoading && checkstarttimeLoading && checknetworktypenameLoading) {
      setLoading(true);
    }
    if (!checkSimstateLoading && !checkRegionlimitstatuLoading && !checkRestrictedareasLoading && !checkClientstatesLoading && !checkActiveClientstatesLoading && !checkipLoading && !checkapnnameLoading && !checkstarttimeLoading && !checknetworktypenameLoading) {
      setLoading(false);
    }
  }, [checkSimstateLoading, checkRegionlimitstatuLoading, checkRestrictedareasLoading, checkClientstatesLoading, checkActiveClientstatesLoading, checkipLoading, checkapnnameLoading, checkstarttimeLoading, checknetworktypenameLoading]);
  // 同步信息
  useEffect(() => {
    if (updateTrigger) {
      // updateDetails()
      setCheckSimstate(null);
      setstopstatu(null);
      setCheckRegionlimitstatu(null);
      setCheckRestrictedareas(null);
      setClientstates(null);
      setActiveClientstates(null);
      setIp(null);
      setApnname(null);
      setStarttime(null);
      setNetworktypename(null);

      setCheckSimstateLoading(true);
      setCheckRegionlimitstatuLoading(true);
      setCheckRestrictedareasLoading(true);
      setClientstatesLoading(true);
      setActiveClientstatesLoading(true);
      setIpLoading(true);
      setApnnameLoading(true);
      setStarttimeLoading(true);
      setNetworktypenameLoading(true);

      selectSim();
      selectRegionlimitstatus();
      selectRestrictedarea();
      selectSession();
      selectClientstate();
    }
  }, [updateTrigger]);

  function handleClickUpdate() {
    setUpdateTrigger(true);
    setLoading(true);
  }

  function selectSim() {
    // console.log(number);
    // console.log(cardNum);
    if (number) {
      checkState(
        { number },
        (response) => {
          setCheckSimstateLoading(false);
          // console.log(response)
          if (response && (response.key == -1 || response.key == 201)) {
            setCheckSimstate("-");
          } else {
            if (response && response.key != 200) {
              setCheckSimstate("停机");
              setstopstatu(response.value);
            } else {
              setCheckSimstate(response.value);
            }
          }
          setUpdateTrigger(false);
          // Toast.clear()
          // setDataSource(response)
          // setLoadingUsage(false)
        },
        () => {
          console.log(111111);
        },
        () => { }
      );
    }
  }

  function selectRegionlimitstatus() {
    // console.log(number);
    // console.log(cardNum);
    if (number) {
      checkRegionlimitstatus(
        { number },
        (response) => {
          setCheckRegionlimitstatuLoading(false);
          // console.log(response)
          if (response && response.regionLimitStatus == -1) {
            setCheckRegionlimitstatu("-");
          } else {
            setCheckRegionlimitstatu(response.regionLimitStatus);
          }
          setUpdateTrigger(false);
        },
        () => {
          console.log(111111);
        },
        () => { }
      );
    }
  }

  function selectRestrictedarea() {
    // console.log(number);
    // console.log(cardNum);
    if (number) {
      checkRestrictedarea(
        { number },
        (response) => {
          setCheckRestrictedareasLoading(false);
          // console.log(response)
          if (response && response.arearestricttype == -1) {
            setCheckRestrictedareas("-");
          } else {
            setCheckRestrictedareas(response.restrictedarea);
          }
          setUpdateTrigger(false);
        },
        () => {
          console.log(111111);
        },
        () => { }
      );
    }
  }

  function selectSession() {
    // console.log(number);
    // console.log(cardNum);
    if (number) {
      checkSession(
        { number },
        (response) => {
          // console.log(response)
          setActiveClientstatesLoading(false);
          setIpLoading(false);
          setApnnameLoading(false);
          setStarttimeLoading(false);
          setNetworktypenameLoading(false);
          if (response && response.clientstate == -2) {
            setActiveClientstates("-");
          } else {
            setActiveClientstates(response.clientstatename);
          }

          if (response && response.ip == "") {
            setIp("-");
          } else {
            setIp(response.ip);
          }

          if (response && response.apnname == "") {
            setApnname("-");
          } else {
            setApnname(response.apnname);
          }

          if (response && response.starttime == "") {
            setStarttime("-");
          } else {
            setStarttime(response.starttime);
          }

          if (response && response.networktypename == -2) {
            setNetworktypename("-");
          } else {
            setNetworktypename(response.networktypename);
          }

          setUpdateTrigger(false);
        },
        () => {
          console.log(111111);
        },
        () => { }
      );
    }
  }

  function selectClientstate() {
    // console.log(number);
    // console.log(cardNum);
    if (number) {
      checkClientstate(
        { number },
        (response) => {
          setClientstatesLoading(false);
          // console.log(response)
          if (response && (response.clientstate == -2 || response.clientstate == -1)) {
            setClientstates("-");
          } else {
            setClientstates(response.clientstatename);
          }
          setUpdateTrigger(false);
        },
        () => {
          console.log(111111);
        },
        () => { }
      );
    }
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  function renderCheckList() {
    // console.log(checkSimstate)
    //? checkSimstate : <DotLoading color='primary' />
    return (
      <List header="智能检测">
        <List.Item
        // extra={
        //     <Button onClick={selectSim} >重调</Button>
        // }
        >
          卡状态: {checkSimstate}
          {stopstatu && "("}
          {stopstatu && <span style={{ color: "#f44336" }}> {stopstatu} </span>}
          {stopstatu && ")"}
          {checkSimstateLoading && <DotLoading color="primary" />}
        </List.Item>
        <List.Item>
          卡在线状态：{checkActiveClientstates == "离线" ? <span style={{ color: "#f44336" }}>{checkActiveClientstates} </span> : checkActiveClientstates}
          {checkActiveClientstatesLoading && <DotLoading color="primary" />}
        </List.Item>
        {(cardinfo && cardinfo.oper == 1 )&& (
          <List.Item>
            设备开关机状态: {checkClientstates == "关机" ? <span style={{ color: "#f44336" }}>{checkClientstates} </span> : checkClientstates}
            {checkClientstatesLoading && <DotLoading color="primary" />}
          </List.Item>
        )}
        <List.Item>
          网络类型：{checknetworktypename}
          {checknetworktypenameLoading && <DotLoading color="primary" />}
        </List.Item>
        {(cardinfo && cardinfo.oper == 1 ) && (
          <List.Item>
            区域限制状态: {checkRegionlimitstatu}
            {checkRegionlimitstatuLoading && <DotLoading color="primary" />}
          </List.Item>
        )}
        {(cardinfo && cardinfo.oper == 1 ) && (
          <List.Item>
            区域限制区域: {checkRestrictedareas}
            {checkRestrictedareasLoading && <DotLoading color="primary" />}
          </List.Item>
        )}
        <List.Item>
          APN: {checkapnname}
          {checkapnnameLoading && <DotLoading color="primary" />}
        </List.Item>
        <List.Item>
          IP: {checkip}
          {checkipLoading && <DotLoading color="primary" />}
        </List.Item>
        <List.Item>
          最后上线时间：{checkstarttime}
          {checkstarttimeLoading && <DotLoading color="primary" />}
        </List.Item>
      </List>
    );
  }

  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header">
        <HeaderPanel />
      </div>
      <div className="layoutPage-body">
        <NoticeBar content={"智能检测数据可能存在延迟,仅供参考!!!"} color="alert" />
        {renderCheckList()}
        <br />
        {/* <br/> */}
        {loadingnum ? (
          //checkSimstateLoading && checkRegionlimitstatuLoading && checkRestrictedareasLoading && checkClientstatesLoading && checkActiveClientstatesLoading && checkipLoading && checkapnnameLoading && checkstarttimeLoading && checknetworktypenameLoading
          <Button className="am-button green am-button-primary" disabled color="primary" >
            正在加载检测,请稍后...
          </Button>
        ) : (
          <Button
            // type="primary"
            className="am-button green am-button-primary"
            style={{ color: "#fff" }}
            // onClick={async () => {
            //   await handleClickUpdate();
            // }}
            onClick={
              handleClickUpdate
            }
          >
            智能检测
          </Button>
        )}
      </div>
      <div className="layoutPage-footer"></div>
    </section>
  );
}

export default IntelDiagnosis;
