//
export const SET_OAUTH_CODE = 'SET_OAUTH_CODE'
export function setOauthCode(data) {
  return {
    type: SET_OAUTH_CODE,
    data: data
  }
}

export const SET_OAUTH_OPENID = 'SET_OAUTH_OPENID'
export function setOauthOpenID(data) {
  return {
    type: SET_OAUTH_OPENID,
    data: data
  }
}
