import request from '@/utils/request'
import apis from '@/config/apis/jump'



/**
 * 获取电信接口得token
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getDianXinJumpTokenUrl(ok, failed, end) {
    request.post(apis.getDianXinJumpTokenUrl)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }