import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    // this.state = { hasError: false }
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    // return { hasError: true }
    return { error: true }
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    // logErrorToMyService(error, errorInfo)
    this.setState({
      error, errorInfo
    })
  }

  refresh() {
    window.location.reload()
  }

  render() {
    if (this.state.error) {
      let _errShow = this.props.demotion || (
        <React.Fragment>
          <h1 style={{padding: '30px 30px 0 30px', fontSize: '18px'}}>抱歉，出错了，请<span style={{color: '#0e87f2'}} onClick={this.refresh}>刷新重试</span>.</h1>
          <div style={{padding: '0 30px', marginTop: '10px'}}>{this.state.error.toString()}</div>
          <div style={{padding: '0 30px', marginTop: '10px'}}>{this.state.error.componentStack}</div>
        </React.Fragment>
      )
      // 你可以自定义降级后的 UI 并渲染
      return _errShow
    }

    return this.props.children
  }
}

export default ErrorBoundary
