import * as ActionTypes from './action'
import { getCookie } from '@/utils/storage'

window.getCookie = getCookie

const _localAuth = getCookie('authorization') || null
const _localUserInfo = getCookie('userinfo') ? JSON.parse(getCookie('userinfo')) : {}

// console.log(`获取本地用户登录信息: `)
// console.log(_localAuth)

// 加载本地缓存
let initState = {
  'username': null,
  'avatar': null,
  'role': null,
  'authorization': null,
  'lasttime': null,
  'online': false,
  //
  'userid': null,
  'number': null,
  'iccid': null,
  'msisdn': null,
  'phone': null,
  'simid': null,
  'simType': null,
  'simState': null,
  // 'simStateName': null,
  'loginMethod': null,
  'simHasWhite': null,
}
let localState = {
  'authorization': _localAuth,
  'online': _localAuth ? true : false,
  'id': _localUserInfo['id'] ? _localUserInfo['id'] : null,
  'userid': _localUserInfo['userid'] ? _localUserInfo['userid'] : null,
  'number': _localUserInfo['number'] ? _localUserInfo['number'] : null,
  'numberShow': _localUserInfo['numberShow'] ? _localUserInfo['numberShow'] : null,
  'iccid': _localUserInfo['iccid'] ? _localUserInfo['iccid'] : null,
  'msisdn': _localUserInfo['msisdn'] ? _localUserInfo['msisdn'] : null,
  'phone': _localUserInfo['phone'] ? _localUserInfo['phone'] : null,
  'simid': _localUserInfo['simid'] ? _localUserInfo['simid'] : null,
  'simType': _localUserInfo['simType'] ? _localUserInfo['simType'] : null,
  'simState': _localUserInfo['simState'] ? _localUserInfo['simState'] : null,
  // 'simStateName': _localUserInfo['simStateName'] ? _localUserInfo['simStateName'] : null,
  'loginMethod': _localUserInfo['method'] ? _localUserInfo['method'] : null,
  'simHasWhite': _localUserInfo['simHasWhite'] ? _localUserInfo['simHasWhite'] : null,
  'isNeedReal': _localUserInfo['isNeedReal'] ? _localUserInfo['isNeedReal'] : null,
  'isReal': _localUserInfo['isReal'] ? _localUserInfo['isReal'] : null,
  'realUrl': _localUserInfo['realUrl'] ? _localUserInfo['realUrl'] : null,
  'interfaceType': _localUserInfo['interfaceType'] ? _localUserInfo['interfaceType'] : null,
  'cancelAccount': _localUserInfo['cancelAccount'] ? _localUserInfo['cancelAccount'] : null,
  'order': _localUserInfo['order'] ? _localUserInfo['order'] : null,
}
let initState1 = {
  'username': 'ajay',
  'avatar': 'agege',
  'role': 'agege',
  'authorization': 'agegeage',
  'lasttime': 'agege',
  'online': true,
  //
  'number': 'msisdn4646465',
  'iccid': 'iccid64646465',
  'msisdn': 'msisdn4646465',
  'phone': '159****1235',
  'simid': 1,
  'loginMethod': 'normal',
}
let localState1 = {
  // 'authorization': _localAuth,
  'online': true
}
// 具体字段待确定

// console.log(localState)

export default function user(state = Object.assign({}, initState, localState), action) {
  switch(action.type) {
    case ActionTypes.USER_LOGIN:
      // const { iccid, msisdn, phone, number, simid, loginMethod, } = action.data
      const { loginMethod, } = action.data
      // console.log(action.data)
      return Object.assign({}, state, {
        // username, avatar, role, authorization, lasttime,
        // iccid, msisdn, phone, number, simid, loginMethod,
        loginMethod: loginMethod || 'normal',
        online: true
      })
    case ActionTypes.USER_LOGOUT:
      return Object.assign({}, initState)
    case ActionTypes.SET_USERINFO:
      return Object.assign({}, state, action.data)
    default:
      return state
  }
}

// export default function user(state = Object.assign({}, initState, localState), action) {
//   switch(action.type) {
//     case ActionTypes.USER_LOGIN:
//       // const { username, avatar, role, authorization, lasttime } = action.data
//       return Object.assign({}, state, {
//         // username, avatar, role, authorization, lasttime,
//         online: true
//       })
//     case ActionTypes.USER_LOGOUT:
//       return Object.assign({}, initState)
//     case ActionTypes.SET_USERINFO:
//       return Object.assign({}, state, action.data)
//     default:
//       return state
//   }
// }
