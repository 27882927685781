import React, { useState, useEffect } from 'react'
import { useHistory, } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { WingBlank, WhiteSpace, Checkbox, } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'

import CheckAgreementBlock from '@/components_base/CheckAgreementBlock'
import ArticleCon from './ArticleCon'

import usePrevious from '@/hooks/usePrevious'

function SafeInfoCheckBlock({...rest}) {
  //
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <CheckAgreementBlock {...rest} fileName="《信息安全承诺书》">
      {/* <WingBlank> */}
        <article className="articleBlock">
          <div className="am-xinxi-context">信息安全承诺书</div>
          <div className="am-xinxi-body"><ArticleCon /></div>
        </article>
      {/* </WingBlank> */}
    </CheckAgreementBlock>
  )
}

export default SafeInfoCheckBlock
