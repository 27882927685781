import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, } from 'antd-mobile'


function IotTipsModal({dataSource,}) {
  //
  const [visibleModal, setVisibleModal] = useState(false)
  
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {
    if (dataSource) {
      setVisibleModal(true)
    }
  }, [dataSource])
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function handleCloseModal() {
    setVisibleModal(false)
  }
  
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */


  const { title, content, footer, } = dataSource || {}
  
  const _footer = []
  if (footer) {
    footer.map(item => {
      const { text, onPress, } = item
      _footer.push({
        text,
        onPress: () => {
          onPress()
          handleCloseModal()
        }
      })
    })
  }

  return (
    <Modal
      className={`iotTips-modal`}
      visible={visibleModal}
      transparent
      maskClosable={false}
      closable={true}
      onClose={handleCloseModal}
      title={title}
      footer={_footer}
    >
      {content}
    </Modal>
  )
}

export default IotTipsModal
