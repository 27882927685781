const prefix = '/h5'
const prefixOauth = '/oauth2.0'

export default {
  login: `${prefix}/login/do`,
  //
  findGetCode: `${prefix}/findphone/find-get-code`,
  findReset: `${prefix}/findpassword/find-password`,
  findPhoneVerify: `${prefix}/findphone/find-verify-phone`,
  //
  getUserInfo: `${prefix}/global/get-userinfo`,
  getUserBalance: `${prefix}/global/get-user-balance`,
  //
  userForCheck: `${prefix}/inter-check/check-config`,
  userIpCheck: `${prefix}/pi-check/task-run`,
}