import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, WingBlank, WhiteSpace, DatePicker, Picker, PullToRefresh, Icon } from 'antd-mobile'
import dayjs from 'dayjs'

import usePrevious from '@/hooks/usePrevious'
// import FilterBlock from './FilterBlock'
import ListBlock from './ListBlock'
import HeaderPanel from '@/components_blc//HeaderPanel'
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";


// import { billsTypes, dcToBillsTypes, } from './fn'

function SmsRecord() {
  const navigates = useNavigate()
  //
  const [filterParams, setFilterParams] = useState({})

  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {

  }, [])
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header">
      <HeaderPanel />
      </div>
      <div className="layoutPage-body">
      <section style={{ background: '#fff', borderTop: '1px solid #ddd',height:'2rem' }}>
          <br/>
          <br/>
          <Button type="primary" className="am-button am-button-primary" style={{'--background-color': '#108ee9',color:'#fff'}} onClick={() => navigates('/dosend')}>发送短信</Button>
          <br/>
        </section>
        <br/>
        <section className="mayBeNeed">
        <div className="mayBeNeed-title">短信记录</div>
        <ListBlock params={filterParams} />
        </section>
      </div>
      <div className="layoutPage-footer">
      </div>
    </section>
  )
}

export default SmsRecord