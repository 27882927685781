import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Toast, WhiteSpace, DatePicker, Picker, Popup, Image, Result } from "antd-mobile";
import axios from "axios";
import usePrevious from "@/hooks/usePrevious";
import Auth from "@/routers/Auth";
import dayjs from "dayjs";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { setOauthCode, setOauthOpenID } from "@/redux/wechat/action";
import CONFIG from "@/config/global";
import { getQueryBySearch, getSearchByHash, getUrlParams, getUrlParamstoken } from "@/utils/core/url";
import { getWeChatCodeUrl, getWeChatOpenID } from "@/utils/wechat";
import OperatingInstructions from "./OperatingInstructions";

// import { billsTypes, dcToBillsTypes, } from './fn'

function Authbind() {
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [closestate, setclosestate] = useState(false);
  const [close, setclose] = useState(false);
  //

  const _urlSearch = window.location.search || getSearchByHash(window.location.hash);
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));

  const token = getUrlParamstoken("token", window.location.href);
  const type = _urlSearch["type"] || _urlSearchHash["type"];
  const remindtype = _urlSearch["tyremindtypee"] || _urlSearchHash["remindtype"];

  const { code } = _urlSearch ? getQueryBySearch(_urlSearch) : {};
  console.log(code); 
  const [oauthCodeState, setOauthCodeState] = useState(null);
  const prevOauthCodeState = usePrevious(oauthCodeState);
  //
  const [oauthOpenIDState, setOauthOpenIDState] = useState(null);
  const prevOauthOpenIDState = usePrevious(oauthOpenIDState);
  const [visible, setvisible] = useState(false);
  var ua = window.navigator.userAgent.toLowerCase();
  const uuu = ua.match(/MicroMessenger/i) == "micromessenger";
  document.title = "微信授权"; //头部title
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {
    const { search, hash } = window.location;
    // 获取授权code链接
    if (!code && uuu) {
      //   Toast.show("授权申请");
      let _hash = hash || "#/";
      let _url = `${search}${_hash}`;
      getWeChatCodeUrl(
        { url: _url, type: type },
        (url) => {
          //   console.log(`授权申请：${url}`);
          window.location.href = url;
        },
        () => {}
      );
    } else if (!uuu) {
      Toast.show("请在微信中打开!!!");
      setclosestate(true);
    }
    if (!oauthCodeState && code) {
      setOauthCodeState(code);
    }
    if (loading) {
      if (oauthCodeState && !oauthOpenIDState && uuu) {
        console.log(oauthCodeState);
        Toast.show("正在授权...");
        getWeChatOpenID(
          { code: oauthCodeState, type: type * 1, token: token, remindtype: remindtype * 1 },
          (openid) => {
            Toast.show("授权成功!");
            setclosestate(true);
            setOauthOpenIDState(openid);
            console.log(openid);
          },
          () => {}
        );
      }

      // 这里一次性处理redux状态
      if (oauthOpenIDState !== prevOauthOpenIDState) {
        dispatch(setOauthCode(oauthCodeState));
        dispatch(setOauthOpenID(oauthOpenIDState));
        // 缓冲0.5h，以天为单位
        const _opt = {
          expires: 1,
        };
        saveCookie(
          CONFIG["oauth"],
          JSON.stringify(
            Object.assign(
              {},
              {
                // 'oauth_code': oauthCodeState,
                oauth_openid: oauthOpenIDState,
              }
            )
          ),
          _opt
        );
        //
        axios.defaults.headers.common["x-openid"] = oauthOpenIDState;
        // 消除href-code
        // redirectForCode()

        // console.log('********')
        // console.log(oauthCodeState)
        // console.log(prevOauthOpenIDState)
        // setTimeout(() => {
        redirectForCode();

        // }, 100)
      }
      setLoading(false);
    }
  }, [loading, oauthCodeState, code, oauthOpenIDState, prevOauthOpenIDState]);

  useEffect(() => {
    if (close) {
      if (uuu) {
        // 微信内置浏览器
        console.log("微信内关闭");
        window.WeixinJSBridge.call("closeWindow");
      } else {
        //其他浏览器
        console.log("微信外内关闭");
        window.close();
      }
    }
  }, [close]);
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  // 重定向清除code
  function redirectForCode() {
    const { origin, pathname, hash, search } = document.location;
    // console.log(`${pathname}${hash}`)
    document.location.href = `${pathname}${search}${hash}`;
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  function handleTriggerClose() {
    setvisible(false);
  }
  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header"></div>
      <div className="layoutPage-body">
        <section style={{ background: "#fff", borderTop: "1px solid #ddd", height: "2rem" }}>
          <br />
          <br />
          {!closestate ? (
            <div>
              <Result status="info" title="微信授权" description="请点击下方授权按钮进行微信授权" />
              <Button
                color="primary"
                className="am-button am-button-primary"
                // loading={loading}
                onClick={() => setLoading(true)}>
                授权
              </Button>
              <Button
                block
                onClick={() => {
                  setvisible(true);
                }}>
                消息提醒操作指引
              </Button>
              <OperatingInstructions visible={visible} onClose={handleTriggerClose}></OperatingInstructions>
            </div>
          ) : (
            <div>
              {" "}
              {uuu ? (
                <div>
                  <Result status="success" title="授权成功" description="授权成功,请在平台刷新页面查看或发送测试验证是否成功绑定!" />
                  <Button color="success" className="am-button am-button-primary" onClick={() => setclose(true)}>
                    关闭
                  </Button>
                </div>
              ) : (
                <div>
                  {" "}
                  <Result status="error" title="微信授权" description="请在微信中扫码授权!!!" />{" "}
                  <Button color="danger" className="am-button am-button-primary" onClick={() => setclose(true)}>
                    关闭
                  </Button>
                </div>
              )}
              <Button
                block
                onClick={() => {
                  setvisible(true);
                }}>
                消息提醒操作指引
              </Button>
              <OperatingInstructions visible={visible} onClose={handleTriggerClose}></OperatingInstructions>
            </div>
          )}
          <br />
        </section>
      </div>
      <div className="layoutPage-footer"></div>
    </section>
  );
}

export default Authbind;
