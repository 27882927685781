import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals';
 import { Provider } from 'react-redux'
import store from './redux/store'
import { Button } from 'antd-mobile/es'
import ErrorBoundary from '@/components_base/ErrorBoundary'



const root = ReactDOM.createRoot(document.getElementById('iotcRoot'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <ErrorBoundary>
  <Provider store={store}>
  <App />
  </Provider>
  </ErrorBoundary>
); 
//require('promise.prototype.finally').shim()
// const root = ReactDOM.createRoot(document.getElementById('iotcRoot'));
// root.render(
//   <React.StrictMode>
//     < App />
//   </React.StrictMode>
// );
// ReactDOM.createRoot(document.getElementById('iotcRoot')).render(
//   // <Button block color="primary" size="large">
//   //   按钮
//   // </Button> 
//   <Provider store={store}>
//     < App />
//     {/* <DevTools /> */}
//   </Provider>
// )
// ReactDOM.render(
//   <ErrorBoundary>
//     <Provider store={store}>
//       < App />
//       {/* <DevTools /> */}
//       {/* <Button block color="primary" size="large">
//         按钮
//       </Button> */}
//     </Provider>
//   </ErrorBoundary>
//   ,
//   document.getElementById('iotcRoot')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// ReactDOM.render(
//   <ErrorBoundary>
//     <Provider store={store}>
//       < App />
//       {/* <DevTools /> */}
//       {/* <Button block color="primary" size="large">
//         按钮
//       </Button> */}
//     </Provider>
//   </ErrorBoundary>,
//   document.getElementById('iotcRoot')
// );
// serviceWorker.unregister();
//reportWebVitals();
