import React, { useState, useEffect } from 'react'
import { useHistory, } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { WingBlank, WhiteSpace, Checkbox, Modal, } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'

import PopupForArticle from '@/components_base/PopupForArticle'

import usePrevious from '@/hooks/usePrevious'

function CheckAgreementBlock({initCheck = true, prefixDesc, fileName, onChange = () => {}, children, }) {
  //
  const [visible, setVisible] = useState(false)
  //
  const [checked, setChecked] = useState(initCheck)
  const prevChecked = usePrevious(checked)

  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {
    if (checked !== prevChecked) {
      console.log(checked);
      onChange && onChange(checked)
    }
  }, [checked, prevChecked])
  
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function handleTriggerOpen() {
    setVisible(true)
  }
  function handleTriggerClose() {
    setVisible(false)
  }
  // function handleChangeCheck(e) {
  //   console.log(e.target.checked);
  //   setChecked(e.target.checked)
  // }

  

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  const _prefixDesc = prefixDesc || '我已阅读并同意'
  const _fileName = (
    <span onClick={handleTriggerOpen}>{fileName}</span>
  )

  return (
    <React.Fragment>
      <div className="checkAgreementBlock" style={{color:'rgb(204, 204, 204)',margin:'0.2rem 6px',padding:'16px 0'}}>
        <div className="checkBox"><Checkbox style={{'--font-size': 'inherit',height: '1em'}} defaultChecked={checked} 
        // onChange={handleChangeCheck} 
        onChange={val => {
          console.log(val);
          console.log(visible);
          setChecked(val)
        }}
        /></div>
        <div className="main">{_prefixDesc}{_fileName}</div>
      </div>

      <PopupForArticle visible={visible} onClose={handleTriggerClose}>{children}</PopupForArticle>
    </React.Fragment>
  )
}

export default CheckAgreementBlock
