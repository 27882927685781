// import { useHistory } from 'react-router-dom'
/**
 * 获取URL查询参数
 * @param {*} searchString 
 */
export function getUrlParams(name, str) {
  console.log(str)
  var j = str.substring(str.indexOf('=') + 1, str.length);
  console.log(j)
  var jj = j.substring(0, j.indexOf('&'));
  console.log(jj)
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`,);
  const r = str.substr(1).match(reg);
  // console.log(r)
  if (r != null) return decodeURIComponent(r[2]); return null;
}
export function getUrlParamstoken(name, str) {
  console.log(str)
  var j = str.substring(str.indexOf(`${name}=`) + name.length+1, str.length);
  console.log(j)
  var jj = j.substring(0, j.indexOf('&'));
  console.log(jj)
   return jj;
}

/**
 * 获取URL查询参数
 * @param {*} searchString 
 */
export function getQueryBySearch(searchString) {
  let queryMap = {}
  const _queryItems = searchString.substr(1).split('&')
  _queryItems.map(item => {
    const _item = item.split('=')
    queryMap[`${_item[0]}`] = decodeURI(_item[1])
  })
  return queryMap
}

/**
 * 通过hash,获取URL查询参数
 * @param {*} hashString 
 */
export function getSearchByHash(hashString) {
  const _queryItems = hashString.split('?')
  return `?${_queryItems[1]}`
}

/**
 * 将最新转换成search
 * @param {*} obj 
 */
export function getSearchByObject(obj) {
  if (Object.keys(obj).length > 0) {
    let _search = Object.keys(obj).map(key => {
      return `${key}=${obj[key]}`
    }).join('&')
    return `?${_search}`
  } else {
    return ''
  }
}

// /**
//  * 跳转
//  * @param {*} nextUrl 
//  */
// export function pathTo(nextUrl) {
//   const history = useHistory()
//   nextUrl && history.push(nextUrl)
// }

/**
 * 补充url前缀，默认http
 * @param {*} url 
 */
export function prefix2Url(url) {
  let _newUrl
  const _isMatch = /^(http:\/\/|https:\/\/)/.test(url)
  if (_isMatch) {
    _newUrl = url
  } else {
    _newUrl = `http://${url}`
  }
  return _newUrl
}

/**
 * 消除href-code
 */
export function redirectForCode() {
  const { origin, pathname, hash, search, } = document.location
  const { code, ...rest } = search ? getQueryBySearch(search) : {}
  const _search = getSearchByObject(rest)
  // console.log(_search)
  // console.log(`${origin}${pathname}${_search}${hash}`)
  document.location.href = `${origin}${pathname}${_search}${hash}`
}