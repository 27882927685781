import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Grid, Toast } from "antd-mobile";
import { UserCircleOutline } from "antd-mobile-icons";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getDianXinJumpTokenUrl, getYiDongJumpUrl } from "./fn.js";
import CONFIG from "@/config/global";

// import ModuleLoading from '@/components_base/ModuleLoading'
import picMenu01 from "@/assets/images/icon_menu2/icon_menu2_001.png";
import picMenu02 from "@/assets/images/icon_menu2/icon_menu2_002.png";
import picMenu08 from "@/assets/images/icon_menu2/icon_menu2_008.png";
import picMenu10 from "@/assets/images/icon_menu2/icon_menu2_010.png";
import picMenu13 from "@/assets/images/icon_menu2/icon_menu2_013.png";
import picMenu15 from "@/assets/images/icon_menu2/icon_menu2_015.png";
import picMenu16 from "@/assets/images/icon_menu2/icon_menu2_007.png";

import picMenuMore from "@/assets/images/icon_menu2/icon_menu2_more.png";

// import { getHomeMenu, } from './fn'

const renderData = [
  { type: 1, icon: picMenu01, text: "套餐充值", url: "/package" },
  { type: 2, icon: picMenu02, text: "叠加包充值", url: "/packagefuel" },
  { type: 3, icon: picMenu10, text: "智能检测", url: "/intelDiagnosis" },
  { type: 4, icon: picMenu13, text: "短信服务", url: "/smsrecord" },
  { type: 5, icon: picMenu08, text: "问题反馈", url: "/problemfeedback" },
  { type: 6, icon: picMenu15, text: "实名认证", url: "" },
  { type: 7, icon: picMenu16, text: "语音白名单", url: "/whitelist" },
];

const renderMoreData = {
  type: 1000,
  icon: picMenuMore,
  text: "更多",
  url: "/my/fastEntry",
};

// 套餐充值 0，叠加包充值 2，余额充值 3，新卡激活 4，我的套餐 5，在线补卡 6，亲情号码 7，账单中心 8
// 电商投诉 9，智能检测 10，批量激活 11， 批量续费 12

let attrCancel;
let relatedMap = renderData.map((item) => {
  // console.log(item);
  return item["type"];
});

function MenuFastEntry({ showType }) {
  const navigates = useNavigate();
  //   const { simid, number, } = useSelector(state => state.user)
  const [dataSource, setDataSource] = useState(null);
  const [oper, setoper] = useState(0);
  const [number, setnumber] = useState(null);
  const [visible, setVisible] = useState(true);
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  const rows = [
    { id: 1, type: 1, typeName: "套餐充值", name: "套餐充值" },
    { id: 2, type: 2, typeName: "叠加包充值", name: "叠加包充值" },
    { id: 3, type: 3, typeName: "智能检测", name: "智能检测" },
    { id: 4, type: 4, typeName: "短信服务", name: "短信服务" },
    { id: 5, type: 5, typeName: "问题反馈", name: "问题反馈" },
    { id: 6, type: 6, typeName: "实名认证", name: "实名认证" },
    { id: 7, type: 7, typeName: "语音白名单", name: "语音白名单" },
  ];

  // 临时针对获取menu接口只返回投诉的情况
  useEffect(() => {
    const _resultData = [];
    rows.map((item) => {
      // console.log(item);
      const { type, name } = item;
      const _relatedIndex = relatedMap.indexOf(type);
      if (_relatedIndex >= 0) {
        _resultData.push(
          Object.assign({}, renderData[_relatedIndex], {
            text: name,
          })
        );
      }
    });
    setDataSource(_resultData);
    setoper(getCookie("oper"));
    setnumber(getCookie("number"));
  }, []);
  function onCopyLink(record) {
    const input = document.createElement("input");
    input.value = record;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand("Copy");
    document.body.removeChild(input);
    Toast.show("复制ICCID成功,跳转后粘贴实名！");
    setVisible((v) => false);
  }
  const listItems =
    dataSource &&
    dataSource.map((item, index) => (
      <div className="pageHome-meaufasrnty-icon" key={index}>
        {/* <img src={item.icon} alt="flow" onClick={() => navigates(item.url)} />
          <br />
          <Grid.Item key={index} onClick={() => navigates(item.url)}>
            {item.text}
          </Grid.Item> */}
        <img src={item.icon} alt="flow" onClick={() => runURL(item)} />
        <br />
        <Grid.Item key={index} onClick={() => runURL(item)}>
          {item.text}
        </Grid.Item>
      </div>
    ));
  function runURL(item) {
    console.log(oper);
    console.log(item);
    if (item.type == 6) {
      if (oper == 1) {
        Toast.show("正在跳转,如果跳转失败请刷新页面继续...", 0);
        getYiDongJumpUrl(
          { number },
          (response) => {
            // console.log(response);
            const { code, rul } = response;
            setTimeout(() => {
              if (code === "0") {
                window.location.href = `${rul}`;
              } else {
                Toast.show("跳转失败,移动服务器未响应,请稍后刷新页面重试", 0);
              }
            }, 3000);
          },
          () => {},
          () => {}
        );
      } else if (oper == 3) {
        onCopyLink(number);
        Toast.show("跳转失败,移动服务器未响应,请稍后刷新页面重试", 0);
        navigates("/Jump");
      } else {
        Toast.show("联通卡暂不支持跳转实名,请联系客户");
      }
    } else {
      navigates(item.url);
    }
  }

  // const listItems1 = dataSource && dataSource.map((item, index) =>
  //     <img src={item.icon} alt="flow" />
  // );

  return (
    <div className="menuBlock" style={{ position: "relative", minHeight: "3rem" }}>
      <div className="pageHome-meaufasrnty">
        <Grid className="adm-gridmean" columns={4} gap={4}>
          {listItems}
        </Grid>
      </div>
    </div>
  );
}

export default MenuFastEntry;
