import axios from 'axios'
import React from 'react'
// import { message } from 'antd'
import { Toast } from 'antd-mobile'
import global from '../config/global'
import CryptoJS from 'crypto-js'
import { getCookie, removeCookie } from '../utils/storage'
// import store from '../redux/store'
// import moment from 'moment'
import dayjs from 'dayjs'


import { signBuilder } from '../utils/core/base'
import { redirectForCode, } from '../utils/core/url'

window.signBuilder = signBuilder
window.dayjs = dayjs

const { requestBaseURL, appID, appKEY, version } = global
// const { dispatch } = store

//
const { oauth_openid, } = getCookie(global['oauth']) ? JSON.parse(getCookie(global['oauth'])) : {}

window.axios = axios
// Global axios defaults
axios.defaults.baseURL = requestBaseURL
// axios.defaults.timeout = 3000
axios.defaults.headers.common['x-token'] = getCookie('authorization') || 'nothing'
axios.defaults.headers.common['x-version'] = global['version']
axios.defaults.headers.common['x-openid'] = getCookie('oauth_openid') || 'nothing'// 2020-07-27 oauth_openid
// axios.defaults.headers.post['Content-Type'] = ''
axios.defaults.validateStatus = (status) => {
  return status >= 200 && status < 400
}
// Interceptors
axios.interceptors.request.use(config => {
  const { data, method } = config
  // console.log(config)
  let _comboData = data
  // let _comboData = data
  let _sign
  const _nowTimestamp = new Date().getTime()

  const _injectData = {
    v: version,
    appId: appID,
    transid: dayjs(_nowTimestamp).format('YYYYMMDDHHmmssSSS'),
    timestamp: _nowTimestamp
  }
  // console.log(_comboData)
  // console.log(JSON.stringify(_comboData))

  if (method === 'post') {
    // _comboData = Object.assign({}, _comboData, _injectData)
    // 2020/06/09 改成直接 data: postDataString
    if (_comboData) {
      const _hash = CryptoJS.MD5(JSON.stringify(_comboData))
      //console.log(_comboData)
      _comboData = Object.assign({}, {
        data: CryptoJS.enc.Hex.stringify(_hash)
        // data: JSON.stringify(_comboData)
      }, _injectData)
    } else {
      _comboData = _injectData
    }
      // console.log(_comboData)
    // console.log(CryptoJS.enc.Hex.stringify(CryptoJS.MD5('abc')))
    _sign = signBuilder(_comboData, appKEY)
    _comboData['sign'] = _sign
  }
  config['params'] = Object.assign({}, _injectData, {
    sign: _sign
  }
  )
  // config['data'] = Object.assign({}, _comboData)
  // console.log(config)
  // console.log(_comboData)
  // get
  // post
  // timestamp
  // signaure
  // console.log('request:::')
  // console.log(config)
  return config
}, error => {
  // console.log('request 拦截器:')
  return Promise.reject(error)
})
axios.interceptors.response.use(response => {
  // console.log(response)

  // 业务级别错误处理
  // 这里可以进行全局错误提示，譬如antd的message
  const { status, data } = response.data
  if (status === 1) {
    return data
  } else if (status === -1 || status === -11 || status === -12 || status === -16 || status === -18) {
    // message.error(`[ERROR:${response.data.status}]${response.data.message}`, 1.2)
    // [ERROR:${response.data.status}]

    Toast.show({
      // icon: 'fail',
      content: `${response.data.message}`,
    })
    removeCookie('authorization')
    removeCookie('oauth_openid')
    console.log(response.data.message)
    setTimeout(() => {
      // dispatch(userLogout())
    }, 1250)
    return null
  } else if (status === 57 || status === 58) {
    // oauth_code openid 错误
    const _rcDom = <div>
      <p>{response.data.message}</p>
      <p>请刷新页面重试！</p>
    </div>

    removeCookie(global['oauth'])
    setTimeout(() => {
      // dispatch(setOauthCode(null))
      // dispatch(setOauthOpenID(null))
      // dispatch(userLogout())
      redirectForCode()
    // }, 1250)
    }, 50)
    throw {
      code: status,
      message: response.data.message
    }
  } else {
    console.log(`ERROR: ${status} from ${response.config.baseURL + response.config.url}`)
    // [ERROR:${status}]
    //Toast.show(`${response.data.message}`, 2)
    Toast.show({
      // icon: 'fail',
      content: response.data.message,
      duration: 3000,
    })
    // message.error(`[ERROR:${response.data.status}]${response.data.message}`, 1.2)
    throw {
      code: status,
      message: response.data.message
    }
  }
}, error => {
  // 系统级别错误处理
  console.log('response 错误拦截器:')
  console.log(error)
  // console.log(error.code)
  /* 重点 403 404
  4** 客户端错误，请求包含语法错误或无法完成请求
  5** 服务器错误，服务器在处理请求的过程中发生了错误
  */
 Toast.show({
  // icon: 'fail',
  content: `服务器错误`,
})
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    // 超时
  }
  return Promise.reject(error)
})

// 数据持久化控制

/*
 *
 */

export default {
  get:      axios.get,
  delete:   axios.delete,
  head:     axios.head,
  options:  axios.options,
  post:     axios.post,
  put:      axios.put,
  patch:    axios.patch,
  all:      axios.all,
  spread:   axios.spread
}

