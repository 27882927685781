import * as ActionTypes from './action'
import { getDevice } from '@/utils/core/base'
import { getClientLang } from '@/utils/lang'

// 加载本地缓存
let themeConfig = {
  // 菜单风格
  'navTheme': 'dark',
  // 主题色
  'primaryColor': 'class1',
  // 导航模式 'sidemenu' 'topmenu'
  'layout': 'sidemenu',
  // 内容宽度 'Fixed' 'Fluid'
  'contentWidth': 'Fluid',
  'fixedHeader': true,
  'autoHideHeader': false,
  'fixSiderBar': true,
  'collapse': true,
  // ['desktop', 'tablet', 'phone']
  'device': getDevice(window.innerWidth) || 'desktop',
  // 自定义属性
  'layoutUpdate': 0
}
let langConfig = {
  lang: getClientLang()
}
// 具体字段待确定

export default function config(state = Object.assign({}, themeConfig, langConfig), action) {
  switch(action.type) {
    case ActionTypes.SET_SYSTEM_CONFIG:
      const { tips, url, } = action.data
      const _switch = action.data['switch']
      return Object.assign({}, state, {
        aliChatOn: _switch === 1 ? true : false,
        aliChatUrl: url,
        fuelTips: tips,
      })
    case ActionTypes.SET_LANG:
      return Object.assign({}, state, {
        lang: action.data
      })
    case ActionTypes.SET_THEME:
      return Object.assign({}, state, action.data)
    case ActionTypes.RESET_THEME:
      return Object.assign({}, state, themeConfig)
    case ActionTypes.SET_DEVICE:
      return Object.assign({}, state, {
        device: action.data
      })
    case ActionTypes.UPDATE_LAYOUT:
      return Object.assign({}, state, {
        layoutUpdate: state['layoutUpdate'] + 1
      })
    default:
      return state
  }
}
