import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// import { useIntl } from 'react-intl'

import usePrevious from '@/hooks/usePrevious'

import { drawCanvasCode } from '@/utils/verifyCodeCanvas'

let captchaTimer
let captchaDur = 60000

function CodeBlock({onChange = () => {}}) {
  // captcha
  const [captcha, setCaptcha] = useState(null)
  const [captchaTrigger, setCaptchaTrigger] = useState(null)
  const prevCaptchaTrigger = usePrevious(captchaTrigger)
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */

  useEffect(() => {
    handleCaptchaTrigger()
    captchaTimer = setInterval(function() {
      handleCaptchaTrigger()
    }, captchaDur)
    return () => {
      clearInterval(captchaTimer)
      captchaTimer = null
    }
  }, [])

  useEffect(() => {
    if (captchaTrigger !== prevCaptchaTrigger) {
      const _code = drawCanvasCode('#captchaCanvas', '#captchaImg').join('').toLowerCase()
      setCaptcha(_code)
      // console.log(`code: ${_code}`)
      // console.log(_code)
      onChange && onChange(_code)
    }
  }, [captchaTrigger, prevCaptchaTrigger])
  
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  function handleCaptchaTrigger() {
    setCaptchaTrigger(Math.random())
  }
  function handleCaptchaTriggerByClick() {
    clearInterval(captchaTimer)
    handleCaptchaTrigger()
    captchaTimer = setInterval(function() {
      handleCaptchaTrigger()
    }, captchaDur)
  }
  
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  return (
    <span onClick={handleCaptchaTriggerByClick}>
      <img id="captchaImg" alt="captcha img" style={{width: '50px', height: '20px', display: 'inline-block'}} />
      <canvas id="captchaCanvas" width="50" height="20"></canvas>
    </span>
  )
}

export default CodeBlock

