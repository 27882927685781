import React from 'react'
// import config_local from './config_local'
// import config_mq from './config_mq'
// import config_bh from './config_bh'
import config_iis from './config_iis'
import config_iis_T from './config_iis_T'
import cacheMod from './cacheMod'
import config_MQ from './config_MQ'
import config_xx from './config_xx'
import config_SW from './config_SW'
export default {
  //...config_local,
  //...config_iis,
  //...config_iis_T,
   ...config_MQ,
 // ...config_xx,
 // ...config_SW,
  // ==================================================================
  // ==================================================================
  // ==================================================================
  encryptSalt: 'gTHPvn4Z0lHkV1XHn4Fo',
  appID: 'TfVHav1haMz87LZLKJwPWXBLQT1XgJ',
  appKEY: 'oYN21zJs4aEHQl6cw105',
  version: '3.0',
  //
  simAddSalt: '*z8KgOlT',
  //
  htmlTitle: '三网融合管理系统',
  ...cacheMod,
}


