/**
 * 得出指定数字的精确度
 * @param {*} math 
 */
export function precision(math) {
  let prec
  try {
    prec = math.toString().split(".")[1].length
  } catch(err) {
    prec = 0
  }
  return prec
}

export function add(a, b) {}

export function multiply(a, b) {
  const _prec = Math.pow(10, Math.max(precision(a), precision(b)))
  return (a * _prec) * (b * _prec) / (_prec * _prec)
}