import React, { useState, useEffect } from 'react'
// import { useSelector, } from 'react-redux'
import { WingBlank, WhiteSpace, Icon, Toast, Result, Button,Dialog } from 'antd-mobile'
import { Link, useLocation, useNavigate, } from 'react-router-dom'
import usePrevious from '@/hooks/usePrevious'
// import ModuleLoading from '@/components_base/ModuleLoading'
import Empty from '@/components_base/Empty'
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getQueryBySearch, getSearchByHash, } from '@/utils/core/url'
import { packagesForFuelNew } from '../fn'
import { dcToPackageType, testPay,createFuelOrder,checkPaystate } from '../fn'
import { DownOutline, UpOutline } from 'antd-mobile-icons'
import CheckPayMethod from '@/components_blc/CheckPayMethod'
import SafeInfoCheckBlock from '@/components_base/SafeInfoCheckBlock'
import { getsCardInfo, } from '@/pages/anotherhome/fn'
import IotTipsModal from '@/components_base/IotTipsModal'
import { onBridgeReady } from "@/utils/wechat";
import GLOBAL from '@/config/global';
import ModalForTime from "../ModalForTime";
const showCountFlow = 6

function CheckForFlow({ onChecked = () => { }, }) {
  const navigates = useNavigate()
  //
  const [dataSource, setDataSource] = useState(null)
  //
  const [checked, setChecked] = useState(null)
  const prevChecked = usePrevious(checked)
  //
  const [showMore, setShowMore] = useState(false)
  // 是否同意
  const [agreed, setAgreed] = useState(true)
  const [payMethod, setPayMethod] = useState('wx')
  const [show, setshow] = useState(false)
  // 成功支付提示
  const [successTips, setSuccessTips] = useState(null)
  const [triggerModalTime, setTriggerModalTime] = useState(false);
  const [orderNumber, setorderNumber] = useState(null);
  const _urlSearch = window.location.search && getQueryBySearch(window.location.search)
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash))
  const cardNum = _urlSearch['cardNum'] || _urlSearchHash['cardNum']
  var number = null
  var simid = null
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */

  useEffect(() => {
    if (cardNum) {
      number = getCookie("lastno");
      if (cardNum != number) {
        removeCookie("lastno");
        removeCookie("siminfo")
        removeCookie("simid")
        const _opt = {
          expires: 1,
        };
        saveCookie("lastno", cardNum, _opt);
        const fournumber = cardNum.substr(0, 4);
        const teonumber = cardNum.substr(0, 2);
        let issn = 0;
        if (fournumber == 8986 || teonumber == 14) {
          issn = 0;
        } else {
          issn = 1;
        }
        removeCookie("issn");
        saveCookie("issn", issn, _opt);
        Toast.show('加载中...');
        getsCardInfo(
          { number: cardNum, },
          (response) => {
            console.log(333333333);
            console.log(response);
            setDataSource(response);
            saveCookie("simid", response.id, _opt);
            saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
            Toast.clear()
          },
          () => {
           // console.log(111111);
            navigates('/')
          },
          () => {

          },
        )
      }
    }

    number = getCookie("lastno");
    if (number) {
      //Toast.show('加载中...');
      packagesForFuelNew(
        {
          number: number,
          fueltype: 2,
        },
        (response) => {
          console.log(response)
          const checkeds = dcToPackageType(response['info'])
          setDataSource(checkeds)
          // setSimid(response['info'].id);
          // setshow(true)
          //console.log("2223434343434343")
          // setDataSource([])
        },
        () => {
          setshow(true)
        },
        () => {
          //setshow(true)
        },
      )
    }
    else {
      navigates('/')
    }

  }, [])

  // function onClickPay(){
  //     testPay(
  //       {
  //         number:1
  //       },
  //       (response) => {
  //         console.log(response);
  //         if(response.type == 2){



  //          window.location.href=response.go_model;
  //         }
  //         // setDataSource([])
  //     },
  //       () =>{
  //         Toast.show("微信支付失败，请联系管理员")
  //       },
  //       () => {
  //         Toast.show("微信支付失败，请联系管理员!")
  //       }
  //       // () => { },
  //       // () => { },
  //   )
  //   }


  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  // 关闭购买时长
  function onCloseForModalTime() {
    setTriggerModalTime(false);
  }

  function onClick(e) {
    const _dom = e.currentTarget
    const _id = _dom.getAttribute('data-id')
    const _index = _dom.getAttribute('data-index')
    setChecked(handleChecked(_id))
    // handleChecked(_id, dataSource[_index])
  }
  function handleChecked(idStr) {
    // console.log(idStr);
    // console.log(checkedPkg);
    // console.log(dataSource);
    return dataSource.find((item) => {
      return idStr === item.value;
    });
    // setChecked(checkedPkg)
    // setChecked(idStr)
    // onChecked && onChecked(checkedPkg)
  }
  //
  function onShowMore() {
    setShowMore(!showMore)
  }
  // 协议
  function handleChangeAgree(checked) {
    setAgreed(checked)
    console.log(`协议：${checked}`)
  }
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  function renderPay() {
    return (
      <React.Fragment>
        <br />

        <div className='am-body'>
          <div className="am-list">
            <CheckPayMethod
              defaultChecked={payMethod}
              key={payMethod}
            />
          </div>
          <br />
          <br />
          <SafeInfoCheckBlock initCheck={agreed} onChange={handleChangeAgree} />
          <br />
          <Button type="primary" className="am-button green am-button-primary" style={{ color: '#fff' }} onClick={onClickPay}>确认付款</Button>
        </div>
      </React.Fragment>
    )
  }

  function renderBlock() {
    return (
      <div className="packageCheckForFlow">
        {renderItem()}
      </div>
      // <WingBlank>
      /* <WhiteSpace size="lg" /> */
      // </WingBlank>
    )
  }

  function renderItem() {
    // console.log(dataSource);
    return dataSource.map((item, index) => {

      // const { id, targetname, salepricestr, effectstr, fee, } = item
      const { value, label, price } = item
      let _isActive;
      // const _isActive = checked === `${value}` ? 'active' : ''
      if (checked) {
        _isActive = checked["value"] === value ? 'active' : ''
      }

      //
      if (!showMore && index > showCountFlow - 1) {
        return null
      }

      return (
        <div className={`cardPkgForFlow item ${_isActive}`} key={`voice-${value}`} data-id={`${value}`} data-index={index} onClick={onClick}>
          <div className="name">{label}</div>
          <div className="price">售价：{price}</div>
          <div className="other">当月有效</div>
        </div>
      )
    })
  }

  function renderShowMore() {
    let _rcDom
    if (showMore) {
      _rcDom = '收起更多'
    } else {
      _rcDom = '更多叠加包'
    }
    return (
      <div className="am-remore" style={{ textAlign: 'right', padding: '0.08rem 0', fontSize: '0.24rem' }} onClick={onShowMore}>
        {_rcDom}&nbsp;{showMore ? <UpOutline fontSize={12} /> : <DownOutline fontSize={12} />}
      </div>
    )
  }


 // 点击付款
 function onClickPay() {
  number = getCookie("lastno");
  // console.log(number);
  // console.log(cardNum);
  if(cardNum)
  {
      simid=getCookie("simid");
  }
  else
  {
    simid=getCookie("simid");
  }
  console.log(simid);
  console.log(checked);
  if (!checked) {
    Toast.show("请先选择叠加包");
    return false
  }
  // 判断支付方式
  if (!payMethod) {
    Toast.show("请先选择微信支付");
    return false
  }
  //
  if (!agreed) {
    Toast.show(
      { content:<p>
        请先仔细阅读并同意
        <br />
        《个人信息保护政策》和《单独同意书》
      </p>}
    );
    return false
  }
  // 直接创建预订单
  const { value, price } = checked
  doPay({
    orderTarget: value,
    fee: price,
    cardid: simid,
    number: number
  })
}

// 付款
function doPay(postData) {
  console.log(postData);
  if (payMethod === 'wx') {
    Toast.show('正在创建订单中...')
    createFuelOrder(
      postData,
      (response) => {
        const { type, go_model, preorderno, orderno } = response;
        // Toast.clear();
        // console.log(type);
        // console.log(go_model);
        if (type == 1) {
          console.log("跳转支付");
          doPayForWechat(go_model);
        } else {
          //
          setorderNumber(orderno)
          // setorderNumber(preorderno)
          const _rcDom = (
            <div style={{ fontSize: "0.21rem", color: "#333", padding: "0.15rem 0", textAlign: "left" }}>
              <p>1.如果您已在打开微信支付内支付成功,请点击'已支付'按钮验证是否完成订单. </p>

              <p style={{ paddingTop: "0.1rem" }}>2.如果您未完成支付可点击'取消'按钮,重新发起订单支付.</p>
            </div>
          );

          Dialog.show({
            title: "请确认微信支付是否已完成",
            content: _rcDom,
            closeOnAction: true,
            actions: [
              [
                {
                  key: "cancel",
                  text: "取消",
                },
                {
                  key: "confirm",
                  danger: true,
                  text: "已完成付款",
                  onClick: () => {
                    if (orderno) {
                      checkPaystate(
                        {
                          orderno: orderno,
                        },
                        (response) => {
                          console.log(response);
                          if (response.type == 3) {
                            Toast.show({
                              //icon: "success",
                              content: response.message + ",正在跳转主页...",
                              duration: 3000,
                              afterClose: () => {
                                navigates("/anotherhome");
                              },
                            });
                          } else {
                            Toast.show({
                              // icon: "fail",
                              content: response.message,
                              duration: 3000,
                            });
                          }
                        },
                        () => {},
                        () => {}
                      );
                    }
                  },
                },
              ],
            ],
            //style: styles.dialog,
          });
          window.location.href = response.go_model;
        }
      },
      () => { },
      () => { }
    )
  }

}

// 调起微信支付
function doPayForWechat(postData) {
  onBridgeReady(
    postData,
    () => {
      handleOk()
    },
    () => { },
    () => { },
  )
}


// 支付成功回调
function handleOk() {
  // 2020-12-14 新增成功支付提示-蚁团
  if (GLOBAL['packageFuelSuccessTipsDo'] === 1) {
    Toast.clear()
    const _rcDomContent = (
      <div>
        <p style={{ textAlign: 'justify' }}>充值已完成！请返回首页手动刷新使用量，如剩余流量还是负数，需再次购买叠加包后才可使用。</p>
      </div>
    )
    setSuccessTips({
      title: '温馨提醒',
      content: _rcDomContent,
      footer: [
        {
          text: '确认',
          onPress: () => {
          }
        },
        {
          text: '返回首页',
          onPress: () => {
            navigates.push("/anotherhome");
          }
        }
      ]
    })
  } else {
    //
    Toast.show('支付成功', 2)
  }
  setTriggerModalTime(false)
  setTimeout(() => {
    navigates.push("/anotherhome");
  }, 2000)
}



  let _isRenderMore = false
  if (dataSource && dataSource.length > showCountFlow) {
    _isRenderMore = true
  }

  return (
    <React.Fragment>
      {/* {!dataSource && (
                <div style={{ height: '4.5rem', position: 'relative', }}>
                    <ModuleLoading />
                </div>
            )} */}

      {dataSource && dataSource.length === 0 && (
        <div style={{ height: '4.5rem', position: 'relative', }}>
          <Empty caption="暂无叠加包" />
        </div>
      )}
      {dataSource && dataSource.length > 0 && renderBlock()}

      {_isRenderMore && (
        // <WingBlank>
        renderShowMore()
        // </WingBlank>
      )}

      {dataSource && dataSource.length > 0 && renderPay()}

      {show && (<Result
        status='info'
        title='信息提示'
        description='所选卡无可订购套餐,请联系客服处理!'
      />)}
      {checked && triggerModalTime && <ModalForTime dataSource={checked} onClose={onCloseForModalTime} />}
      <IotTipsModal dataSource={successTips} />

    </React.Fragment>
  )
}

export default CheckForFlow