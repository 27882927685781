import request from '../utils/request'
import apis from '../config/apis/wechat'
import { saveCookie, removeCookie, } from '../utils/storage'
import { parse } from 'querystring';

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

/**
 * 获取微信授权code的链接
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function getWeChatCodeUrl(postData, ok, failed, end) {
  request.post(apis.getWeChatCodeUrl, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 获取微信授权 openid,
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function getWeChatOpenID(postData, ok, failed, end) {
  request.post(apis.getWeChatOpenID, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 支付
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} cancel 
 * @param {*} failed 
 */
export function onBridgeReady(postData, ok, cancel, failed) {
  // console.log(postData)
  window.WeixinJSBridge.invoke(
    'getBrandWCPayRequest', 
    postData,
    // {
    //   "appId":"wx2421b1c4370ec43b",     //公众号名称，由商户传入     
    //   "timeStamp":"1395712654",         //时间戳，自1970年以来的秒数     
    //   "nonceStr":"e61463f8efa94090b1f366cccfbbb444", //随机串     
    //   "package":"prepay_id=u802345jgfjsdfgsdg888",     
    //   "signType":"MD5",         //微信签名方式：     
    //   "paySign":"70EA570631E4BB79628FBCA90534C63FF7FADD89" //微信签名 
    // },
    function(res){
      console.log(res)
      if(res.err_msg == "get_brand_wcpay_request:ok" ){
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        ok && ok()
      } 
      if(res.err_msg == "get_brand_wcpay_request:cancel" ){
        cancel && cancel()
      } 
      if(res.err_msg == "get_brand_wcpay_request:fail" ){
        failed && failed()
      } 
 })
}

