import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Popup ,Image} from "antd-mobile";
import wxgzh1 from '@/assets/images/wxgzh1.jpg'
import wxgzh2 from '@/assets/images/wxgzh2.jpg'
import wxgzh3 from '@/assets/images/wxgzh3.jpg'
import wxgzh4 from '@/assets/images/wxgzh4.jpg'
import wxgzh5 from '@/assets/images/wxgzh5.jpg'
import wxgzh6 from '@/assets/images/wxgzh6.jpg'
function OperatingInstructions({ visible, children, onClose = () => {}, hasBtn = true, btnTxt = "知道了" }) {
 
  // console.log(children);
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */

  //

  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function handleClose() {
    onClose && onClose();
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  const _rcDomBtn = (
    <Button type="primary" onClick={handleClose}>
      {btnTxt}
    </Button>
  );

  return (
    <Popup
      visible={visible}
      // onMaskClick={() => {
      //   setVisible5(false)
      // }}
      showCloseButton
      onClose={() => {
        handleClose();
      }}
      onMaskClick={() => {
        handleClose();
      }}
      bodyStyle={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        minHeight: "83vh",
      }}>
      <div className="am-xinxi-body" style={{ height: "83vh", overflowY: "scroll", padding: "20px" }}> 
      <div className="am-xinxi-context" style={{ fontSize: "18px", fontWeight: "900" }}>微信公众号消息提醒操作指引</div>
      {/* <p style={{ fontSize: "18px", fontWeight: "900" }}></p> */}
      <p style={{color:"red"}}>微信默认开启免打扰模式,请进行如下操作,以防止错过重要提醒!</p>
      <br/>
        <p>①:扫码关注微信公众号或微信搜索"北京铭谦物联"关注</p>
        <br/>
        <p>
          <Image src={wxgzh1}  />
        </p>
        <br/>
        <p>②:点击右上角的图标,进入公众号首页</p>
        <br/>
        <p>
          <Image src={wxgzh2}  />
        </p>
        <br/>
        <p>③:点击右上角的三个点的小图标,会弹出公众号设置菜单</p>
        <br/> 
        <p>
          <Image src={wxgzh3}  />
        </p>
        <br/> 
        <p>④:点击设置</p>
        <br/> 
        <p>
          <Image src={wxgzh4} />
        </p>
        <p>⑤:如下图所示关闭消息免打扰</p>
        <br/> 
        <p>
          <Image src={wxgzh5}  />
        </p>
        <br/> 
        <p>⑥:接收消息通知页面(绑定成功后可在平台可发送测试提醒)</p>
        <br/> 
        <p>
          <Image src={wxgzh6} />
        </p>
        </div>
     
    </Popup> 
  );
}

export default OperatingInstructions;
