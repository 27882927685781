import React from 'react'
import { SpinLoading   } from 'antd-mobile'
// import { Spin } from 'antd'

//import styles from './pullLoading.module.scss'

function PullLoading() {
  return (//className={styles.container}
    <div >
      {/* <Spin size="large" /> */}
      <SpinLoading color='primary'  />
    </div>
  )
}

export default PullLoading
