import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/haoka'
import CONFIG from '@/config/global'


/**
 * 获取城市地区
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
export async function getProvince(params, list) {
  let result = await request.get(apis.getProvince, params, list)
  // console.log(result);
  return result;

}

/**
 * 获取背景图片和验证码
 * @param {*} params
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
 export async function getImg(params) {
  let result = await request.post(apis.getImg, params)
  // console.log(result);
  return result;
}

/**
 * 发送短信验证码
 * @param {*} postData
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
export function sendVailcode(postData, ok, failed, end) {
  request.post(apis.sendVailcode, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
* 电信号卡提交系统订单并发送消息队列
* @param {*} postData
* @param {*} ok
* @param {*} failed
* @param {*} end
*/
export function submitHaokadxOrder(postData, ok, failed, end) {
  request.post(apis.submitHaokadxOrder, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 生成通道选项
 * @param {*} data
 */
export function dataPickerToOptions(data) {
  return data.map(item => {
    const { key, value, children } = item
    // console.log(children);
    return {
      value: key,
      label: value,
      children: children.map(item => {
        const { key, value, children } = item
        return {
          value: key,
          label: value,
          children: children.map(item => {
            const { key, value, children } = item
            return {
              value: key,
              label: value,
              // children: children,
            }
          }),
        }
      }),
    }
  })
}

/**
* 订单查询
* @param {*} postData
* @param {*} ok
* @param {*} failed
* @param {*} end
*/
export function seachHaokadx(postData, ok, failed, end) {
  request.post(apis.seachHaokadx, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

export function fieldsValueToForm(fieldsValue, userid,iscode) {
  const { ordername, phone, address, addressdetail, code, idcard } = fieldsValue
  console.log(address);
  console.log(idcard);
  let _interim = {
    // agentId: agentid,
  }
  _interim['name'] = ordername
  _interim['phone'] = phone
  _interim['idcard'] = idcard
  _interim['userid'] = ((userid && userid != undefined) ? userid : 0)
  //  console.log(batchnumber);
  _interim['provinceCode'] = address[0]
  _interim['cityCode'] = address[1]
  _interim['countyCode'] = address[2]
  _interim['address'] = addressdetail
  iscode && (_interim['code'] = code)
  // console.log(_interim)
  return _interim
}