//
export const USER_LOGIN = 'USER_LOGIN'
export function userLogin(data) {
  return {
    type: USER_LOGIN,
    data: data
  }
}

export const USER_LOGOUT = 'USER_LOGOUT'
export function userLogout() {
  return {
    type: USER_LOGOUT
  }
}

export const SET_USERINFO = 'SET_USERINFO'
export function setUserInfo (data) {
  return {
    type: SET_USERINFO,
    data: data
  }
}
export const CLEAR_USERINFO = 'CLEAR_USERINFO';

export function clearUserInfo() {
  return {
    type: CLEAR_USERINFO
  };
}
