import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/simInfo'
import CONFIG from '@/config/global'

/**
 * = 新卡查询状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function doNumber(postData, ok, failed, end) {
  request.post(apis.doNumber, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 查询卡状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getCardInfo(postData, ok, failed, end) { 
  request.post(apis.getcardinfo, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 查询卡是否存在
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkCardInfo(postData, ok, failed, end) { 
  request.post(apis.checkCardInfo, postData)
    .then(function (response) {
      console.log(response)
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 获取新卡套餐
 * @param {*} params 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function getPkgs(params, ok, failed, end) {
  request.post(apis.getPkgs, params)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 创建预订单
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function createAdvanceOrder(postData, ok, failed, end) {
  request.post(apis.createAdvanceOrder, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 创建预订单
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function createAdvanceOrderNew(postData, ok, failed, end) {
  request.post(apis.createAdvanceOrderNew, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 创建支付订单（余额支付）
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function createAdvanceOrderByBalance(postData, ok, failed, end) {
  request.post(apis.createAdvanceOrderByBalance, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 获取手机短信验证码
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function getPhoneCode(postData, ok, failed, end) {
  request.post(apis.getPhoneCode, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 设置手机号
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function setPhone(postData, ok, failed, end) {
  request.post(apis.setPhone, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 设置密码
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function setPsw(postData, ok, failed, end) {
  //
  postData['password'] = CryptoJS.HmacSHA256(postData['password'], CONFIG['encryptSalt']).toString().toLowerCase()
  
  request.post(apis.setPsw, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 获取新卡套餐(新版)
 * @param {*} params 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getPkgsNew(params, ok, failed, end) {
  request.post(apis.getPkgsNew, params)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}