import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/account'
// import apisOther from '@/config/apis/other'
import { saveCookie, removeCookie, removeCache } from '@/utils/storage'
import CONFIG from '@/config/global'

// import { doByRequestAction } from '@/utils/request.action.js'
import { Toast } from 'antd-mobile'

/**
 * 登录
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
// export function login(postData, ok, failed, end) {
//   //
//   postData['password'] = CryptoJS.HmacSHA256(postData['password'], CONFIG['encryptSalt']).toString().toLowerCase()

//   request.post(apis.login, postData)
//     .then(function (response) {
//       const _isAction = doByRequestAction(response)
//       if (!_isAction) {
//         console.log(response)
//         const { token, iccid, msisdn, phone, id, } = response
//         const userinfo = JSON.stringify({
//           // iccid, msisdn, phone, number: msisdn || iccid, simid: id, loginMethod: 'normal',
//           loginMethod: 'normal',
//         })
//         //
//         saveCookie('authorization', token, { expires: 1 })
//         saveCookie('userinfo', userinfo, { expires: 1 })

//         window.axios.defaults.headers.common['x-token'] = token
//       }
//       ok && ok(response)
//     })
//     .catch(function () {
//       failed && failed()
//     })
//     .finally(function () {
//       end && end()
//     })
// }

export function logout() {
  removeCookie('authorization')
  removeCookie('userinfo')
}

export function logoutone() {
  removeCookie('lastno')
  removeCookie('siminfo')
  removeCookie("simid")
  //removeCache("lastno")
}

/**
 * 获取登录用户信息
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function getUserInfo(ok, failed, end) {
  request.post(apis.getUserInfo)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 获取用户余额
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function getUserBalance(ok, failed, end) {
  request.post(apis.getUserBalance)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 获取系统配置
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
// export function getSystemConfig(ok, failed, end) {
//   request.post(apisOther.getSystemConfig)
//     .then(function (response) {
//       ok && ok(response)
//     })
//     .catch(function () {
//       failed && failed()
//     })
//     .finally(function () {
//       end && end()
//     })
// }

/**
 * 根据状态，拿到对应的状态名，针对微信无法正常保存中文字符
 * @param {*} simState 
 * -1初始化-2卡号错误1待激活2已激活3停机4测试期5库存期6预销户7已销户"
 */
export function getSimStateName(simState) {
  let _simStateName
  switch (simState) {
    case -2:
      _simStateName = '空卡'
      break
    case -1:
      _simStateName = '卡错误'
      break
    case 1:
      _simStateName = '待激活'
      break
    case 2:
      _simStateName = '正常'
      break
    case 3:
      _simStateName = '停机'
      break
    case 4:
      _simStateName = '测试'
      break
    case 5:
      _simStateName = '库存'
      break
    case -1:
      _simStateName = '预销'
      break
    default:
      _simStateName = '已销'
  }
  return _simStateName
}

/**** 忘记密码 ****/
/**
 * 获取验证码，忘记密码
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function findGetCode(postData, ok, failed, end) {
  request.post(apis.findGetCode, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 重置密码，忘记密码
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function findReset(postData, ok, failed, end) {
  //
  postData['password'] = CryptoJS.HmacSHA256(postData['password'], CONFIG['encryptSalt']).toString().toLowerCase()
  postData['confirmpassword'] = CryptoJS.HmacSHA256(postData['confirmpassword'], CONFIG['encryptSalt']).toString().toLowerCase()
  request.post(apis.findReset, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 验证手机，忘记密码
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function findPhoneVerify(postData, ok, failed, end) {
  request.post(apis.findPhoneVerify, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 智能检测
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function userForCheck(postData, ok, failed, end) {
  request.post(apis.userForCheck, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 用户IP检测
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function userIpCheck(postData, ok, failed, end) {
  request.post(apis.userIpCheck, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}