import { createStore, compose } from 'redux'
import { createReducers } from './core'
import DevTools from './DevTools'

let initState = {}

const reducers = createReducers()

const enhancer = compose(
  DevTools.instrument()
)

let store = createStore(reducers, initState, enhancer)

export default store
