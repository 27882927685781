const prefix = '/h5'

export default {
  getsCardInfo:`${prefix}/siminfo/get-cardinfo`,
  checkCardInfo:`${prefix}/siminfo/check-cardinfo`,
  refreshUsageCard:`${prefix}/siminfo/update-flow-use`,
  packagesForFuelNew:`${prefix}/siminfo/get-basicpro-info-new`,
  testPay:`${prefix}/siminfo/create-pay-test`,
  createBasicproOrder:`${prefix}/siminfo/create-basicpro-order-new`,
  createFuelOrder:`${prefix}/siminfo/create-fuel-order`,
  checkPaystate:`${prefix}/siminfo/check-paystate`,
  checkState:`${prefix}/siminfo/check-state`,
  checkRegionlimitstatus:`${prefix}/siminfo/check-regionlimitstatus`,
  checkRestrictedarea:`${prefix}/siminfo/check-restrictedarea`,
  checkSession:`${prefix}/siminfo/check-session`,
  checkClientstate:`${prefix}/siminfo/check-clientstate`,
  getcardinfo: `${prefix}/global/get-cardinfo`,
  doNumber: `${prefix}/active-new/go`,
  getPkgs: `${prefix}/getproduct/get-basicpro-info`,
  getPkgsNew: `${prefix}/getproduct/get-basicpro-info-new`,
  createAdvanceOrder: `${prefix}/getproduct/create-basicpro-order`,
  createAdvanceOrderNew: `${prefix}/getproduct/create-basicpro-order-new`,
  createAdvanceOrderByBalance: `${prefix}/getproduct/balance/create-basicpro-order`,
  getPhoneCode: `${prefix}/setphone/get-code`,
  setPhone: `${prefix}/setphone/config-phone`,
  setPsw: `${prefix}/setpass/config-password`,

}