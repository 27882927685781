import React, { useState, useEffect } from 'react'
import CONFIG from '@/config/global'

/**
 * 自定义当前页面title
 * 销毁恢复原本title？
 */

function HtmlTitle({title}) {
  const { htmlTitle, } = CONFIG
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {
    const _title = title || htmlTitle
    document.title = _title
  }, [])
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  return null
}

export default HtmlTitle
