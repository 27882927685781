import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Modal, Toast } from "antd-mobile";
import picIcon05 from "@/assets/images/icon_005.png";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getsCardInfo } from "@/pages/anotherhome/fn";
import { setUserInfo,clearUserInfo } from "@/redux/user/action";

function HeaderPanel() {
  const dispatch = useDispatch()
  const navigates = useNavigate();
  const [dataSource, setDataSource] = useState(null);
  const [cookies, setCookies] = useState([]);

  const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
  const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];
  //console.log(_urlSearch);
  //console.log(_urlSearchHash);

  var siminfo = getCookie("siminfo");
  const number = getCookie("lastno");
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  useEffect(() => {
    if (siminfo) {
      setDataSource(JSON.parse(siminfo));
    }
    if (!siminfo) {
      // navigates("/");
    }
  }, []);

  useEffect(() => {
    if (cardNum) {
      if (cardNum != number) {
        removeCookie("lastno");
        removeCookie("siminfo");
        const _opt = {
          expires: 1,
        };
        saveCookie("lastno", cardNum, _opt);
        const fournumber = cardNum.substr(0, 4);
        const teonumber = cardNum.substr(0, 2);
        let issn = 0;
        if (fournumber == 8986 || teonumber == 14) {
          issn = 0;
        } else {
          issn = 1;
        }
        removeCookie("issn");
        saveCookie("issn", issn, _opt);
        Toast.show("加载中...");
        getsCardInfo(
          { number: cardNum },
          (response) => {
            console.log(response);
            setDataSource(response);
            saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
            saveCookie("simid", response.id, _opt);
            dispatch(
              clearUserInfo()
            );
            dispatch(
              setUserInfo({
                simids: response.id,
                cardState: response.cardState,
              })
            );
            removeCookie("oper");
            saveCookie("oper", response.oper, _opt);
            removeCookie("number");
            saveCookie("number", response.iccid, _opt);
            // Toast.clear()
            // alert("跳转" + _urlSearch);
            // window.location.href = _urlSearch;
          },
          () => {
            console.log(111111);
            //navigates('/')
          },
          () => {}
        );
      }
    }
  }, []);
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  //

  function onCopyLink(record) {
    //console.log("record:::::::::::::" + JSON.stringify(record))
    const input = document.createElement("input");
    input.value = record;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand("Copy");
    document.body.removeChild(input);
    Toast.show("复制成功！");
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  function renderWalletBlock() {
    // console.log(getCookie("issn"));
    let issn = getCookie("issn");
    let iccidss = 0;
    if (issn == 0) {
      iccidss = 1;
    }
    if (dataSource) {
      const { id, iccid, msisdn, cardStateName, cardState, pkgName, simactivedate, expireTime, oper, snNO } = dataSource;

      let _simStateClass;
      if (cardState === -1 || cardState === -2 || cardState === 1) {
        _simStateClass = "invalid";
      } else if (cardState === 2) {
        _simStateClass = "normal";
      } else if (cardState === 3) {
        _simStateClass = "pastdue";
      } else {
        _simStateClass = "invalid";
      }
      return (
        <section className="headerPanel">
          <div className="headerPanel-base">
            <div className="headerPanel-account">
              {iccidss == 1 && (
                <a onClick={() => onCopyLink(msisdn)} style={{ color: "#fff" }}>
                  {" "}
                  {msisdn}{" "}
                </a>
              )}
              <label className={`headerPanel-status ${_simStateClass}`}>{cardStateName}</label>
            </div>
            <div className="headerPanel-number">
              {iccidss == 1 && (
                <a onClick={() => onCopyLink(iccid)} style={{ color: "#fff" }}>
                  ICCID：{iccid}{" "}
                  <span role="img" aria-label="copy" className="anticon anticon-copy">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="0.21rem" height="0.21rem" fill="currentColor" aria-hidden="true">
                      <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                    </svg>
                  </span>
                </a>
              )}
            </div>
            <div className="headerPanel-number">
              {issn == 1 && (
                <a onClick={() => onCopyLink(snNO)} style={{ color: "#fff" }}>
                  SN：{snNO}{" "}
                  <span role="img" aria-label="copy" className="anticon anticon-copy">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="0.21rem" height="0.21rem" fill="currentColor" aria-hidden="true">
                      <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                    </svg>
                  </span>
                </a>
              )}
            </div>
          </div>
          <div className="headerPanel-extra">
            <div className="btn btn-logout">
              <Link to={`/anotherhome`}>
                <img src={picIcon05} alt="out" />
              </Link>
            </div>
          </div>
        </section>
      );
    }
  }
  return renderWalletBlock();
}

export default HeaderPanel;
