import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/simsms'
import CONFIG from '@/config/global'


  /**
 * 获取短信记录
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getsRecordList(postData, ok, failed, end) {
    request.post(apis.getsRecordList, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }

    /**
 * 发送短信
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function Send(postData, ok, failed, end) {
  request.post(apis.Send, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

export function fieldsValueToForm(fieldsValue) {
  const { number, sendContentType, sendContent, } = fieldsValue
  console.log(sendContentType);
  let _interim = {
    // agentId: agentid,
  }
  _interim['number'] = number
  //  console.log(batchnumber);
  _interim['sendContentType'] = sendContentType[0]
  _interim['sendContent'] = sendContent

  // console.log(_interim)
  return _interim
}