import React, { useState, useEffect } from 'react'
import { WingBlank, WhiteSpace, List, Radio, Toast, Icon, Divider,Checkbox } from 'antd-mobile'

import usePrevious from '@/hooks/usePrevious'
import { ShopbagOutline } from 'antd-mobile-icons'
import { getUserBalance, } from '@/utils/account'


const payMethods = {
  'wx': {
    key: 'wx',
    name: '微信支付',
    iconClass: 'payMethod-icon-wx',
  }
}
const priority = ['wx', 'balance']


function CheckPayMethod({ defaultChecked }) {
  //
  const [payMethod, setPayMethod] = useState(defaultChecked || null)
  const prevPayMethod = usePrevious(payMethod)
  const [balance, setBalance] = useState(null)
  //
  // let supportPayMethod = []
  // if (nonSupport && nonSupport.length > 0) {
  //   priority.map((item) => {
  //     if (nonSupport.indexOf(item) < 0) {
  //       supportPayMethod.push(payMethods[item])
  //     }
  //   })
  // } else {
  //   priority.map((item) => {
  //     supportPayMethod.push(payMethods[item])
  //   })
  // }

  let supportPayMethod = { key: 'wx', name: '微信支付', iconClass: 'payMethod-icon-wx' }
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  // useEffect(() => {
  //   if (!nonSupport || nonSupport.indexOf('balance') < 0) {
  //     getUserBalance(
  //       (response) => {
  //         const { balance } = response
  //         setBalance(balance)
  //         // setBalance(0)
  //       }
  //     )
  //   }
  // }, [])

  // useEffect(() => {
  //   if (payMethod && payMethod !== prevPayMethod) {
  //     onChecked && onChecked(payMethod)
  //   }
  // }, [payMethod, prevPayMethod])
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  // console.log(supportPayMethod);
  function renderPayMethodItem() {

      const { key, name, iconClass, } = supportPayMethod
      let _showTxt = name
      return (
          <Checkbox 
          className="am-list-item"
            key={key}
            id={key}
            onChange={val => {
              console.log(val);
              setPayMethod(val)
            }}
            checked ={payMethod}
          >
            <span key={key} className={`payMethod-icon ${iconClass}`} ></span>
            {_showTxt}
          </Checkbox >
      )
  }


  return (
    <List>
      <div className="payMethodBlock">
        {renderPayMethodItem()}
      </div>
    </List>
  )
}

export default CheckPayMethod
