import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Toast, Dialog, Popover, Modal } from "antd-mobile";
// import HomeHeader from './HomeHeader'
// import FuelBlock from './FuelBlock'
import MenuFastEntry from "@/components_blc/MenuFastEntry";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getsCardInfo, refreshUsageCard } from "./fn";
import { logoutone } from "@/utils/account";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import picIcon02 from "@/assets/images/icon_002.png";
import picIcon03 from "@/assets/images/icon_003.png";
import usePrevious from "@/hooks/usePrevious";
import { RightOutline, CouponOutline } from "antd-mobile-icons";
import { useSelector, useDispatch } from "react-redux";
import { setUserInfo,clearUserInfo } from "@/redux/user/action";
function Anotherhome() {
  const dispatch = useDispatch()
  const navigates = useNavigate();
  const [dataSource, setDataSource] = useState(null);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const prevLoadingUsage = usePrevious(loadingUsage);
  const [visible, setVisible] = useState(true);
  //const [issn, setissn] = useState(0);
  // let issn = false;
  var number = [];
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  const iswhitelist = window.location.href.indexOf("#/whitelist") != -1;
  useEffect(() => {
    // 获取 cardNum
    const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
    const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
    const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];
    // console.log(cardNum);
    // console.log(number)
    if (cardNum && !iswhitelist) {
      number = getCookie("lastno");
      if (cardNum != number) {
        removeCookie("lastno");
        const _opt = {
          expires: 1,
        };
        saveCookie("lastno", cardNum, _opt);
        const fournumber = cardNum.substr(0, 4);
        const teonumber = cardNum.substr(0, 2);
        let issn = 0;
        if (fournumber == 8986 || teonumber == 14) {
          issn = 0;
        } else {
          issn = 1;
        }
        removeCookie("issn");
        saveCookie("issn", issn, _opt);
      }
    }
    number = getCookie("lastno");
    // setissn(getCookie("issn"))
    // issn = getCookie("issn");
    if (number && loadingUsage && !iswhitelist) {
      Toast.show("加载中...");
      console.log("首页");
      getsCardInfo(
        { number },
        (response) => {
          Toast.clear();
          setDataSource(response);
          setLoadingUsage(false);
          const _opt = {
            expires: 1,
          };
          // console.log(response);
          saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
          saveCookie("simid", response.id, _opt);
          dispatch(
            clearUserInfo()
          );
          dispatch(
            setUserInfo({
              simids: response.id,
              cardState: response.cardState,
            })
          );
          removeCookie("oper");
          saveCookie("oper", response.oper, _opt);
          removeCookie("number");
          saveCookie("number", response.iccid, _opt);
          setTimeout(() => {
            setVisible(false);
          }, 3000);
          // var siminfos = getCookie("siminfo");
          // const siminfoss = JSON.parse(siminfos);

          // console.log(siminfoss.oper);
          // console.log(JSON.parse(siminfos));
        },
        () => {
          Toast.show("卡查询失败，返回首页");
          navigates("/");
        },
        () => {}
      );
    } else if (!number && !cardNum && !loadingUsage) {
      // console.log(2222222);
      navigates("/");
    } else if (!number) {
      navigates("/");
    }
  }, [loadingUsage]);

  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function onLogout() {
    const _rcDom = <div style={{ fontSize: "0.32rem", color: "#333", padding: "0.15rem 0" }}>您确定退出登录吗？</div>;
    // Modal.confirm({
    //     content: _rcDom,
    //     onConfirm: () => {
    //         logoutone()
    //         navigates('/')
    //     },
    // })
    Dialog.confirm({
      content: _rcDom,
      onConfirm: async () => {
        logoutone();
        const { origin, pathname, hash, search } = document.location;
        console.log(`${pathname}${hash}`);
        document.location.href = `${pathname}#/`;
        // navigates("/");
      },
    });
  }

  function handleRefresh() {
    const number = getCookie("lastno");
    const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
    const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
    const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];
    // console.log(cardNum);
    Toast.show("刷新中");
    if (cardNum) {
      removeCookie("lastno");
      const number = cardNum;
      refreshUsageCard(
        { number },
        (response) => {
          // Toast.clear()
          setTimeout(() => {
            // Toast.show({
            //     icon: 'success',
            //     content: '成功',
            //   });
            setLoadingUsage(true);
            // console.log(333333333);
          }, 50);
        },
        () => {},
        () => {}
      );
    } else if (number) {
      refreshUsageCard(
        { number },
        (response) => {
          // Toast.clear()
          setTimeout(() => {
            // Toast.show({
            //     icon: 'success',
            //     content: '成功',
            //   });
            setLoadingUsage(true);
            // console.log(333333333);
          }, 50);
        },
        () => {},
        () => {}
      );
    }
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  function onCopyLink(record) {
    //console.log("record:::::::::::::" + JSON.stringify(record))
    const input = document.createElement("input");
    input.value = record;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand("Copy");
    document.body.removeChild(input);
    Toast.show("复制成功！");
    setVisible((v) => false);
  }
  function renderWalletBlock() {
    // console.log(getCookie("issn"));
    let issn = getCookie("issn");
    let iccidss = 0;
    if (issn == 0) {
      iccidss = 1;
    }
    // console.log(issn);
    // console.log(iccidss);
    if (dataSource) {
      const { id, iccid, msisdn, cardStateName, cardState, pkgName, simactivedate, expireTime, oper, imsi, snNO } = dataSource;
      const _thumbClass = ((oper) => {
        if (oper === 1) return "oper_yd";
        if (oper === 2) return "oper_lt";
        if (oper === 3) return "oper_dx";
        return "";
      })(oper);
      //  -1初始化-2卡号错误1待激活2已激活3停机4测试期5库存期6预销户7已销户
      //
      let _simStateClass;
      if (cardState === -1 || cardState === -2 || cardState === 1) {
        _simStateClass = "invalid";
      } else if (cardState === 2) {
        _simStateClass = "normal";
      } else if (cardState === 3) {
        _simStateClass = "pastdue";
      } else {
        _simStateClass = "invalid";
      }
      return (
        <div className="pageHomeHeader">
          <div className="pageHomeHeader-infoPanel">
            <div className={`pageHomeHeader-infoPanel-thumb ${_thumbClass}`}></div>
            <div className="pageHomeHeader-infoPanel-base">
              <div className="pageHomeHeader-infoPanel-account">
                {iccidss == 1 && (
                  <a onClick={() => onCopyLink(msisdn)} style={{ color: "#fff" }}>
                    {" "}
                    <span className="pageHomeHeader-infoPanel-account-show">{msisdn} </span>{" "}
                  </a>
                )}
                <label className={`pageHomeHeader-infoPanel-status ${_simStateClass}`}>{cardStateName}</label>
              </div>
              <div className="pageHomeHeader-infoPanel-number">
                {iccidss == 1 && (
                  <Popover content="点击卡号复制" placement="bottom-end" mode="dark" trigger="click" visible={visible}>
                    <a onClick={() => onCopyLink(iccid)} style={{ color: "#fff" }}>
                      ICCID：{iccid}{" "}
                      <span role="img" aria-label="copy" className="anticon anticon-copy">
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="0.21rem" height="0.21rem" fill="currentColor" aria-hidden="true">
                          <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                        </svg>
                      </span>
                    </a>
                  </Popover>
                )}
              </div>
              <div className="pageHomeHeader-infoPanel-number">
                {issn == 1 && (
                  <a onClick={() => onCopyLink(snNO)} style={{ color: "#fff" }}>
                    SN：{snNO}{" "}
                    <span role="img" aria-label="copy" className="anticon anticon-copy">
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="0.21rem" height="0.21rem" fill="currentColor" aria-hidden="true">
                        <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>
            <div className="pageHomeHeader-infoPanel-extra">
              <div className="btn btn-logout" onClick={onLogout}>
                <img src={picIcon02} alt="out" />
              </div>
            </div>
          </div>

          <div className="pageHomeHeader-pkgBlock">
            <div className="left">
              <div className="name">{pkgName}</div>
              <div className="dateEnd">到期时间：{expireTime}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderFuelBlock() {
    if (dataSource) {
      const { id, amount, totalpackage, usage } = dataSource;
      const _percentFlow = ((usage / amount) * 100).toFixed(1);
      return (
        <div className="pageHome-fuelBlock">
          <div className="pageHome-fuelContainer">
            <div className="pageHome-fuelCard pageHome-fuelCard-flow">
              <div className="pageHome-fuelCard-refresh" onClick={handleRefresh}>
                刷新
              </div>
              <div className="pageHome-fuelCard-icon">
                <img src={picIcon03} alt="flow" />
              </div>
              <div className="pageHome-fuelCard-total">总量{totalpackage}MB</div>
              <div className="pageHome-fuelCard-progess">
                <div className="pageHome-fuelCard-progess-bar" style={{ width: `${_percentFlow}%` }}></div>
              </div>
              <div className="pageHome-fuelCard-detail">
                <div className="left">
                  <div className="name">已使用量</div>
                  <div className="count">{usage}MB</div>
                </div>
                <div className="right">
                  <div className="name">剩余量</div>
                  <div className="count">{amount}MB</div>
                </div>
              </div>
              {/* <div className="pageHome-fuelCard-goto" onClick={() => navigates('/home')}>叠加包充值<RightOutline fontSize={12} /></div> */}
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <section className={`layoutPage pageHome`}>
        <div className="layoutPage-header"></div>
        <div className="layoutPage-body">
          {/* <HomeHeader /> */}
          {renderWalletBlock()}
          <div className="pageHome-body">
            {/* <FuelBlock /> */}
            {renderFuelBlock()}

            {dataSource && <MenuFastEntry />}
          </div>
        </div>
        <div className="layoutPage-footer"></div>
      </section>
      {/* <UxBlock /> */}
    </React.Fragment>
  );
}

export default Anotherhome;
