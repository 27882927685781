import React from "react";
import { Router, Route, Redirect, Routes } from "react-router-dom";
// import { useSelector } from 'react-redux'
// Suspense

import HelpMC from "@/pages/search";
import HelpHome from "@/pages/home";
import HelpAnotherHome from "@/pages/anotherhome";
import HelpPackageFuel from "@/pages/packageFuelDo";
import HelpPackage from "@/pages/package";
import HelpIntelDiagnosis from "@/pages/IntelDiagnosis";
import HelpSmsRecord from "@/pages/smsRecord";
import HelpDoSend from "@/pages/smsDoSend";
import HelpAuthBind from "@/pages/authbind";
import HelpOrderFor from "@/pages/orderfor";
import HelpOrderForPrice from "@/pages/orderforprice";
import HelpOrderForSeach from "@/pages/orderforseach";
import HelpProblemFeedback from "@/pages/ProblemFeedback";
import WhiteList from "@/pages/WhiteList";
import WhiteAdd from "@/pages/WhiteList/WhiteAdd";


import JumpCTCC from "@/pages/Other/Jump";
// import JumpCMCC from "@/pages/Other/CmccJump";

import Exception from "@/pages/Exception";
// NoOnline
import NoOnlineRountes from "../NoOnlineRountes";
// Private
// import PrivateRoute from "../PrivateRoute";

// const GuideHtml = React.lazy(() => import('@/pages/GuideHtml'))

function Auth({ children }) {
  // const { online } = useSelector(state => state.user)

  // console.log(window.wx)
  // window.wx.config({
  //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //   appId: '', // 必填，公众号的唯一标识
  //   timestamp: '', // 必填，生成签名的时间戳
  //   nonceStr: '', // 必填，生成签名的随机串
  //   signature: '',// 必填，签名
  //   jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
  // });

  return (
    <React.Fragment>
      <Routes>
        {/* <Route path={`/guide-html`} exact>
          <GuideHtml />
        </Route> */}
        <Route path={`/`} element={<HelpMC />}>
          {/* <Route path={`/`}  element={<HelpHome />}> */}

          {/* <HelpMC modpath={`/search`} exact /> */}
        </Route>
        <Route path={`/home`} element={<HelpHome />}></Route>
        <Route path={`/anotherhome`} element={<HelpAnotherHome />}></Route>
        <Route path={`/packagefuel`} element={<HelpPackageFuel />}></Route>
        <Route path={`/package`} element={<HelpPackage />}></Route>
        <Route path={`/intelDiagnosis`} element={<HelpIntelDiagnosis />}></Route>
        <Route path={`/smsrecord`} element={<HelpSmsRecord />}></Route>
        <Route path={`/dosend`} element={<HelpDoSend />}></Route>
        <Route path={`/authbind`} element={<HelpAuthBind />}></Route>
        <Route path={`/orderfor`} element={<HelpOrderFor />}></Route>
        <Route path={`/orderforprice`} element={<HelpOrderForPrice />}></Route>
        <Route path={`/orderforseach`} element={<HelpOrderForSeach />}></Route>
        <Route path={`/problemfeedback`} element={<HelpProblemFeedback />}></Route>
        <Route path={`/jump`} element={<JumpCTCC />}></Route>
        <Route path={`/whitelist`} element={<WhiteList />}></Route>
        <Route path={`/WhiteAdd`} element={<WhiteAdd />}></Route>

        {/* <Route path={`/cmccjump`} element={<JumpCMCC />}></Route> */}
        {/* <Route path="/" exact component={HelpMC}></Route> */}
        {/* <Route path={`*`}>
          <Exception />
        </Route> */}
        <Route path="/search" exact component={<NoOnlineRountes />}></Route>
      </Routes>
    </React.Fragment>
  );
}

export default Auth;
