import request from '@/utils/request'
import apis from '@/config/apis/jump'


//移动跳转实名
export function getYiDongJumpUrl(postData,ok, failed, end) {
    request.post(apis.getYiDongJumpUrl,postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }
  export function getDianXinJumpTokenUrl(ok, failed, end) {
    request.post(apis.getDianXinJumpTokenUrl)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }