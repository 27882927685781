import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DeleteOutline } from "antd-mobile-icons";
import { WingBlank, WhiteSpace, Icon, Button, Toast, Modal, CheckList,Dialog } from "antd-mobile";

import usePrevious from "@/hooks/usePrevious";
import HeaderPanel from "@/components_blc/HeaderPanel";
// import ModuleLoading from '@/components_base/ModuleLoading'
import { SpinLoading } from "antd-mobile";
import Empty from "@/components_base/Empty";

import { setUserInfo } from "@/redux/user/action";
import { getWhiteList, delWhiteNumber, hasWhiteListByNumber } from "../fn";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { getsCardInfo } from "../fn";
import store from "@/redux/store";
import Alert from '@/components_base/Alert'
const alert = Modal.alert;

function WhiteListIndex() {
  const dispatch = useDispatch();
  // const history = useNavigate()
  const navigates = useNavigate();
  // const { simHasWhite } = useSelector((state) => state.user);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [checkvalue, setCheckValue] = useState([]);
  const [disable, setDisable] = useState(false);
  const { simids } = useSelector((state) => state.user);
  const [simidnumber, setsimidnumber] = useState(simids);
  // const simid = getCookie("simid");
  const [hasWhite, setHasWhite] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [checked, setChecked] = useState([]);
  //
  const [triggerRefresh, setTriggerRefresh] = useState(null);
  const prevTriggerRefresh = usePrevious(triggerRefresh);
  const newdata = [...checkvalue];
  const allValues = store.getState();
  useEffect(() => {
    console.log(allValues);
    // setTriggerRefresh(Math.random());
    // if (hasWhite !== null && hasWhite !== undefined) {
    //   if (!hasWhite) {
    //     doUnAccord();
    //   } else {
    //     // setHasWhite(hasWhite);
    //     setTriggerRefresh(Math.random());
    //   }
    // } else {
    // 先校验当前卡是否拥有亲情号码，再请求数据
    //  Toast.info('验证中...')
    if (simidnumber) {
      hasWhiteListByNumber(
        { id: simidnumber },
        (response) => {
          const { type } = response;
          if (type === 1) {
            console.log(type);
            //Toast.hide()
            setTimeout(() => {
              setHasWhite(true);
              // console.log(Math.random())
            }, 50);
          } else {
            doUnAccord();
          }
          //
          // dispatch(
          //   setUserInfo({
          //     simHasWhite: type === 1 ? true : false,
          //   })
          // );
        },
        () => { },
        () => { }
      );
    } else {
      setsimidnumber(getCookie("simid"));
      console.log(getCookie("simid"))
    }
    //}
  }, [simidnumber]);
  useEffect(() => {
    if (simidnumber && hasWhite) {
      getWhiteList(
        {
          id: simidnumber,
        },
        (response) => {
          // console.log("huoquda" + response);
          setDataSource(response);
        },
        () => { },
        () => { }
      );
    }
  }, [simidnumber, hasWhite]);
  useEffect(() => {
    // console.log(checked)
  }, [checked]);
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  // 验证不通过
  function doUnAccord() {
    Toast.info("无亲情号码权限", 2);
    setTimeout(() => {
      navigates.replace("/my/home");
    }, 1750);
  }
  //
  function onChecked(e) {
    const _dom = e.currentTarget;
    const _id = _dom.getAttribute("data-id") * 1;
    // const _index = _dom.getAttribute('data-index')
    const _tempChecked = [...checked];
    const _hasIndex = _tempChecked.indexOf(_id);
    if (_hasIndex >= 0) {
      _tempChecked.splice(_hasIndex, 1);
    } else {
      _tempChecked.push(_id);
    }
    setChecked(_tempChecked);
  }
  //
  function onDelete() {
    if (checkvalue.length === 0) {
      Toast.info("请选择要删除的号码", 2);
      return false;
    }
    Dialog.confirm({
      content:"确定删除选中的号码吗？",
      closeOnMaskClick: true,
      confirmText: "确定",
      cancelText: "取消",
      onConfirm: () => {       
        Toast.show("删除中...");
        delWhiteNumber(
          {
            numberid: simidnumber,
            numberList: checkvalue,
          },
          (response) => {
            Toast.show(response.message);
            Toast.clear();
          },
          () => { },
          () => { }
        );
        },
      onCancel: () => {
        Toast.clear();
      }
    })
  }

  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  return (
    <section className={`layoutPage pageWhiteList`}>
      <div className="layoutPage-body">
        <div style={{ padding: '0.38rem 0.38rem' }}>
          <Alert message="注意事项" type="warning">
            <p>每个设备最多只能添加5个语音亲情号码；</p>
            <p>每个设备每个自然月只能删除一次亲情号码，每次最多可删除2个亲情号码;</p>
            <p>选中卡会出现删除按钮</p>
          </Alert>
        </div>
        {!dataSource && <SpinLoading color="primary" />}
        {dataSource && dataSource.length === 0 && <Empty caption="暂无亲情号码" />}
        {dataSource && dataSource.length !== 0 && <div className="whiteList">
          <CheckList multiple onChange={val => {
            if(val && val.length>=2)
            {
              Toast.show("最多只能选择两个");
            }
            setCheckValue(val);
          }}>
            {dataSource.map((item, index) => {
              const { id, family, remark, state, status } = item;
              const _status = status === 1 ? "check-ok" : "check-wait";
              let _checkedClass;
              if (checked.indexOf(id) >= 0) {
                _checkedClass = "checked";
              } else {
                _checkedClass = "";
              }
              return(
                <CheckList.Item key={id} value={id} disabled={checkvalue&& checkvalue.length>=2 && id!=checkvalue[0] && id!=checkvalue[1]}>
                  <div className="whiteListItem" >
                    <div className="whiteListItem-content">
                      <div className="whiteListItem-name">
                        <span className="whiteListItem-icon user"></span>&nbsp;{remark}
                      </div>
                      <div className="whiteListItem-number">{family}</div>
                    </div>
                    <div className="whiteListItem-status">
                      <span className={`whiteListItem-icon ${_status}`}></span>&nbsp;{state}
                    </div>
                  </div>
                </CheckList.Item>
              )
            })}
          </CheckList>
          {checkvalue&& checkvalue.length>=1 &&<Button color='danger' className="am-button am-button-primary" style={{ '--background-color': '#FF0000', color: '#fff' }} onClick={onDelete} >删除选中项</Button>}
        </div>}
      </div>
    </section>
  );
}

export default WhiteListIndex;
