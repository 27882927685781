import request from '@/utils/request'
import apis from '@/config/apis/whitelist'

/**
 * 添加新的亲情号码
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function addWhiteNumber(postData, ok, failed, end) {
  return request.post(apis.addWhiteNumber, postData)
      .then(function (response) {
        ok && ok(response)
        console.log(response);
        return response
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }