import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import { WingBlank, WhiteSpace, Icon, Button, List, Input, TextArea, Toast, Modal, Picker, Form } from 'antd-mobile'
import { createForm } from 'rc-form'

import usePrevious from '@/hooks/usePrevious'
import CodeBlock from './CodeBlock'
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { Send, fieldsValueToForm } from './fn'
import HeaderPanel from '@/components_blc//HeaderPanel'
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getsCardInfo, } from '@/pages/anotherhome/fn'


const alert = Modal.alert

const sendTypes = [
  [
    { value: 0, label: 'ASCII串', },
    { value: 3, label: '短信写卡操作', },
    { value: 4, label: '二进制信息', },
    { value: 8, label: 'UCS2编码', },
    { value: 9, label: '含GB中文', },
  ]
]

function DoSend(props) {
  const navigates = useNavigate()
  // const { number, simid, } = useSelector(state => state.user)
  //
  const { getFieldProps, validateFields, getFieldError } = props.form
  //
  const [code, setCode] = useState(null)
  const [formInstance] = Form.useForm()
  // 表单提交
  const [formData, setFormData] = useState(null)
  const prevFormData = usePrevious(formData)
  const [loading, setLoading] = useState(false)
  const [pickerVisible, setPickerVisible] = useState(null)
  const _urlSearch = window.location.search && getQueryBySearch(window.location.search)
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash))
  const cardNum = _urlSearch['cardNum'] || _urlSearchHash['cardNum']
  var siminfo = getCookie("siminfo");
  const [number, setNumber] = useState(null)
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
   useEffect(() => {
    if(siminfo){
      setNumber(getCookie("lastno"))
    }
    if (!siminfo) {
      navigates('/')
    }

  }, [])

  useEffect(() => {
    // setNumber(getCookie("lastno"))
    if (cardNum) {
      if (cardNum != number) {
        removeCookie("lastno");
        removeCookie("siminfo")
        const _opt = {
          expires: 1,
        };
        saveCookie("lastno", cardNum, _opt);
        const fournumber = cardNum.substr(0, 4);
        const teonumber = cardNum.substr(0, 2);
        let issn = 0;
        if (fournumber == 8986 || teonumber == 14) {
          issn = 0;
        } else {
          issn = 1;
        }
        removeCookie("issn");
        saveCookie("issn", issn, _opt);
        Toast.show('加载中...');
        getsCardInfo(
          { number:cardNum, },
          (response) => {
            // console.log(response);
            setNumber(response.iccid)
            saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
            Toast.clear()
          },
          () => {
            console.log(111111);
            navigates('/')
          },
          () => {

          },
        )
      }
    }

  }, [])


  useEffect(() => {
    if (loading && formData && formData !== prevFormData) {
      console.log(formData);
      const { number, sendContent, sendContentType } = formData
      if (!number) {
        Toast.show("物联卡号/ICCID不能为空");
      }
      if (sendContent == null) {
        Toast.show("请输入发送内容");
      }
      if (!sendContentType) {
        Toast.show("请选择发送类型");
      }

      Send(
        formData,
        (response) => {
          Toast.show('发送成功', 2)
        },
        () => {
        },
        () => {
          setLoading(false)
        }
      )
    }
  }, [loading, formData, prevFormData])

  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  //
  function handleCodeChange(code) {
    setCode(code)
  }
  function onFinish(values) {
    console.log('value' + JSON.stringify(values));
    const _formData = fieldsValueToForm(values)

    setLoading(true)
    setFormData(_formData)
  }

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  // console.log(sendTypes);
  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header">
        <HeaderPanel />
      </div>
      <div className="layoutPage-body">

        <section className="imitWxForm">
          <br />
          {number && <Form
            layout='horizontal'
            form={formInstance}
            onFinish={onFinish}
            initialValues={{ number: number }}
            footer={
              <Button type="primary" style={{ '--background-color': '#108ee9', color: '#fff' }} loading={loading}>发送</Button>
            }
          >
            <Form.Item label='发送号码' name="number"
              rules={
                [
                  {
                    required: true,
                    message: '请输入MSISDN/ICCID'
                  }
                ]
              }
            >
              <Input
                placeholder="请输入MSISDN/ICCID">
              </Input>
            </Form.Item>
            <Form.Item
              label="发送类型" name="sendContentType"
              rules={[{
                required: true,
                message: '请选择发送类型'
              }]}
              trigger='onConfirm'
              onClick={() => {
                setPickerVisible(true)
              }}
            >
              <Picker
                columns={sendTypes}
                visible={pickerVisible}
                onClose={() => {
                  setPickerVisible(false)
                }}
              >
                {value =>
                  value[0] == null ? "请选择发送类型" : value[0].label
                }
              </Picker>
            </Form.Item>

            <Form.Item label="发送内容" name="sendContent"
              rules={
                [
                  {
                    required: true,
                    message: '请输入要发送的内容'
                  },
                  {
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.resolve()
                      }
                      if (value.length <= 140) {
                        return Promise.resolve()
                      }
                      return Promise.reject('短信内容不得超过140个字符')
                    }
                  }
                ]
              }
            >
              <TextArea
                title={'发送内容'}
                placeholder={'请输入要发送的内容'}
                rows={5}
              />
            </Form.Item>

            <Form.Item label='验证码' name="captcha"
              rules={[{
                required: true,
                message: '请输入验证码'
              },
              {
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.resolve()
                  }
                  if (value.toLowerCase() === code) {
                    return Promise.resolve()
                  }
                  return Promise.reject('验证码错误')
                }
              }]}
              extra={
                <CodeBlock onChange={handleCodeChange} />
              }
            >
              <Input placeholder='请输入验证码' clearable />
            </Form.Item>
          </Form>}


        </section>

      </div>
      <div className="layoutPage-footer" style={{ padding: '25px 0' }}>
        {/* <WingBlank size="lg"> */}
        {/* <Button type="primary" loading={loading}>发送</Button> */}
        {/* </WingBlank> */}
      </div>
    </section>
  )
}

export default createForm()(DoSend)