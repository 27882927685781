import React, { useState, useEffect } from 'react'

import usePrevious from '@/hooks/usePrevious'

function Alert({message, type, children, size }) {
  //
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  
  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  const _sizeClass = size ? `size-${size}` : 'size-normal'

  return (
    <section className={`alertBlock ${type} ${_sizeClass}`}>
      {
        message && (
          <div className="alertBlock-message">{message}</div>
        )
      }
      {
        children && (
          <div className="alertBlock-desc">{children}</div>
        )
      }
    </section>
  )
}

export default Alert
