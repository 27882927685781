import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { WingBlank, WhiteSpace, Button, List, InputItem, Icon, Toast, } from 'antd-mobile'
import './style.scss'
import HomeHeader from './HomeHeader'
import FuelBlock from './FuelBlock'
import { getsCardInfo } from "./fn";
import { getCookie, saveCookie } from "@/utils/storage";


function Home() {

    const [dataSource, setDataSource] = useState(null)
    //
    /** 
     * *****************************************************************
     * *****************************************************************
     * 订阅
     * *****************************************************************
     * *****************************************************************
     */

    // useEffect(() => {
    //     Toast.show('加载中...');
    //     const number =getCookie("lastno");
    //     getsCardInfo(
    //         { number, },
    //         (response) => {
    //             console.log(response);
    //             Toast.clear()
    //             setDataSource(response)
    //         },
    //         () => { },
    //         () => { },
    //     )
    // }, [])

    return (
        <React.Fragment>
            <section className={`layoutPage pageHome`}>
                <div className="layoutPage-header"></div>
                <div className="layoutPage-body">
                    <HomeHeader />
                    <div className="pageHome-body">
                        <div className='divHeight '></div>
                        <FuelBlock />
                        <div className='divHeight '></div>
                    </div>
                </div>
                <div className="layoutPage-footer"></div>
            </section>
        </React.Fragment>
    )

}

export default Home