import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import { WingBlank, WhiteSpace, Icon, Button, List, Input, TextArea, Toast, Modal, Picker, Form,Dialog} from 'antd-mobile'
import { createForm } from 'rc-form'

import usePrevious from '@/hooks/usePrevious'
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";
import { submitFeedback, fieldsValueToForm } from './fn'
import HeaderPanel from '@/components_blc//HeaderPanel'
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getsCardInfo, } from '@/pages/anotherhome/fn'


const alert = Modal.alert

function ProblemFeedback(props) {
    const navigates = useNavigate()
    // const { number, simid, } = useSelector(state => state.user)
    //
    const { getFieldProps, validateFields, getFieldError } = props.form

    const [formInstance] = Form.useForm()
    // 表单提交
    const [formData, setFormData] = useState(null)
    const prevFormData = usePrevious(formData)
    const [loading, setLoading] = useState(false)
    const _urlSearch = window.location.search && getQueryBySearch(window.location.search)
    const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash))
    const cardNum = _urlSearch['cardNum'] || _urlSearchHash['cardNum']
    var siminfo = getCookie("siminfo");
    const [number, setNumber] = useState(null)
    /** 
     * *****************************************************************
     * *****************************************************************
     * 订阅
     * *****************************************************************
     * *****************************************************************
     */
    useEffect(() => {
        if (siminfo) {
            setNumber(getCookie("lastno"))
        }
        if (!siminfo) {
            navigates('/')
        }

    }, [])

    useEffect(() => {
        // setNumber(getCookie("lastno"))
        if (cardNum) {
            if (cardNum != number) {
                removeCookie("lastno");
                removeCookie("siminfo")
                const _opt = {
                    expires: 1,
                };
                saveCookie("lastno", cardNum, _opt);
                const fournumber = cardNum.substr(0, 4);
                const teonumber = cardNum.substr(0, 2);
                let issn = 0;
                if (fournumber == 8986 || teonumber == 14) {
                  issn = 0;
                } else {
                  issn = 1;
                }
                removeCookie("issn");
                saveCookie("issn", issn, _opt);
                Toast.show('加载中...');
                getsCardInfo(
                    { number: cardNum, },
                    (response) => {
                        // console.log(response);
                        setNumber(response.iccid)
                        saveCookie("siminfo", JSON.stringify(Object.assign({}, response)), _opt);
                        Toast.clear()
                    },
                    () => {
                        console.log(111111);
                        navigates('/')
                    },
                    () => {

                    },
                )
            }
        }

    }, [])


    useEffect(() => {
        if (loading && formData && formData !== prevFormData) {
            // console.log(formData);
            const { iccid, content, phone } = formData
            if (!iccid) {
                Toast.show("物联卡号/ICCID不能为空");
            }
            if (content == null) {
                Toast.show("请输入发送内容");
            }
            if (!phone) {
                Toast.show("手机号不能为空");
            }

              submitFeedback(
                formData,
                (response) => {
                    Dialog.alert({
                        content: "提交成功",
                        closeOnMaskClick: true,
                        confirmText: "返回首页",
                        onConfirm: () => {
                            navigates('/anotherhome')
                        },
                      });
                },
                () => {
                },
                () => {
                  setLoading(false)
                }
              )
        }
    }, [loading, formData, prevFormData])

    /** 
     * *****************************************************************
     * *****************************************************************
     * 回调
     * *****************************************************************
     * *****************************************************************
     */
    //
    function onFinish(values) {
        console.log('value' + JSON.stringify(values));
        const _formData = fieldsValueToForm(values, number)
        setLoading(true)
        setFormData(_formData)
    }

    /** 
     * *****************************************************************
     * *****************************************************************
     * 渲染
     * *****************************************************************
     * *****************************************************************
     */
    return (
        <section className={`layoutPage`}>
            <div className="layoutPage-header">
                <HeaderPanel />
            </div>
            <div className="layoutPage-body">

                <section className="imitWxForm">
                    <br />
                    {number && <Form
                        layout='horizontal'
                        form={formInstance}
                        onFinish={onFinish}
                        initialValues={{ number: number }}
                        footer={
                            <Button type="primary" style={{ '--background-color': '#108ee9', color: '#fff' }} loading={loading}>提交</Button>
                        }
                    >
                        <Form.Item label="反馈问题" name="content"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: '请输入要反馈的内容'
                                    },
                                    {
                                        validator: (rule, value) => {
                                            if (!value) {
                                                return Promise.resolve()
                                            }
                                            if (value.length <= 300) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject('短信内容不得超过300个字符')
                                        }
                                    }
                                ]
                            }
                        >
                            <TextArea
                                title={'反馈问题'}
                                placeholder={'请输入要反馈的内容'}
                                rows={5}
                                showCount
                                maxLength={300}
                            />
                        </Form.Item>

                        <Form.Item label='手机号' name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: "请填写联系电话",
                                },
                                {
                                    validator: (rule, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        if (value.length == 11) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject("联系电话不得少于11位");
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入手机号">
                            </Input>
                        </Form.Item>

                    </Form>
                    }


                </section>

            </div>
            <div className="layoutPage-footer" style={{ padding: '25px 0' }}>
            </div>
        </section>
    )
}

export default createForm()(ProblemFeedback)