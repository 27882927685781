import global from '@/config/global'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
// import { message } from 'antd'
/**
 * 待定
 * @param {Number} width 
 */
export function getDevice(width) {
  const isPhone = typeof navigator !== 'undefined' && navigator && navigator.userAgent.match(/phone/gi)

  if (width < 680 || isPhone) {
    return 'phone'
  }
  if (width < 1280 && width > 680) {
    return 'tablet'
  }
  return 'desktop'
}

/**
 * 签名生成器
 * @param {*} obj 
 * @param {*} appKEY 
 */
export function signBuilder(obj, appKEY) {
  let str = ''
  if (JSON.stringify(obj) !== '{}') {
    let keyValue = []
    for (var i in obj) {
      keyValue.push({
        key: i,
        value: obj[i]
      })
    }

    keyValue.sort(function(a, b) {
      return (a.key).localeCompare(b.key)
    })
    for (var i in keyValue) {
      str += `${keyValue[i].key}${keyValue[i].value}`
    }
  }
  str = `${appKEY}${str}${appKEY}`
  const _hashSign = CryptoJS.MD5(str)
  return CryptoJS.enc.Hex.stringify(_hashSign)
}
/**
 * 返回，为请求提供附加的信息
 */
export function requestInjector() {
  const { appID, version } = global
  const _nowTimestamp = new Date().getTime()
  return {
    v: version,
    appId: appID,
    transid: dayjs(_nowTimestamp).format('YYYYMMDDHHmmssSSS'),
    timestamp: _nowTimestamp
  }
}
/**
 * 格式化，文本过滤，限制显示字数，省略号
 * @param {*} text 
 */
export function formatRichText(text, len, mark) {
  // let content = decodeURIComponent(text)
  let content
  try {
    content = decodeURIComponent(text)
  } catch(e) {
    content = text
  }
  let _mark = mark || '...'
  content = content.replace(/<.+?>/g, '')
  content = content.replace(/&nbsp;/ig, '')
  content = content.replace(/\s/ig, '')
  content = content.length > len ? `${content.slice(0, len)}${_mark}` : content
  return content
}
/**
 * 复制指定dom(input)中的文本
 * @param {*} dom 
 */
export function copyFromDom(dom) {
  if (dom.value) {
    dom.select()
    document.execCommand('Copy')
    // message.success('复制成功', 1.2)
  }
}
/**
 * 处理textarea显示文本换行问题
 * @param {*} values 
 */
export function textareaForLinefeed(value) {
  if (typeof value === 'string') {
    let _str = value.replace(/(<br \/>|<br>)/g, '\n').replace(/\&nbsp\;/g, ' ')
    return _str
  } else {
    return ''
  }
}


/**
 * 过滤所有空白符
 * @param {*} string 
 */
export function filterOutBlankChar(string) {
  const reg = new RegExp(' ', 'g')
  return string.replace(reg, '')
}

/**
 * 返回隐秘手机号
 * @param {*} phone 
 */
export function phoneSecret(phone) {
  const reg = /^(\d{3})\d{4}(\d{4})$/
  return phone.replace(reg, '$1****$2')
}