import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/simInfo'
import CONFIG from '@/config/global'

/**
 * = 新卡查询状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
export function doNumber(postData, ok, failed, end) {
  request.post(apis.doNumber, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 查询卡状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getCardInfo(postData, ok, failed, end) { 
  request.post(apis.getcardinfo, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 查询卡是否存在
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkCardInfo(postData, ok, failed, end) { 
  request.post(apis.checkCardInfo, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 查询卡状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getsCardInfo(postData, ok, failed, end) { 
    request.post(apis.getsCardInfo, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }