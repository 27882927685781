import React, { useState, useEffect } from "react";
import { AutoCenter } from 'antd-mobile'
import CONFIG from "@/config/global";
import { Toast } from "antd-mobile";
import PullLoading from "@/components_base/PullLoading";
import { getDianXinJumpTokenUrl } from "./fn.js";

function Jump() {

  useEffect(() => {
    getDianXinJumpTokenUrl((response) => {
      const { code, token } = response;
      setTimeout(() => {
        if (code === 1 && token) {
          //console.log(token);
          window.location.href = `${CONFIG["telemrealname"]}${token}`;
          //window.open(`${CONFIG["telemrealname"]}${token}`);
        } else {
          Toast.show("跳转失败,电信服务器未响应,请稍后刷新页面重试" + token, 0);
          console.log(token);
        }
      }, 3000);
    });
  }, []);

  return (
    <React.Fragment>
      <div style={{ marginTop: "30%", textAlign: "center" }}>
        {" "}
        <span>正在跳转,如果显示"请输入用户名和密码"请返回重新进入</span>
        <AutoCenter style={{ marginTop: "10%"}}>
          {" "}
          <PullLoading />
        </AutoCenter>
      </div>
    </React.Fragment>
  );
}
export default Jump;
