import { useRef, useEffect } from 'react'
// 记录当前帧，并返回上一帧的值
export default function usePrevious(val) {
  // 这里直接丢给 Ref ，设置第一帧的值
  const ref = useRef(val)
  useEffect(() => {
    ref.current = val
  })
  return ref.current
}
