import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { WingBlank, WhiteSpace, Modal, Icon,Popup } from "antd-mobile";
import { CloseOutline, ArrowDownCircleOutline } from 'antd-mobile-icons'

function AliChat({ onClose = () => {}, ...props }) {
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function handleClose() {
    onClose && onClose();
  }
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

function context(){
return(
  <section className={`layoutPage`}>
  <div className="layoutPage-header">
    <div className="modalPopup-header">
      {/* <WingBlank> */}
        <div className="am-prop-header">
          <div className="modalPopup-header-title">订购规则</div>
          {/* <div className="modalPopup-header-close" onClick={handleClose}>
          <CloseOutline />                
          </div> */}
        </div>
      {/* </WingBlank> */}
    </div>
  </div>
  <div className="layoutPage-body">
    <div
      // className="am-wingblank am-wingblank-lg"
      className="am-prop"
      style={{ textAlign: "left", color: "#333" }}
    >
      <br />
      <p>
        <span>
          1.所有套餐订购周期都是基础周期*n,如月套餐:1*n,季套餐:3*n,半年包:6*n,年包:12*n(个月).
        </span>
      </p>
      <br />
      <p>
        <span>
          2.订购周期:所选套餐的基础上*订购周期,如XXX月套餐*1,最终的订购周期就是1*订购周期数!如有疑问请联系客户经理帮您解答.
        </span>
      </p>
      <br />
      <p>
        <span>3.续费订购:已生效套餐到期后生效当前订购套餐.</span>
      </p>
      <br />
      <p>
        <span>4.立即生效:将作废其他已订购套餐和叠加包.</span>
      </p>
      {/* <h1 className='title' style={{fontSize:'14pt',lineHeight:'150%', margin:'17pt 0pt 16.5pt',borderBottom: '1px solid #ddd;'}}><span style={{fontFamily:'微软雅黑', fontSize:'14pt', fontWeight:'bold'}}>信息安全承诺书</span></h1> */}
    </div>
  </div>
  <div className="layoutPage-footer"></div>
</section>
)
}

  return (
    <Popup
    {...props}
    // visible={visible}
    // onMaskClick={() => {
    //   setVisible5(false)
    // }}
    showCloseButton
    onClose={() => {
      handleClose()
    }}
    bodyStyle={{
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      minHeight: '85vh',
     zIndex: "9999"
    }}
  >
    <div 
    style={{ height: '65vh', overflowY: 'scroll', padding: '20px' }}
    >
    {context()}
    </div>

  </Popup>
    // <Modal
    //   popup
    //   animationType="slide-up"
    //   style={{ height: "85%", zIndex: "9999" }}
    //   content={context()}
    //   {...props}
    // >
    // </Modal>
  );
}

export default AliChat;
