import React from 'react'

function ArticleCon() {
  //
  /** 
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /** 
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */

  /** 
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <React.Fragment>
      <div>
        <div className="am-xinxi-context">个人信息保护政策</div>
        <p><span>本政策将帮助您了解以下内容：</span></p>
        <p><span>一、定义与解释</span></p>
        <p><span>二、我们如何收集和使用您的个人信息</span></p>
        <p><span>三、我们如何使用 Cookie 和同类技术</span></p>
        <p><span>四、我们如何委托处理、向第三方提供、转移、公开您的个人信息</span></p>
        <p><span>五、我们如何存储和保护您的个人信息</span></p>
        <p><span>六、您的权利</span></p>
        <p><span>七、我们如何处理敏感个人信息</span></p>
        <p><span>八、您的个人信息如何在全球范围转移</span></p>
        <p><span>九、本个人信息保护政策如何更新</span></p>
        <p><span>十、如何联系我们</span></p>
        <br />
        <p><span>我司深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我司承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</span></p>
        <br />
        <p><span>请在使用我们的产品（或服务）前，仔细阅读并了解本《个人信息保护政策》（以下简称“政策”），并确认了解我们对您个人信息的处理规则。一旦您点击确认接受我们的政策或在我们的政策更新后我们重新获得您的确认，即表示您认可并接受本政策（含更新版本）的所有内容，并同意我司按本政策收集、使用、保存、共享、转让或披露您的相关个人信息。</span></p>
        <br />
        <p><span>一、定义与解释</span></p>
        <br />
        <p><span>1. 个人信息</span></p>
        <p><span>个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</span></p>
        <br />
        <p><span>2. 敏感个人信息</span></p>
        <p><span>敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</span></p>
        <br />
        <p><span>3. 个人信息主体</span></p>
        <p><span>个人信息主体指个人信息所标识的自然人。</span></p>
        <br />
        <p><span>4. 去标识化</span></p>
        <p><span>去标识化指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。</span></p>
        <br />
        <p><span>5. 匿名化</span></p>
        <p><span>匿名化指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</span></p>
        <br />
        <p><span>6. 设备</span></p>
        <p><span>设备是指可用于访问我们服务的装置，例如台式计算机、笔记本电脑、平板电脑或智能手机。</span></p>
        <br />
        <p><span>7. 唯一设备标识符</span></p>
        <p><span>唯一设备标识符（专属 ID 或 UUID）是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（移动设备的 IMEI 号）。唯一设备标识符有多种用途，包括可在不能使用cookies时用以提供广告。</span></p>
        <br />
        <p><span>8. IP地址</span></p>
        <p><span>每台上网的设备都会指定一个编号，称为互联网政策 (IP) 地址。这些编号通常都是根据地理区域指定的。IP 地址通常可用于识别设备连接至互联网时所在的位置。</span></p>
        <br />
        <p><span>9. GPS</span></p>
        <p><span>Global Positioning System（全球定位系统）的简称。您使用的设备上的GPS信号接收器可以从GPS卫星收到信号并利用传来的信息计算您的位置。</span></p>
        <br />
        <p><span>10. WIFI</span></p>
        <p><span>Wi-Fi是一种允许电子设备连接到一个无线局域网（WLAN）的技术，通常我们所说的无线网络。</span></p>
        <br />
        <p><span>11. 基站</span></p>
        <p><span>基站，即公用移动通信基站，是无线电台站的一种形式，是指在一定的无线电覆盖区中，通过移动通信交换中心，与移动电话终端之间进行信息传递的无线电收发信电台。</span></p>
        <br />
        <p><span>12. SSL</span></p>
        <p><span>SSL（Secure Socket Layer）又称为安全套接层，是在传输通信政策（TCP/IP）上实现的一种安全政策。SSL支持各种类型的网络，同时提供三种基本的安全服务，均通过使用公开密钥和对称密钥技术以达到信息保密的效果。</span></p>
        <br />
        <p><span>13. 信息收集技术</span></p>
        <br />
        <p><span>我司在本政策中所述的“自动收集”包括以下方式：</span></p>
        <br />
        <p><span>（1）Cookie</span></p>
        <p><span>Cookie是您浏览网页时，网站服务器放在客户端（您的计算机、移动电话或其他智能终端内）里面的一个小小的文本文件，可用于改善您的使用体验。</span></p>
        <br />
        <p><span>（2）Web beacon（网络信标）</span></p>
        <p><span>Web beacon是装嵌在网站或电邮内的电子图像文件案或其他技术，可用于计算访客数目、记录您是否及何时阅览电邮或网站，或用以使用某些Cookie。</span></p>
        <br />
        <p><span>（3）Log files（日志文件）</span></p>
        <p><span>ET Tag是在互联网浏览器与互联网服务器之间背后传送的HTTP政策标头，可代替Cookie，用以追踪个别使用者，使我们可更深入地了解和改善我们的服务。</span></p>
        <br />
        <p><span>（4）ET Tag（实体标签）</span></p>
        <p><span>3、淫秽色情信息危害行为：立即删除淫秽色情和低俗信息，并查找原因分析不足，对发布的信息建立完善的审核、管控机制，进行事前审核、事中监控、事后处置，防止出现淫秽色情和低俗信息；对非法信息源、内容引入路径等进行封堵，禁止引入、发布包含淫秽色情和低俗内容的域名链接。对网络安全影响严重，可能或已经造成</span><span>电信运营商、我司及关联方</span><span>网络及其他客户损失时，</span><span>电信运营商、我司及关联方</span><span>将立即中断客户网络连接，同时以多种方式通知客户。对于相关人员视情节轻重采取相应处理措施，直至移交国家信息安全机构及当地司法机关依法处理。</span></p>
        <br />
        <p><span>（5）JavaScript</span></p>
        <p><span>JavaScript是一种编程语言，用于制作更具互动性和动态的网页。JavaScript可以设定Cookie、阅读Cookie及删除Cookie。</span></p>
        <br />
        <p><span>14.个人信息的处理</span></p>
        <br />
        <p><span>个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</span></p>
        <br />
        <p><span>二、我们如何收集和使用您的个人信息</span></p>
        <br />
        <p><span>我们根据合法、正当、必要的原则，出于如下所述的目的收集和使用您的个人信息。</span></p>
        <br />
        <p><span>1.我们提供的业务功能需要依赖部分信息才得以运行。您选择使用我司的产品和服务，需要向我们提供或允许我们收集并使用的必要信息包括：</span></p>
        <br />
        <p><span>（1）为了我们能够为您提供互联网号卡申请的订单查询服务，我们需收集您的基本注册或登录信息，包括您的手机号码。如果您不提供相关信息，则无法享受我们提供的服务。</span></p>
        <br />
        <p><span>（2）为保证您能够正常使用我司提供的互联网号卡申请服务，确保您成功提交号卡订单，我们会根据相关电信法律法规及运营商的政策要求对您进行身份核验，在这个过程中我们可能会收集一些必要的个人信息以实现前述目的，包括您的姓名、电话号码、身份证等。</span></p>
        <br />
        <p><span>（3）为了向您展示您账户内的订单信息及便于您对订单进行管理，也为了我们及时跟进处理您存在异常的订单，在必要时对您进行客户回访等，我们会在您下完订单后，收集您在使用我们的产品或服务过程中所产生的订单信息，具体可能包括如下内容:订单编号、订单来源、商品名称、下单时间、用户ID、订单价格、实际支付价格、支付方式，如您选购的商品涉及邮寄服务，我们还将收集您填写的收货信息，包括收货人、联系电话、收货地址等。</span></p>
        <br />
        <p><span>（4）为了使我们更加了解您如何使用我们的服务，从而针对性地满足您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他使用我们服务的用户做出其他方面的回应，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</span></p>
        <br />
        <p><span>a.日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。</span></p>
        <br />
        <p><span>b.设备信息：我们会根据您在产品使用过程中授予的具体权限，接收并记录您所使用的设备相关信息（例如浏览器及版本、操作系统及版本、设备分辨率信息）、设备所在位置相关信息（IP 地址）。</span></p>
        <br />
        <p><span>请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，该个人信息我们将依法依规进行处理并取得您的同意。</span></p>
        <br />
        <p><span>c.位置信息：当您开启设备定位功能，下载使用我们的产品或访问移动网页使用我们的服务时，我们在征求您的同意之后，可能会记录您设备所在的位置信息（大多数移动设备将允许您关闭定位服务，具体方法建议您联系您的移动设备的服务商或生产商）。</span></p>
        <br />
        <p><span>（5）您通过我们的客服或参加我司举办的活动时所提交的信息</span></p>
        <br />
        <p><span>（6）您通过其他方式向我司所提交的信息</span></p>
        <br />
        <p><span>2.如您下载使用我们的应用软件，我们可能通过您授予我们app具体的系统权限额外收集对应功能所需的个人信息，对于此类个人信息您有权自主选择是否允许我们收集，且经您授权的系统权限后续都可以在系统的设置功能中选择关闭，关闭后我们将无法通过这些权限收集对应的个人信息。对于系统权限的具体操作方式，以您的设备系统开发者提供的说明为准。</span></p>
        <br />
        <p><span>3.我们收集上述必要个人信息及经您同意后向我们提供或允许我们收集的非必要个人信息，除为了提供、维护和改进对应业务功能，为您提供相应的服务外，还将用于以下用途：</span></p>
        <br />
        <p><span>（1）您了解并同意，在符合法律规定的前提下，我们可以自行或委托第三方通过技术手段对您的个人信息数据进行处理，并在符合相关法律法规及监管政策的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用。</span></p>
        <br />
        <p><span>（2）为您推送和展示定制内容基于收集到的信息，我们可能向您推荐您可能感兴趣的内容，包括但不限于向您推送、展示个性化的搜索结果、信息流或第三方推广信息，或者在征得您同意的情况下与我司的关联公司共享信息以便他们向您发送有关其产品或服务的信息。</span></p>
        <br />
        <p><span>如我们基于上述收集到的信息向您个人进行信息推送、商业营销，我们将同时为您提供便捷的拒绝方式，或您有权通过本政策文首的联系方式联系我们处理您的相关请求，我们将及时响应并解决您反映的问题。</span></p>
        <br />
        <p><span>（3）持续开发和优化产品服务</span></p>
        <br />
        <p><span>我们使用收集到的信息保障我们及关联方、合作方的业务运营，例如评估、维护和改进产品和服务的性能，当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的相关服务。</span></p>
        <br />
        <p><span>为迭代和开发新的产品和服务，我们可能对收集的信息加以统计分析辅助决策，但形成的数据分析结果不包含您的任何身份识别信息。</span></p>
        <br />
        <p><span>4. 事先征得授权同意的例外</span></p>
        <br />
        <p><span>根据相关法律法规及国家标准，在以下情形中，我们会依法收集并使用您的个人信息无需征得您的同意：</span></p>
        <br />
        <p><span>-为订立、履行您作为一方当事人的合同所必需；</span></p>
        <p><span>- 为履行法定职责或者法定义务所必需；</span></p>
        <p><span>- 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span></p>
        <p><span>- 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span></p>
        <p><span>- 依照法律规定在合理的范围内处理您自行公开或者其他已经合法公开的您的个人信息；</span></p>
        <p><span>- 法律、行政法规规定的其他情形。</span></p>
        <br />
        <p><span>5. 补充说明</span></p>
        <br />
        <p><span>当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</span></p>
        <p><span>当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意</span></p>
        <br />
        <p><span>三、我们如何使用Cookie、同类技术</span></p>
        <br />
        <p><span>1. cookie</span></p>
        <br />
        <p><span>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物篮内的商品等数据</span></p>
        <br />
        <p><span>我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。</span></p>
        <br />
        <p><span>2. 网站信标和像素标签</span></p>
        <p><span>除 Cookie 外，我们还可能会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击 URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</span></p>
        <br />
        <p><span>3. Do Not Track（请勿追踪）</span></p>
        <p><span>很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。</span></p>
        <br />
        <p><span>四、我们如何委托处理、向第三方提供、转移、公开您的个人信息</span></p>
        <br />
        <p><span>1.委托处理</span></p>
        <p><span>我们的一些业务功能和技术服务可能由外部供应商提供。我们委托处理个人信息的，会与受托人明确约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督。</span></p>
        <br />
        <p><span>我们会严格要求受托人按照约定处理个人信息，不得超出约定的处理目的、处理方式等处理个人信息；如发生委托合同不生效、无效、被撤销或者终止等情形，我们将要求受托人将个人信息返还或者予以删除，不得保留。</span></p>
        <br />
        <p><span>2.向第三方提供</span></p>
        <br />
        <p><span>我们不会向本公司以外的任何公司、组织和个人提供您的个人信息，除非获得您的明确同意。目前，我们会在以下情形中，向您征求您对我们向第三方提供您个人信息的单独授权同意：</span></p>
        <br />
        <p><span>（1）为了向您提供我们的服务，我们需向合作伙伴电信运营商及其他必要第三方分享您的个人信息，以实现向您提供您需要的服务</span></p>
        <br />
        <p><span>（2）与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span></p>
        <br />
        <p><span>我们的关联公司包括我司现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</span></p>
        <br />
        <p><span>（3）与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会向合作伙伴提供您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您通过我们的网站购买商品或订购业务时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span></p>
        <br />
        <p><span>目前，我们的授权合作伙伴包括以下【三】大类型：</span></p>
        <br />
        <p><span>a.供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</span></p>
        <br />
        <p><span>b.第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</span></p>
        <br />
        <p><span>c.广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助在不识别您个人的前提下提升广告及服务信息的有效触达率。</span></p>
        <br />
        <p><span>我们在征得您对我们向第三方提供您个人信息的单独授权同意的同时，会向您告知接收方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类；我们还会与接收方签署严格的保密协定，要求他们依照法律规定、按照我们的说明、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。</span></p>
        <br />
        <p><span>3.转移</span></p>
        <br />
        <p><span>因合并、分立、解散、被宣告破产等原因需要转让个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。我们会要求接收方继续受本政策的约束，否则我们将要求接收方重新取得您个人的同意。</span></p>
        <br />
        <p><span>4.公开</span></p>
        <br />
        <p><span>我们不会公开我们处理的您的个人信息，除非取得您的单独同意</span></p>
        <br />
        <p><span>5.向第三方提供、公开个人信息时事先征得授权同意的例外</span></p>
        <br />
        <p><span>以下情形中，向第三方提供、公开您的个人信息无需事先征得您的同意：</span></p>
        <br />
        <p><span>- 为订立、履行您作为一方当事人的合同所必需；</span></p>
        <p><span>- 为履行法定职责或者法定义务所必需；</span></p>
        <p><span>- 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span></p>
        <p><span>- 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span></p>
        <p><span>- 依照法律规定在合理的范围内处理您自行公开或者其他已经合法公开的您的个人信息；</span></p>
        <p><span>- 法律、行政法规规定的其他情形。</span></p>
        <br />
        <p><span>我们会在合理的范围内处理您自行公开或者其他已经合法公开的个人信息，您明确拒绝的除外。我们处理上述已公开的个人信息，对于可能对您个人权益产生重大影响的，我们会依照法律规定取得您个人的同意。</span></p>
        <br />
        <p><span>五、我们如何存储和保护您的个人信息</span></p>
        <br />
        <p><span>1. 我们如何存储信息</span></p>
        <p><span>1）存储信息的地点</span></p>
        <p><span>我们遵守法律法规的规定，收集的有关您的信息和资料将保存在中华人民共和国境内我司的服务器上。以下情形除外：</span></p>
        <br />
        <p><span>- 法律法规有明确规定；</span></p>
        <p><span>- 获得您的明确授权；</span></p>
        <p><span>- 您通过互联网进行跨境交易等个人主动行为。</span></p>
        <br />
        <p><span>2）存储信息的期限</span></p>
        <br />
        <p><span>一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。用于识别您个人身份以及反映相关活动的个人信息将留存至用户注销账号后六个月，除非法律法规另有规定。</span></p>
        <br />
        <p><span>在您终止使用我们的产品或服务后，我们会停止对您的信息的收集和使用，已经收集的信息会留存2年，法律法规或监管部门另有规定的除外。</span></p>
        <br />
        <p><span>当我们的相关服务发生停止运营的情形时，我们将采取包括推送通知和公告形式在内的通知您，并在合理的期限内删除服务器中留存的您的个人信息或进行匿名化处理。</span></p>
        <br />
        <p><span>2. 我们如何保护您的个人信息</span></p>
        <br />
        <p><span>（1）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如身份证信息）时受 SSL 加密保护；我们同时对我们网站提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span></p>
        <br />
        <p><span>（2）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span></p>
        <br />
        <p><span>（3）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</span></p>
        <br />
        <p><span>（4）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span></p>
        <br />
        <p><span>（5）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</span></p>
        <br />
        <p><span>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span></p>
        <br />
        <p><span>六、您的权利</span></p>
        <br />
        <p><span>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利，您可以参考下面的步骤进行相关操作：</span></p>
        <br />
        <p><span>1.撤回同意的权利</span></p>
        <br />
        <p><span>鉴于我们是基于您的同意处理您的个人信息，您可以通过我们的在线客服或拨打客服电话联系我们，撤回您的同意，我们将在30天内响应您的需求。</span></p>
        <br />
        <p><span>请您知悉，我们提供的产品和服务需要一些必要的个人信息才能得以完成（见本政策“第二部分”），对于此类必要信息，如您撤回您的同意，我们将无法为您提供我们的产品或服务；对于额外收集的个人信息的收集和使用，您有权随时向我们撤回您的同意。</span></p>
        <br />
        <p><span>在我们依照您的要求处理您的撤回同意请求后，我们将不再处理您撤回同意所涉及的个人信息，同时我们也将无法为您提供对应的或全部的服务。但您的撤回同意，不影响撤回前基于您的同意已进行的个人信息处理活动的效力。</span></p>
        <br />
        <p><span>2. 查阅、复制您的个人信息</span></p>
        <br />
        <p><span>您有权查阅、复制您的个人信息，法律法规规定的例外情况除外。如果您想行使数据查阅权、复制权，可以随时通过我们的在线客服或拨打客服电话与我们联系。我们将在30天内回复您。</span></p>
        <br />
        <p><span>3. 转移个人信息</span></p>
        <p><span>您请求将您的个人信息转移至您指定的个人信息处理者的，如果符合国家网信部门规定条件的，我们会提供转移的途径。如果您想行使前述权利，可以随时通过我们的在线客服或拨打客服电话与我们联系，我们将在30天内回复您。</span></p>
        <br />
        <p><span>4.更正、补充您的个人信息</span></p>
        <p><span>当您发现我们处理的关于您的个人信息有错误或需要补充时，您有权要求我们做出更正、补充。您可以通过发送电子邮件【 service@gzjunbo.net】联系我们。我们将在30天内对您的个人信息予以核实，并及时更正、补充。</span></p>
        <br />
        <p><span>5.删除您的个人信息</span></p>
        <br />
        <p><span>在以下情形中，我们会主动删除且您有权要求我们删除您的个人信息：</span></p>
        <br />
        <p><span>（1）我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</span></p>
        <p><span>（2）我们停止提供产品或者服务，或者保存期限已届满；</span></p>
        <p><span>（3）您撤回同意；</span></p>
        <p><span>（4）我们违反法律、行政法规或者违反约定处理个人信息；</span></p>
        <p><span>（5）法律、行政法规规定的其他情形。</span></p>
        <br />
        <p><span>法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。</span></p>
        <br />
        <p><span>6. 约束信息系统自动化决策</span></p>
        <br />
        <p><span>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。我们保证前述决策的透明度和结果公平、公正，不对个人在交易价格等交易条件上实行不合理的差别待遇。</span></p>
        <br />
        <p><span>如我们通过自动化决策方式向您进行信息推送、商业营销，我们将同时提供不针对您个人特征的选项，或者向您提供便捷的拒绝方式。</span></p>
        <br />
        <p><span>如我们通过自动化决策方式作出的决定对您的权益产生重大影响，您有权通过我们的在线客服或拨打客服电话联系我们要求我们予以说明，我们会提供相应的救济，且您有权拒绝我们仅通过自动化决策的方式作出决定。</span></p>
        <br />
        <p><span>7.您行使权利的指引</span></p>
        <br />
        <p><span>为保障安全，在行使权利时您可能需要向我们书面提出，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span></p>
        <br />
        <p><span>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情形收取一定成本费用。</span></p>
        <br />
        <p><span>七、第三方网站和服务</span></p>
        <br />
        <p><span>我们的政策不适用于第三方提供的产品或服务。您使用我们的产品或服务可能包括第三方的产品或服务，其中一些会以第三方网站的链接形式提供，还有一些会以SDK、API等形式接入。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的个人信息保护政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的个人信息保护政策不适用通过我们的服务链接的其他网站。</span></p>
        <br />
        <p><span>八、我们如何处理敏感个人信息</span></p>
        <br />
        <p><span>1．只有在具有特定的目的和充分的必要性，并采取严格保护措施的情形下，我们才会处理您的敏感个人信息。</span></p>
        <br />
        <p><span>2．处理您的敏感个人信息我们会取得您的单独同意；法律、行政法规规定处理敏感个人信息应当取得您的书面同意的，我们会取得您的单独书面同意。</span></p>
        <br />
        <p><span>3．我们处理您的敏感个人信息的，会向您告知处理敏感个人信息的必要性以及对您个人权益的影响，除非法律另有规定。</span></p>
        <br />
        <p><span>4．我们如何处理儿童的个人信息</span></p>
        <br />
        <p><span>（1）尽管各地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。</span></p>
        <br />
        <p><span>（2）我们只会在符合法律规定且取得儿童父母或监护人明确同意的情况下处理儿童的个人信息。</span></p>
        <br />
        <p><span>（3）我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。</span></p>
        <br />
        <p><span>九、您的个人信息如何在全球范围转移</span></p>
        <br />
        <p><span>只有在完全符合我国法律规定的前提下，我们才会将您的个人信息跨境提供。</span></p>
        <br />
        <p><span>十、本个人信息保护政策如何更新</span></p>
        <br />
        <p><span>我们的个人信息保护政策可能变更。</span></p>
        <br />
        <p><span>1. 未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在专门页面上发布对政策所做的任何变更并重新征得您的同意。同时我们会将本政策的旧版本存档，供您查阅。</span></p>
        <br />
        <p><span>2. 对于重大变更，我们还会提供更为显著的通知，包括我们会通过网站公示的方式进行通知甚至向您提供弹窗提示。</span></p>
        <br />
        <p><span>本政策所指的重大变更包括但不限于：</span></p>
        <br />
        <p><span>- 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span></p>
        <br />
        <p><span>- 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</span></p>
        <br />
        <p><span>- 个人信息共享、转让或公开披露的主要对象发生变化；</span></p>
        <br />
        <p><span>7.您行使权利的指引</span></p>
        <br />
        <p><span>- 您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>
        <br />
        <p><span>- 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></p>
        <br />
        <p><span>- 个人信息安全影响评估报告表明存在高风险时；</span></p>
        <br />
        <p><span>- 伴随政策或法律发布实施，为响应国家政策或者法律规范，做出变更。</span></p>
        <br />
      </div>

    </React.Fragment>
  )
}

export default ArticleCon
