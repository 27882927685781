// Cookie
export function saveCookie(key, valueString, opt = {}) {
    // let _cookie = key + '=iotc_' + JSON.stringify(value)
    let _cookie = key + '=iotc_' + escape(valueString)
    // 过期时间，先默认分钟为单位
    if (opt['expires']) {
      let tVal = opt['expires'] || 7
      let t = new Date()
      t.setTime(t.getTime() - 8 * 60 * 60 * 1000 + tVal * 24 * 60 * 60 * 1000)
      // console.log(t.toUTCString())
      t.toUTCString()
      _cookie = `${_cookie};expires=${t}`
    }
    // 路径
    if (opt['path']) {
      _cookie = `${_cookie};path=${opt['path']}`
    } else {
      _cookie = `${_cookie};path=/`
    }
    // 域名
    if (opt['domain']) {
      _cookie = `${_cookie};domain=${opt['domain']}`
    }
    // 安全连接
    if (opt['secure']) {
      _cookie = `${_cookie};secure}`
    }
    document.cookie = _cookie
  }
  
  export function getCookie(key) {
    let _cookies = document.cookie.split(';')
    let _result
    _cookies.forEach((item) => {
      let _cookie = item.trim().split('=iotc_')
      if (_cookie[0] === key) {
        // _result = JSON.parse(_cookie[1])
        _result = unescape(_cookie[1])
      }
    })
    return _result
  }
  
  export function removeCookie(key) {
    document.cookie = `${key}=iotc_;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  }
  
  
  /**
   * Cache
   * 命名规则：`cc_${modID}`
   * 结构规则：{ timestamp, lifecycle, data }, lifecycle [born, alive] born 新生数据, alive 可用数据
   * 读取规则: lifecycle = alive，且时效1天内可读
   * 部分缓存仅在页面之间操作生效的，如：（跳转并返回，保留上一步的数据）。需控制lifecycle变化。
   */
  
  /**
   * 缓存cache数据
   * @param {String} modName 
   * @param {any} modData 
   * @param {Object} modAttach lifeCycle [born, alive]
   */
  export function saveCache(modName, modData, modAttach = {}) {
    const { lifecycle, prefix, unique } = modAttach
    const _cache = {
      unique, // 用于区分不同用户存储的信息
      timestamp: new Date().getTime(),
      data: modData,
      lifecycle: lifecycle || 'alive'
    }
    const _prefix = prefix || 'cc_'
    localStorage.setItem(`${_prefix}${modName}`, JSON.stringify(_cache))
  }
  
  /**
   * 读取cache缓存
   * @param {*} modName 
   * @param {*} modAttach 
   */
  export function getCache(modName, modAttach = {}) {
    // force 表示忽略 lifecycle
    const { prefix, force, unique, duration, } = modAttach
    const _prefix = prefix || 'cc_'
    const _cache = JSON.parse(localStorage.getItem(`${_prefix}${modName}`))
    //
    if (!_cache) return null
    const { timestamp, lifecycle } = _cache
    const _now = new Date().getTime()
    const _delay = _now - timestamp
    const _threshold = duration || 24 * 60 * 60 * 1000
    //
    if (lifecycle !== 'alive' && !force) return null
    //
    if (unique && unique !== _cache['unique']) return null
    //
    if (_delay >= _threshold) {
      removeCache(modName, modAttach)
      return null
    }
    return _cache
  }
  
  /**
   * 删除cache缓存
   * @param {*} modName 
   * @param {*} modAttach 
   */
  export function removeCache(modName, modAttach = {}) {
    const { prefix } = modAttach
    const _prefix = prefix || 'cc_'
    localStorage.removeItem(`${_prefix}${modName}`)
  }
  
  /**
   * 删除所有cache缓存
   * @param {*} modAttach 
   */
  export function clearCache(modAttach = {}) {
    const { prefix } = modAttach
    const _prefix = prefix || 'cc_'
    const _cacheLength = localStorage.length
    const _cacheReg = eval(`/^(${_prefix})/`)
    let _cacheName
    for (let i = 0; i < _cacheLength; i++) {
      _cacheName = localStorage.key(i)
      if (_cacheReg.test(_cacheName)) {
        localStorage.removeItem(_cacheName)
      }
    }
  }
  
  window.cksProxy = {
    save: saveCookie,
    get: getCookie,
    remove: removeCookie,
  }
  
  window.cacheProxy = {
    save: saveCache,
    get: getCache,
    remove: removeCache,
    clear: clearCache,
  }
  
  