import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/simsms'
import CONFIG from '@/config/global'
import axios from 'axios'


  /**
 * 获取短信记录
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
//  export function getsRecordList(postData, ok, failed, end) {
//     request.post(apis.getsRecordList, postData)
//       .then(function (response) {
//         ok && ok(response)
//       })
//       .catch(function () {
//         failed && failed()
//       })
//       .finally(function () {
//         end && end()
//       })
//   }

  export async function getsRecordList(postData) {
    // console.log(postData)
    let result = await request.post(apis.getsRecordList,postData);
    // console.log(result.rows);
    return result.rows;
  }
