import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux'
import { WingBlank, WhiteSpace, Icon, Button, List, Input, TextArea, Toast, Modal, Picker, Form, CascadePicker, Checkbox, Popup, Dialog, Popover } from "antd-mobile";
import { createForm } from "rc-form";
import SafeInfoGeRenCheckBlock from "@/components_base/SafeInfoGeRenCheckBlock";
import usePrevious from "@/hooks/usePrevious";
import { getProvince, dataPickerToOptions, fieldsValueToForm, sendVailcode, submitHaokadxOrder, getImg, seachHaokadx } from "./fn";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import picIcon01 from "@/assets/images/dd1.png";
import picIcon02 from "@/assets/images/dd2.png";
import ArticleCon from "./ArticleCon";
import ArticleCondan from "./ArticleCondan";
import { list } from "postcss";
import GLOBAL from "@/config/global";
<link rel="icon" href="javascript:;" />;

const alert = Modal.alert;

function Orderfor() {
  document.title = "中国电信";
  const navigates = useNavigate();
  // const { number, simid, } = useSelector(state => state.user)
  //
  //
  // const [code, setCode] = useState(null)
  const [phonestate, setphonestate] = useState(false);
  const [formInstance] = Form.useForm();
  // 表单提交
  const [formData, setFormData] = useState(null);
  const prevFormData = usePrevious(formData);
  // const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [phonevalue, setPhoneValue] = useState(null);
  const [pickerList, setPickerList] = useState([]);
  // 是否同意
  const [agreed, setAgreed] = useState(false);
  const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
  const userid = _urlSearch["uid"] || _urlSearchHash["uid"];
  const [countdown, setCountdown] = useState(0);
  const [dandu, setDandu] = useState(false);
  const [xinxi, setXinxi] = useState(false);
  const [imagUp, setImagUp] = useState(null);
  const [imagDo, setImagDo] = useState(null);
  const [iscode, setisCode] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let a = await getProvince();
      let lists = dataPickerToOptions(a.list);
      setPickerList(lists);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (userid) {
        let a = await getImg({ userid: userid });
        let image1 = a && a.path29A ? `${GLOBAL["downloadDomainH5"]}${a.path29A}` : picIcon01;
        let image2 = a && a.path29B ? `${GLOBAL["downloadDomainH5"]}${a.path29B}` : picIcon02;
        setImagUp(image1);
        setImagDo(image2);
        if (a && a.issms == 1) {
          setisCode(true);
        } else {
          setisCode(false);
        }
      } else {
        setImagUp(picIcon01);
        setImagDo(picIcon01);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (disabled && formData && formData !== prevFormData) {
      // console.log(formData);
      const { name, phone, provinceCode, cityCode, countyCode, address, code, idcard } = formData;
      if (!name) {
        Toast.show("请输入真实姓名");
      }
      if (!phone) {
        Toast.show("联系电话不能为空");
      }
      if (!idcard) {
        Toast.show("身份证号不能为空");
      }
      if (address == null) {
        Toast.show("请输入详细地址");
      }
      if (provinceCode == null || cityCode == null || countyCode == null) {
        Toast.show("请选择地址");
      }
      if (!agreed) {
        Toast.show({
          content: "请阅读并同意《个人信息保护政策》《单独同意书》",
        });
        setDisabled(false);
      }
      if (iscode && !code) {
        Toast.show("验证码不能为空");
      }
      if (agreed) {
        // console.log(formData);
        // return
        submitHaokadxOrder(
          formData,
          (response) => {
            if (response.code == 1) {
              Dialog.alert({
                content: response.msg,
                closeOnMaskClick: true,
                confirmText: "已知晓",
                onConfirm: () => {
                  window.location.reload();
                },
              });
            } else {
              Dialog.alert({
                content: response.msg,
                closeOnMaskClick: true,
                confirmText: "已知晓",
                onConfirm: () => {
                  window.location.reload();
                },
              });
              setDisabled(false);
              // Toast.show(response.msg, 2)
            }
          },
          () => {
            setDisabled(false);
          },
          () => {
            //setLoading(false)
          }
        );
      }
    }
  }, [disabled, formData, prevFormData]);

  function handleCountdown() {
    if (phonevalue && phonevalue != null) {
      sendVailcode(
        { phone: phonevalue, userid: userid },
        (response) => {
          // console.log(response);
          // setCode(response.value)
          Toast.show("发送验证码成功,五分钟内有效");
          setCountdown(120);
          // 开始倒计时
          const timer = setInterval(() => {
            setCountdown((countdown) => {
              if (countdown <= 1) {
                // 倒计时结束，清除定时器
                clearInterval(timer);
                // 恢复按钮状态
                return 0;
              } else {
                // 继续倒计时
                return countdown - 1;
              }
            });
          }, 1000);
        },
        () => { },
        () => { }
      );
    }
  }

  function onCopyLink(record) {
    //console.log("record:::::::::::::" + JSON.stringify(record))
    const input = document.createElement("input");
    input.value = record;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand("Copy");
    document.body.removeChild(input);
    Toast.show("复制成功！");
  }

  function openShowTips() {
    // console.log(phonevalue);
    if (phonevalue == null && phonevalue == undefined) {
      Toast.show("联系电话不能为空");
      return
    }
    seachHaokadx(
      { phone: phonevalue },
      (response) => {
        console.log(response);
        if (response.code == 1) {
          const Array = response.info.map((item, index) => (
            <div key={index} style={{ 'fontSize': '15px' }} onClick={() => onCopyLink(item.kofreightcode)}>
              {item.kofreightname}(订单号)：{item.kofreightcode}<span style={{ margin: "0 2px" }} /><span role="img" aria-label="copy" className="anticon anticon-copy">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="0.21rem" height="0.21rem" fill="currentColor" aria-hidden="true">
                  <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                </svg>
              </span>
            </div>
          ));
          Modal.show({
            content: Array,
            closeOnMaskClick: true,
          })

        } else {
          Modal.show({
            content: "未查询到订单信息",
            closeOnMaskClick: true,
          })
        }
      },
      () => { },
      () => { }
    );

  }

  function onFinish(values) {
    const _formData = fieldsValueToForm(values, userid, iscode);
    setDisabled(true);
    setFormData(_formData);
  }
  // 协议
  function handleChangeAgree(checked) {
    setAgreed(checked);
    // console.log(`协议：${checked}`)
  }
  const handleImageError1 = (e) => {
    //console.log("图片加载失败,替换为系统预设图1:", e);
    setImagUp(picIcon01);
  };
  const handleImageError2 = (e) => {
    //console.log("图片加载失败,替换为系统预设图2:", e);
    setImagDo(picIcon02);
  };
  return (
    <div className="orderfor-background">
      <section className={`layoutPage`}>
        <div className="layoutPage-header">
          <div className="widget-view">
            <div style={{ margin: "0rem" }}>
              <div className="flex flex-wrap">
                <div className="flex flex-center cursor-pointer">
                  <img width="100%" src={imagUp} onError={handleImageError1} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="layoutPage-body">
          <div className="widget-view">
            <div className="widget-form-list">
              {/* <section className="imitWxForm"> */}
              {/* <br /> */}
              <div className="widget-view">
                <div style={{ margin: "0rem" }}>
                  <ul className="flex flex-wrap"></ul>
                </div>
              </div>
              <div className="widget-view">
                <div className="wg-item wg-padding align-middle">
                  <Form
                    layout="horizontal"
                    form={formInstance}
                    onFinish={onFinish}
                    footer={[
                      <Button
                        type="primary"
                        className="button1"
                        key="button1"
                        // loading={loading}
                        disabled={disabled}>
                        免费领取
                      </Button>,
                      //复选框   我已阅读
                      <div className="checkAgreementBlock" key="x1" style={{ color: "rgb(204, 204, 204)", margin: "0.2rem 6px", padding: "16px 0" }}>
                        <div className="checkBox">
                          <Checkbox
                            key="x2"
                            style={{
                              "--icon-size": "18px",
                              "--gap": "6px",
                              "--font-size": "inherit",
                              height: "1em",
                            }}
                            defaultChecked={agreed}
                            // onChange={handleChangeCheck}
                            onChange={(val) => {
                              // console.log(val);
                              setAgreed(val);
                            }}
                          />
                        </div>
                        <div className="main">
                          我已阅读并同意
                          <span
                            className="icon tips"
                            onClick={() => {
                              setXinxi(true);
                            }}>
                            《个人信息保护政策》
                          </span>
                          <Popup
                            visible={xinxi}
                            showCloseButton
                            onClose={() => {
                              setXinxi(false);
                            }}>
                            <div className="am-xinxi-body" style={{ height: "60vh", overflowY: "scroll", padding: "20px" }}>
                              <ArticleCon />
                            </div>
                          </Popup>
                          和
                          <span
                            className="icon tips"
                            onClick={() => {
                              setDandu(true);
                            }}>
                            《单独同意书》
                          </span>
                          <Popup
                            visible={dandu}
                            showCloseButton
                            onClose={() => {
                              setDandu(false);
                            }}>
                            <div className="am-xinxi-body" style={{ height: "60vh", overflowY: "scroll", padding: "20px" }}>
                              <ArticleCondan />
                            </div>
                          </Popup>
                        </div>
                      </div>,
                      <div onClick={openShowTips} key="x2" className='orderchaxun'><span ></span>查看订单</div>
                    ]}>
                    <Form.Item
                      label="姓名"
                      name="ordername"
                      rules={[
                        {
                          required: true,
                          message: "请填姓名",
                        },
                      ]}>
                      <Input placeholder="请填写办理人真实姓名(已加密)"></Input>
                    </Form.Item>
                    <Form.Item
                      label="手机号码"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "请填写联系电话",
                        },
                        {
                          validator: (rule, value) => {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value.length == 11) {
                              setPhoneValue(value);
                              // console.log(value);
                              setphonestate(true);
                              return Promise.resolve();
                            }
                            setphonestate(false);
                            return Promise.reject("联系电话不得少于11位");
                          },
                        },
                      ]}>
                      <Input placeholder="请填写本人联系电话(已加密)"></Input>
                    </Form.Item>
                    <Form.Item
                      label="身份证号"
                      name="idcard"
                      rules={[
                        {
                          required: true,
                          message: "请填写身份证号",
                        },
                        {
                          validator: (rule, value) => {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value.length == 18) {
                              return Promise.resolve();
                            }
                            return Promise.reject("身份证号不得少于18位");
                          },
                        },
                      ]}>
                      <Input placeholder="请填写本人身份证号(已加密)"></Input>
                    </Form.Item>
                    {phonestate && pickerList && pickerList != [] && (
                      <Form.Item
                        label="收货地址"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "请选择收货地址",
                          },
                        ]}
                        trigger="onConfirm"
                        onClick={() => {
                          setPickerVisible(true);
                        }}>
                        <CascadePicker
                          title="地区选择"
                          visible={pickerVisible}
                          options={pickerList}
                          onConfirm={(values, { items }) => {
                            // console.log(values);
                            // console.log(items);
                            // console.log(items[0].label);
                            setPickerVisible(false);
                            // console.log(pickerlabel);
                          }}
                          onCancel={() => {
                            setPickerVisible(false);
                          }}>
                          {(value) =>
                            // console.log(value)
                            value[0] == null ? "请选择收货地址" : `${value[0].label}/${value[1].label}/${value[2].label}`
                          }
                        </CascadePicker>
                      </Form.Item>
                    )}

                    {phonestate && (
                      <Form.Item
                        label="详细地址"
                        name="addressdetail"
                        rules={[
                          {
                            required: true,
                            message: "请填写收货地址",
                          },
                          {
                            validator: (rule, value) => {
                              if (!value) {
                                return Promise.resolve();
                              }
                              if (value.length <= 140) {
                                return Promise.resolve();
                              }
                              return Promise.reject("收货地址不得超过140个字符");
                            },
                          },
                        ]}>
                        <Input placeholder="请填写收货地址"></Input>
                      </Form.Item>
                    )}

                    {phonestate && iscode && (
                      <Form.Item
                        label="验证码"
                        name="code"
                        rules={[
                          {
                            required: true,
                            message: "请输入验证码",
                          },
                        ]}
                        extra={
                          <a
                            // className="button2"
                            key="send"
                            onClick={handleCountdown}
                            // loading={countdown > 0}
                            disabled={countdown > 0}
                          //style={style.button2}
                          >
                            {countdown > 0 ? `${countdown}秒后重试` : "发送验证码"}
                          </a>
                        }>
                        <Input placeholder="请输入验证码" clearable />
                      </Form.Item>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            {/* </section> */}
          </div>
          <div className="widget-view">
            <div style={{ margin: "0rem" }}>
              <div className="flex flex-wrap">
                <div className="flex flex-center cursor-pointer">
                  <img width="100%" src={imagDo} onError={handleImageError2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default createForm()(Orderfor);
