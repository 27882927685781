import * as ActionTypes from './action'
import CONFIG from '@/config/global'
import { getCookie } from '@/utils/storage'

window.getCookie = getCookie

const _localOauth = getCookie(CONFIG['oauth']) ? JSON.parse(getCookie(CONFIG['oauth'])) : {}

// console.log(`获取本地用户登录信息: `)
// console.log(_localAuth)

// 加载本地缓存
let initState = {
  'oauth_code': null,
  'oauth_token': null,
  'oauth_openid': null,
}
let localState = {
  'oauth_code': _localOauth['oauth_code'] ? _localOauth['oauth_code'] : null,
  'oauth_token': _localOauth['oauth_token'] ? _localOauth['oauth_token'] : null,
  'oauth_openid': _localOauth['oauth_openid'] ? _localOauth['oauth_openid'] : null,
}

export default function user(state = Object.assign({}, initState, localState), action) {
  switch(action.type) {
    case ActionTypes.SET_OAUTH_CODE:
      return Object.assign({}, state, {
        oauth_code: action.data,
      })
    case ActionTypes.SET_OAUTH_OPENID:
      return Object.assign({}, state, {
        oauth_openid: action.data,
      })
    default:
      return state
  }
}

