import React from 'react'
// Exported from redux-devtools
import { createDevTools } from 'redux-devtools'
// Monitors are separate packages, and you can make a custom one
import LogMonitor from 'redux-devtools-log-monitor'
import DockMonitor from 'redux-devtools-dock-monitor'

// createDevTools takes a monitor and produces a DevTools component
const DevTools = createDevTools(
  <DockMonitor
    toggleVisibilityKey='ctrl-h'
    changePositionKey='ctrl-q'
    defaultIsVisible={true}
  >
    <LogMonitor theme='tomorrow'></LogMonitor>
  </DockMonitor>
)

export default DevTools

// 后面尝试对比下，引入与不引人该组件，build后的文件体积对比
