import { combineReducers } from 'redux'
// base reducers
import configReducer from './config/reducer'
import userReducer from './user/reducer'
import wechatReducer from './wechat/reducer'

let injectQueue = {}

export function createReducers() {
  return combineReducers({
    config: configReducer,
    user: userReducer,
    wechat: wechatReducer,
    ...injectQueue
  })
}

export function injectReducers(storeInstance, moduleName, moduleReducer) {
  if (injectQueue[moduleName]) {
    return true
  }
  injectQueue[moduleName] = moduleReducer
  let nextReducers = createReducers()
  storeInstance.replaceReducer(nextReducers)
}

export function ridReducers(storeInstance, moduleName) {
  delete injectQueue[moduleName]
  let nextReducers = createReducers()
  storeInstance.replaceReducer(nextReducers)
}
