import request from '@/utils/request'
import apis from '@/config/apis/whitelist'

/**
 * 获取亲情号码列表
 * @param {*} postData
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
export function getWhiteList(postData, ok, failed, end) {
  request.post(apis.getWhiteList, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 删除指定亲情号码
 * @param {*} postData
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
export function delWhiteNumber(postData, ok, failed, end) {
  request.post(apis.delWhiteNumber, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 添加新的亲情号码
 * @param {*} postData
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
export function addWhiteNumber(postData, ok, failed, end) {
  request.post(apis.addWhiteNumber, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

/**
 * 校验指定卡是否可拥有亲情号码
 * @param {*} postData
 * @param {*} ok
 * @param {*} failed
 * @param {*} end
 */
export function hasWhiteListByNumber(postData, ok, failed, end) {
  return request.post(apis.hasWhiteListByNumber, postData)
    .then(function (response) {
      ok && ok(response)
      return response
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}
