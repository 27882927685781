import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { WingBlank, WhiteSpace, Icon, Button, List, Input, Toast, Modal, Dialog } from 'antd-mobile'
import { createForm } from 'rc-form'
import { useNavigate } from 'react-router-dom'
import usePrevious from '@/hooks/usePrevious'
import Alert from '@/components_base/Alert'
import { filterOutBlankChar } from '@/utils/core/base'
import { addWhiteNumber } from './fn'
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";

const alert = Modal.alert

function WhiteAdd(props) {
  const navigates = useNavigate();
  const { number, simids} = useSelector(state => state.user)
  const { cardState} = JSON.parse(getCookie("siminfo"))
  //
  const { getFieldProps, validateFields, getFieldError } = props.form
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function onSubmit() {
    validateFields(['remark', 'family'], (errors, values) => {
      if (errors) {
        const { remark, family } = errors
        let _key = (remark ? 'remark' : null) || (family ? 'family' : null)
        if (_key) {
          Toast.show(getFieldError(`${_key}`))
          return false
        }
      } else {
        const { remark, family } = values
        const _family = filterOutBlankChar(family)
        var msg =cardState==1?" 当前卡为待激活状态,激活后可添加白名单,是否激活并添加?": _family +" 请确认您填写的号码是否正确"
        // console.log(_family);
        Dialog.confirm({
          content: msg,
          closeOnMaskClick: true,
          confirmText: "确定",
          cancelText: "关闭",
          onConfirm: () => {
            Toast.show('提交中')
            // return
            addWhiteNumber(
              {
                id:simids? simids : getCookie("simid"), remark, family: _family,
              },
              (response) => {
                console.log(response);
                var msg = "提交失败,请稍后重试"
                if (response && response.message) {
                  msg = response.message
                }
                Dialog.alert({
                  content: msg,
                  closeOnMaskClick: true,
                  confirmText: "已知晓",
                  onConfirm: () => {
                    Toast.clear();
                    setTimeout(() => {
                      navigates("/whiteList");
                    }, 50)
                  },
                });
              },
              () => { },
              () => { },
            )
          },
          onCancel: () => {

          }
        });


        // Toast.loading('提交中', 0)
        // addWhiteNumber(
        //   {
        //     id: simid, remark, family: filterOutBlankChar(family),
        //   },
        //   () => {
        //     Toast.hide()
        //     setTimeout(() => {
        //       history.push('/my/whiteList/index')
        //     }, 50)
        //   },
        //   () => {},
        //   () => {},
        // )
      }
    })
  }
  //
  // function doAdd(postData) {
  //   Toast.loading('提交中', 0)
  //   addWhiteNumber(
  //     {
  //       id: simid, remark, family: filterOutBlankChar(family),
  //     },
  //     () => {
  //       Toast.hide()
  //       setTimeout(() => {
  //         history.push('/my/whiteList/index')
  //       }, 50)
  //     },
  //     () => {},
  //     () => {},
  //   )
  // }
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <section className={`layoutPage`}>
      <div className="layoutPage-header"></div>
      <div className="layoutPage-body">

        <section className="imitWxForm" style={{ padding: "0.38rem 0.38rem" }}>
          <form>
            <List style={{ padding: "0.38rem 0" }}>
              <List.Item>
                <Input
                  {...getFieldProps(
                    'remark',
                    {
                      rules: [{
                        required: true,
                        message: '请输入昵称'
                      }]
                    }
                  )}
                  placeholder="请输入昵称">昵称</Input>
              </List.Item>
            </List>
            <List>
              <List.Item>
                <Input
                  {...getFieldProps(
                    'family',
                    {
                      rules: [{
                        required: true,
                        message: '请输入手机号码'
                      }]
                    }
                  )}
                  type="phone"
                  placeholder="请输入手机号码">号码</Input>
              </List.Item>
            </List>
          </form>
        </section>

        <div style={{ padding: '0.38rem 0.38rem' }}>
          <Alert message="注意事项" type="warning">
            <p>每个设备最多只能添加5个语音亲情号码；</p>
            <p>每个设备每个自然月只能删除一次亲情号码，每次最多可删除2个亲情号码;</p>
          </Alert>
        </div>


      </div>
      <div className="layoutPage-footer" style={{ padding: '25px 0' }}>
        <Button type="primary" className="am-button am-button-primary" style={{ "--background-color": "#108ee9", color: "#fff" }} onClick={onSubmit}>确认</Button>
      </div>
    </section>
  )
}

export default createForm()(WhiteAdd)
