import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/simInfo'
import CONFIG from '@/config/global'


  /**
 * 获取智能检测的卡状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkState(postData, ok, failed, end) {
    request.post(apis.checkState, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }

    /**
 * 获取智能检测的区域限制状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkRegionlimitstatus(postData, ok, failed, end) {
    request.post(apis.checkRegionlimitstatus, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }

  /**
 * 查询卡状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function getsCardInfo(postData, ok, failed, end) { 
    request.post(apis.getsCardInfo, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }

    /**
 * 智能检测:区域限制
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkRestrictedarea(postData, ok, failed, end) { 
  request.post(apis.checkRestrictedarea, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

  /**
 * 智能检测:在线状态和网络类型 APN 最后上线时间  IP
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
   export function checkSession(postData, ok, failed, end) { 
    request.post(apis.checkSession, postData)
      .then(function (response) {
        ok && ok(response)
      })
      .catch(function () {
        failed && failed()
      })
      .finally(function () {
        end && end()
      })
  }

    /**
 * 智能检测:开关机状态
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function checkClientstate(postData, ok, failed, end) { 
  request.post(apis.checkClientstate, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}