import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { WingBlank, WhiteSpace, Button, List, Space, Divider, Toast, Input, AutoCenter } from "antd-mobile";
import { createForm } from "rc-form";
// import LoginPopup from './LoginPopup'
import { logout } from "@/utils/account";
// import usePrevious from '@/hooks/usePrevious'
import { doNumber, setPhone, getCardInfo, checkCardInfo } from "./fn";
import { getQueryBySearch, getSearchByHash } from "@/utils/core/url";
import { getCookie, saveCookie, removeCookie } from "@/utils/storage";

import Slogan from "../Slogan";
// logout();
// alert("0000")
function HelpMC(props) {
  const navigates = useNavigate();
  const location = useLocation();
  // const { fnumber, } = getQueryBySearch(location.search)
  const { getFieldProps, validateFields, getFieldError, setFieldsValue } = props.form;
  // console.log(props.form)
  const { onOk } = props;
  //
  const [slogan, setSlogan] = useState(null);
  //
  const [loginNumber, setLoginNumber] = useState(null);
  const [loginPhone, setLoginPhone] = useState(null);
  const [loginModalShow, setLoginModalShow] = useState(false);
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */

  // 近期操作账号 & v2
  useEffect(() => {
    // console.log(getCookie('lastno'));
    const lastNumber = getCookie("lastno");
    // 获取 cardNum
    const _urlSearch = window.location.search && getQueryBySearch(window.location.search);
    const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));

    const cardNum = _urlSearch["cardNum"] || _urlSearchHash["cardNum"];
    // console.log(_urlSearch)
    // console.log(_urlSearchHash)
    // console.log(cardNum)

    // console.log(getCookie('lastno'))
    // console.log(lastNumber)
    if (cardNum) {
      setFieldsValue({
        number: cardNum,
      });
    } else if (lastNumber) {
      setFieldsValue({
        number: lastNumber,
      });
    }

    if (cardNum) {
      validate();
    }
  }, []);
  //
  // useEffect(() => {
  //   if (fnumber) {
  //     setFieldsValue({
  //       number: fnumber,
  //     })
  //   }
  // }, [])
  //
  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  function validate() {
    // console.log(props);
    validateFields(["number"], (errors, values) => {
      // console.log(errors)
      // console.log(values);
      // console.log(getFieldError("number"));
      const { number } = values;
      if (!number || !number.trim()) {
        Toast.show("物联卡号/ICCID/SN不能为空", 2);
        return false;
      }
      console.log(number);
      Toast.show({
        content: "加载中…",
      });
      checkCardInfo(
        { number },
        (response) => {
          // console.log(response);
          Toast.clear();
          // removeCache("lastno");
          // const move =getCookie("lastno")
          // console.log(response.status)
          if (response.status) {
            const _opt = {
              expires: 1,
            };
            setTimeout(() => {
              // 记录卡号
              removeCookie("lastno");
              saveCookie("lastno", number, _opt);
              const fournumber = number.substr(0, 4);
              const teonumber = number.substr(0, 2);
              // console.log(fournumber)
              // console.log(teonumber)
              let issn = 0;
              if (fournumber == 8986 || teonumber == 14) {
                console.log(1)
                issn = 0;
              } else {
                console.log(2)
                issn = 1;
              }
              // console.log(issn)
              removeCookie("issn");
              saveCookie("issn", issn, _opt);
              // saveCookie("lastno", number);
              // removeCache("pool");
              Toast.show("正在跳转...");
              navigates("/anotherhome");
            }, 50);
          }
        },
        () => {},
        () => {
          Toast.show({
            content: "当前卡不存在,请检查后重新输入!",
          });
        }
      );
    });
  }
  //
  function handleCloseLogin() {
    setLoginModalShow(false);
  }
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */

  return (
    <section
      className={["layoutPage", "pageAcDo"].join(" ")}
      style={
        {
          // background: `url(${maskLogin}) no-repeat left top`,
          // backgroundSize: '100%'
        }
      }>
      <div className="layoutPage-header"></div>
      <div className="layoutPage-body">
        <Slogan style={{ marginBottom: "50px" }} />
        {/* <div style={{ padding: '0 0.24rem' }}> */}
        <div className="pageAcDo-formBlock">
          <form>
            <List style={{}}>
              <Input
                {...getFieldProps("number", {
                  rules: [
                    {
                      required: true,
                      message: "物联卡号/ICCID/SN不能为空",
                    },
                  ],
                })}
                placeholder="请输入物联卡号/ICCID/SN"
              />
              <Divider />
            </List>
          </form>
        </div>

        <div className="pageAcDo-otherBlock"></div>
        {/* </div> */}
      </div>
      {/* <Divider /> */}
      <div className="layoutPage-footer">
        <Button block shape="rounded" size="large" type="ghost" onClick={validate}>
          确认
        </Button>
      </div>
    </section>
  );
}
//export default HelpMC;
export default createForm()(HelpMC);
