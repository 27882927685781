import CryptoJS from 'crypto-js'
import request from '@/utils/request'
import apis from '@/config/apis/fankui'
import CONFIG from '@/config/global'


    /**
 * 提交反馈信息
 * @param {*} postData 
 * @param {*} ok 
 * @param {*} failed 
 * @param {*} end 
 */
 export function submitFeedback(postData, ok, failed, end) {
  request.post(apis.submitFeedback, postData)
    .then(function (response) {
      ok && ok(response)
    })
    .catch(function () {
      failed && failed()
    })
    .finally(function () {
      end && end()
    })
}

export function fieldsValueToForm(fieldsValue,number) {
  const { content, phone, } = fieldsValue
  let _interim = {
  }
  _interim['iccid'] = number
  _interim['content'] = content
  _interim['phone'] = phone

//   console.log(_interim)
  return _interim
}