const _codeCharset = `0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM`

export function drawCanvasCode(canvasDom, imgDom) {
  const canvas = document.querySelector(canvasDom)
  const context = canvas.getContext('2d')

  // console.log(`canvas.width: ${canvas.width}`)
  // console.log(`canvas.height: ${canvas.height}`)
  clearCanvas(canvas)

  context.fillStyle = 'cornflowerblue'
  context.fillRect(0, 0, canvas.width, canvas.height)
  context.fillStyle = "white"
  context.font = "0.28rem Arial"
  var rand = new Array()
  var x = new Array()
  var y = new Array()
  for (var i = 0; i < 5; i++) {
    rand[i] = _codeCharset[Math.floor(Math.random() * _codeCharset.length)]
    // x[i] = i * 16 + 10
    // y[i] = Math.random() * 20 + 20
    x[i] = i * 10 + 2
    y[i] = 15

    // console.log(`x: ${x[i]}`)
    // console.log(`y: ${y[i]}`)
    context.fillText(rand[i], x[i], y[i])
  }
  // //画5条随机线
  // for (var i = 0; i < 5; i++) {
  //   drawline(canvas, context)
  // }
  // // 画30个随机点
  // for (var i = 0; i < 30; i++) {
  //   drawDot(canvas, context)
  // }
  convertCanvasToImage(canvas, canvasDom, imgDom)


  return rand
}

function clearCanvas(canvas) {
  const context = canvas.getContext('2d')
  context.fillStyle="#000000"
  context.beginPath()
  context.fillRect(0,0,canvas.width,canvas.height)
  context.closePath()
}

// 随机线
function drawline(canvas, context) {
  context.moveTo(Math.floor(Math.random() * canvas.width), Math.floor(Math.random() * canvas.height));             //随机线的起点x坐标是画布x坐标0位置，y坐标是画布高度的随机数
  context.lineTo(Math.floor(Math.random() * canvas.width), Math.floor(Math.random() * canvas.height));  //随机线的终点x坐标是画布宽度，y坐标是画布高度的随机数
  context.lineWidth = 0.2;                                                  //随机线宽
  context.strokeStyle = `rgba(50,50,50,0.2)`;                               //随机线描边属性
  context.stroke();                                                         //描边，即起点描到终点
}
// 随机点(所谓画点其实就是画1px像素的线，方法不再赘述)
function drawDot(canvas, context) {
var px = Math.floor(Math.random() * canvas.width);
var py = Math.floor(Math.random() * canvas.height);
context.moveTo(px, py);
context.lineTo(px + 1, py + 1);
context.lineWidth = 0.2;
context.stroke();

}
// 绘制图片
function convertCanvasToImage(canvas, canvasDom, imgDom) {
  document.querySelector(canvasDom).style.display = "none"
  var image = document.querySelector(imgDom)
  image.src = canvas.toDataURL("image/png")
  return image
}

// 点击图片刷新
// document.getElementById(‘code_img‘).onclick = function () {
// $(‘#verifyCanvas‘).remove();
// $(‘#verify‘).after(‘<canvas width="100" height="40" id="verifyCanvas"></canvas>‘)
// drawCode();
// }